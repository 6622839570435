/**
 * Enum for FeatureFlagsFilters.
 * @readonly
 * @enum 
 */
 export enum FeatureFlagsFilters {
    /**
     * Percentage Filter.
     */
     Percentage = "Microsoft.Percentage",

    /**
     * Targeting Filter.
     */
    Targeting = "Microsoft.Targeting",
}