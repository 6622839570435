import { ActionButton, Checkbox, Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { CommonStyles, defaultColumnProps, DefaultPageStyles, DeleteButtonIconStyles } from "../../content/styles/Page.styles";
import { UIControlsText } from "../../content/UIControlsText";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { PartnerType } from "../../models/PartnerType";
import { IAccount } from "../../models/IAccount";
import { ICountry } from "../../models/ICountry";
import { IOpportunityAccount } from "../../models/IOpportunityAccount";
import { AccountTypeOptions, partnerTypeValues } from "../../services/StaticData";
import { ActionType } from "../../models/ActionType";
import { nextGenService } from "../../services/NextGenService";
import { AccountAllType } from "../../models/AccountAllType";
import { CssClassConstants } from "../../content/CssClassConstants";
import { IAccountBaseProps } from "./props/IAccountBaseProps";
import { EntityLookup } from "../../pages/pricingprogram/common/components/EntityLookup";
import { IProductType } from "../../models/IProductType";
import { getAction, getParentAccountAction, getPartnerAction, isEditableAccount, isEditableAccountById, isFieldDisabled, isMSStoreDisable, gridAccessibilityFix } from "./common/util";
import { isRoleBasedFilterRequired } from "./common/util";
import { PageMode } from "../../models/PageMode";
import _ from "lodash";
import { SessionStorageConsts } from "../../models/SessionStorageConsts";
import { IEntityLookupColumnConfig } from "../../models/IEntityLookupColumnConfig";
import { getRuleValidationMessage, getEntityDataFromSession } from "./common/util";
import { MessageTypeText } from "../../models/MessageTypeText";
import { PageConstants } from "../../pages/pageconstants/Constants";

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

/**
 * Reseller component.
 * @function component
 */
const Reseller: React.FunctionComponent<IAccountBaseProps> = (props?: IAccountBaseProps) => {
    const [customerCountry, setCustomerCountry] = useState<ICountry | undefined>(props?.customerCountry);
    const [resellerType, setResellerType] = useState<PartnerType>(props?.partnerType || PartnerType.ADR);
    const [selectedResellers, setSelectedResellers] = useState<Array<IAccount>>(
        props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.accounts
        || []);
    const [countries] = useState<ICountry[]>(props?.countries || []);
    //always single object
    const [productType] = useState<IProductType[]>(props?.productType || []);

    /**
     * Get TDistributor name from session storage.
     * @method
     * @@returns {IAccount[]} List of Distributor Accounts.
     */
    const getDistributorAccounts = (): IAccount[] => {
        var allDistributors = sessionStorage.getItem(SessionStorageConsts.distributors);
        if (allDistributors !== null) {
            return JSON.parse(allDistributors);
        }

        return [];
    };

    const userRole = sessionStorage.getItem(SessionStorageConsts.userRole);
    const resellers = useRef<IAccount[]>([]);
    if (props?.resellerAccounts && props?.resellerAccounts.length > 0) {
        if (props.pageMode?.toLowerCase() === PageMode.View.toLowerCase() && isRoleBasedFilterRequired(userRole || '')) {
            resellers.current = props?.resellerAccounts.filter(resellerAccount => selectedResellers.find(({ accountId }) => resellerAccount.accountId === accountId));
        }
        else {
            resellers.current = props?.resellerAccounts;
        }
    }

    if (resellers.current && resellers.current.length > 0) {
        if (customerCountry) {
            if (customerCountry.isEUEFTA) {
                resellers.current = resellers.current.filter((x: IAccount) => x.countryId === customerCountry.countryId
                    || x.isEueftaAccount);
            }
            else {
                resellers.current = resellers.current.filter((x: IAccount) => x.countryId === customerCountry.countryId);
            }
        }

        if (resellerType) {
            resellers.current = resellers.current.filter((x: IAccount) => x.partnerType === resellerType);
        }
    }

    //Fixing Accessibility for noRecords, sortIcons, GridPagerBtns & gridFootercell
    useEffect(() => {
        gridAccessibilityFix();
    });

    useEffect(() => {

        if (resellerType === PartnerType.MsStore) {
            let msStoreAccount: IOpportunityAccount = { id: props && props.id, partnerType: PartnerType.MsStore, };
            msStoreAccount = {
                id: props && props.id,
                countryId: customerCountry?.countryId,
                partnerType: PartnerType.MsStore,
                isAllSelected: AccountAllType.None,
                isComplete: true,
                action: getParentAccountAction(customerCountry?.countryId, PartnerType.MsStore)
            };
            props && props.updateAccounts && props.updateAccounts([msStoreAccount, {}], ActionType.Update);
            return;
        }

        let resellerSelectionComplete: boolean = false;
        if (customerCountry && selectedResellers.length > 0) {
            resellerSelectionComplete = true;
        }

        let resellerSelection: IOpportunityAccount = {
            id: props && props.id,
            partnerType: resellerType,
            countryId: customerCountry?.countryId,
            accounts: selectedResellers,
            isAllSelected: AccountAllType.None,
            isComplete: resellerSelectionComplete,
        };

        if (customerCountry && resellerType === PartnerType.DMP && selectedResellers.length > 0 && props?.partnerAccounts) {

            let existingDMPs = Object.entries(props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.availableRelatedAccounts || []).map(x => x[0]);
            let newDMPs = selectedResellers.filter(x => !existingDMPs.includes(x.accountId)).map(x => x.accountId);
            if (newDMPs.length > 0) {
                let newRelatedAccounts = GetNewRelatedAccountsCopy();

                resellerSelection = {
                    id: props && props.id,
                    partnerType: resellerType,
                    countryId: customerCountry?.countryId,
                    accounts: selectedResellers,
                    isAllSelected: AccountAllType.None,
                    isComplete: resellerSelectionComplete,
                    availableRelatedAccounts: props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.availableRelatedAccounts || GetNewRelatedAccountsCopy(),
                    partnerAccounts: props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.partnerAccounts || GetNewRelatedAccountsCopy()
                };

                selectedResellers.forEach((reseller) => {
                    if (resellerSelection.availableRelatedAccounts && (!resellerSelection.availableRelatedAccounts[reseller.accountId] || (resellerSelection.availableRelatedAccounts[reseller.accountId] && resellerSelection.availableRelatedAccounts[reseller.accountId].length === 0))) {
                        if (newRelatedAccounts[reseller.accountId] && newRelatedAccounts[reseller.accountId].length === 0) {
                            resellerSelection.availableRelatedAccounts[reseller.accountId] = [];
                        }
                    }

                    if (resellerSelection.partnerAccounts && (!resellerSelection.partnerAccounts[reseller.accountId] || (resellerSelection.partnerAccounts[reseller.accountId] && resellerSelection.partnerAccounts[reseller.accountId].length === 0))) {
                        resellerSelection.partnerAccounts[reseller.accountId] = [];
                    }
                })

                if (resellerSelection?.partnerAccounts) {
                    for (const [key, value] of Object.entries(resellerSelection?.partnerAccounts)) {
                        resellerSelection!.partnerAccounts[key] = value.map(p => { return { ...p, action: getPartnerAction(p, resellerSelection.countryId, PartnerType.DMP, key) } });
                    }
                }

                props && props.updateAccounts && props.updateAccounts([resellerSelection, {}], ActionType.Update);
            }
            else {
                let removedAvailableAccounts = Object.entries(props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.availableRelatedAccounts || []).filter(x => !selectedResellers.map(res => res.accountId).includes(x[0])).map(x => x[0]);
                let removedPartnerAccounts = Object.entries(props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.partnerAccounts || []).filter(x => !selectedResellers.map(res => res.accountId).includes(x[0])).map(x => x[0]);
                let updatedAvailableAccounts = props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.availableRelatedAccounts;
                let updatedPartnerAccounts = props?.opportunityAccounts?.find(opp => opp.partnerType === resellerType)?.partnerAccounts;
                if (updatedAvailableAccounts && updatedPartnerAccounts && removedAvailableAccounts.length > 0) {
                    delete updatedAvailableAccounts[removedAvailableAccounts[0]];
                    delete updatedPartnerAccounts[removedPartnerAccounts[0]];
                }
                resellerSelection.availableRelatedAccounts = updatedAvailableAccounts;
                resellerSelection.partnerAccounts = updatedPartnerAccounts;
                props && props.updateAccounts && props.updateAccounts([resellerSelection, {}], ActionType.Update);
            }
        }
        else {
            resellerSelection = {
                id: props && props.id,
                partnerType: resellerType,
                countryId: customerCountry?.countryId,
                accounts: selectedResellers,
                isAllSelected: AccountAllType.None,
                isComplete: resellerSelectionComplete
            };
            props && props.updateAccounts && props.updateAccounts([resellerSelection], ActionType.Update);
        }

        function GetNewRelatedAccountsCopy(): { [key: string]: IAccount[]; } {
            let accounts: { [key: string]: IAccount[]; } = {};
            if (props?.partnerAccounts) {
                // accounts = props.partnerAccounts;

                const resellerIDs = selectedResellers.map(x => x.accountId);
                for (var i in props.partnerAccounts) {
                    if (resellerIDs.includes(i)) {
                        accounts[i] = props.partnerAccounts[i];
                    }
                }
            }
            return accounts;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resellerType, customerCountry, selectedResellers, props?.partnerAccounts]);

    /**
     * PartnerType Options change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onAccountTypeOptionsChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setSelectedResellers([]);
            setResellerType(item.key as PartnerType);
        }
    }

    /**
     * Reseller Country Options change Callback function.
     * @method
     * @param {React.FormEvent<HTMLDivElement>} event Element Click Event.
     * @param {IDropdownOption} item Clicked Option from Dropdown.
     */
    const onResellerCountryOptionsChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            let selectedCountry = countries.find(x => x.countryId === item.key as string);
            setCustomerCountry(selectedCountry);
            setSelectedResellers([]);
        }
    }

    /**
      * Reseller Country Options change Callback function.
      * @method
      * @param SelectedOptions Clicked Option from Dropdown.
      */
    const onResellerChange = (selectedItems: any[]): void => {
        if (selectedItems) {
            const updatedValues = resellers.current.map(item => {
                return {
                    ...item,
                    key: `${item.accountId}-${item.partnerType}`,
                };
            });
            const modifiedResellers = {
                current: updatedValues,
            };
            let updatedResellers: IAccount[] = [];
            selectedItems.forEach(x => {
                let reseller: any;
                reseller = modifiedResellers.current?.find(y => y.key === x.key);
                if (reseller) {
                    reseller = _.omit(reseller, 'key');
                    reseller.action = getAction(reseller, customerCountry?.countryId, reseller.partnerType);
                    updatedResellers.push(reseller);
                }
            });
            setSelectedResellers(updatedResellers);
        }
    }

    const columnDefinitions: IEntityLookupColumnConfig[] = [
        {
            apiField: 'name',
            key: 'name',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
            displayOrder: 2
        }, {
            apiField: 'country',
            key: 'country',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CountryFacetText", UIControlsText.CountryFacetText),
            displayOrder: 3,
        }, {
            apiField: 'organizationId',
            key: 'organizationId',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MpnIdText", UIControlsText.MpnIdText),
            displayOrder: 4
        }]
    return (
        <Stack horizontal {...defaultColumnProps} id={props?.id}>
            <Dropdown
                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerCountryPlaceholderText", UIControlsText.ResellerCountryPlaceholderText)}
                options={countries.map((pt: ICountry) => ({ key: pt.countryId, text: pt.countryName } as IDropdownOption))}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerCountryPlaceholderText", UIControlsText.ResellerCountryPlaceholderText)}
                selectedKey={customerCountry?.countryId}
                disabled={JavaScriptExtensions.isDefined(props?.customerCountry)}
                onChange={onResellerCountryOptionsChange}
                styles={DefaultPageStyles.customWidthStyles}
                style={JavaScriptExtensions.isDefined(props?.customerCountry) ? CommonStyles.blackBorder : {}}
                required />
            <Dropdown
                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "PartnerTypePlaceHolderText", UIControlsText.PartnerTypePlaceHolderText)}
                options={customerCountry?.isMsStore ? AccountTypeOptions.filter(x => x.key !== PartnerType.ADD) : AccountTypeOptions.filter(x => x.key !== PartnerType.MsStore && x.key !== PartnerType.ADD)}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "PartnerTypePlaceHolderText", UIControlsText.PartnerTypePlaceHolderText)}
                defaultSelectedKey={props?.partnerType}
                onChange={onAccountTypeOptionsChange}
                styles={DefaultPageStyles.customWidthStyles}
                disabled={isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerRegistration", UIControlsText.ResellerRegistration), props?.pageMode, props?.opportunityDealStatusCode, undefined, undefined, PageConstants.CPStrategicDealtype) || !isEditableAccountById(props?.id)}
                required />
            {resellerType !== PartnerType.MsStore &&
                <label>
                    <EntityLookup
                        headerText={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerLookupText", getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerLookupText", UIControlsText.ResellerLookupText))}
                        subHeaderText={`${productType && productType[0]?.productName} - ${props?.customerCountry?.countryName}`}
                        items={(resellers.current && resellers.current.map((pt: IAccount) => ({
                            key: `${pt.accountId}-${pt.partnerType}`, accountId: pt.accountId, name: pt.companyName, data: partnerTypeValues[`${pt.partnerType}`], partnerType: pt.partnerType, country: pt.countryName, organizationId: pt.organizationId,
                            editMode: props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()
                        }))) || []}
                        lookUpTextFieldProps={{
                            label: props?.customerCountry?.countryName,
                            placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerNamePlaceholderText", UIControlsText.ResellerNamePlaceholderText),
                            title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerNamePlaceholderText", UIControlsText.ResellerNamePlaceholderText),
                            style: CommonStyles.entityLookupWidth,
                            disabled: props?.disableResellerAndMSStoreField,
                            value: (selectedResellers && selectedResellers.length) ?
                                selectedResellers.length > 1 ? selectedResellers.length + ' Resellers Selected'
                                    : selectedResellers.length + ' Reseller Selected'
                                : ''
                        }}
                        isCountrySelected={!customerCountry ? false : true}
                        columnProps={columnDefinitions}
                        itemSelectionUpdate={onResellerChange}
                        selectedItems={selectedResellers && selectedResellers.map(x => ({
                            key: `${x.accountId}-${x.partnerType}`, accountId: x.accountId, name: x.companyName, data: partnerTypeValues[`${x.partnerType}`], partnerType: x.partnerType, country: x.country,
                            editMode: isEditableAccount(customerCountry?.countryId, props?.pageMode, props?.opportunityDealStatusCode, x, x.partnerType)
                        } as any))}
                        disableSelectAllSelection={props?.pageMode?.toLowerCase() === PageMode.View.toLowerCase()}
                        countryId={customerCountry?.countryId}
                        PageMode={props?.pageMode}
                        partnerType={resellerType}
                        statusCode={props?.opportunityDealStatusCode}
                    />
                </label>
            }
            {props?.hideDeleteIcon && props.pageMode?.toLowerCase() !== PageMode.View.toLowerCase() && isEditableAccountById(props?.id) &&
                <ActionButton
                    iconProps={{ iconName: CssClassConstants.DeleteIconName, ariaLabel: CssClassConstants.DeleteIconName, styles: DeleteButtonIconStyles }}
                    disabled={!props?.hideDeleteIcon}
                    style={resellerType === PartnerType.MsStore ? CommonStyles.DeleteIconMargin : {}}
                    id={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DeleteChannelAccount", UIControlsText.DeleteChannelAccount)}
                    onClick={() => {
                        props?.updateAccounts && props?.updateAccounts([{ id: props?.id, partnerType: resellerType }], ActionType.Delete);
                    }}>
                </ActionButton>
            }
        </Stack>
    );
}

export default Reseller;