/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { IPanelTableProps } from "../props/PanelTableProps";
import { useBoolean } from '@fluentui/react-hooks';
import { UIControlsText } from "../../../content/UIControlsText";
import NextGenKendoGrid from "../../../components/shared/grid/nextgenkendogrid";
import { IGridProps } from "../../../components/shared/grid/IGridProps";
import { readGridConfig, sortArrayData, isEditableRow, getCountryById, getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { GridType } from "../../../models/GridType";
import { getter } from "@progress/kendo-data-query";
import { IEntityLookupColumnConfig } from "../../../models/IEntityLookupColumnConfig";
import { PageMode } from "../../../models/PageMode";
import { SearchBox } from "@fluentui/react";
import { DefaultButton, PrimaryButton, MessageBar, IStackTokens, Panel, PanelType, Stack, ActionButton } from "@fluentui/react";
import { GridSelectionType } from "../../../components/shared/grid/SelectionType";
import { PageStyles } from "../../pricingprogram/common/content/Page.Styles";
import _ from "lodash";
import { ApplicationConstants } from "../../../models/ApplicationConstants";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { ActionType } from "../../../models/ActionType";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';

const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };
const PanelTableContainer: React.FunctionComponent<IPanelTableProps> = (props: IPanelTableProps) => {
    const { onClick } = props.primaryButtonProps;
    const { messageBar, sortingDataType, propertyName, panelType } = props;
    const [showPanel, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [tableListConfig, setTableListConfig] = useState<IGridProps>(readGridConfig(props.headerText));
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const idGetter = getter(tableListConfig.kendoGridProps.dataItemKey);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    let resetPaginationToDefault = { skip: 0, take: tableListConfig.kendoGridProps.noOfRecords }
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    useEffect(() => {
        updateColumnConfig();
        if (props.selectedItems && props.selectedItems.length > 0) {
            setSelectedItemsOnReOpen();
        }
        setSelectedItems(props?.selectedItems)
    }, [props.tableContent.length, props.countrySelected, props.partnerTypeSelected, props.selectedItems])

    const customNoRecordsErrorMessage = () : string => {
        var country = getCountryById(props.countrySelected || "");
        if(props.headerText === GridType.DistributorList 
            && (country?.isEUEFTA || country?.region === ApplicationConstants.MiddleEastRegion)) {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoDistributorFoundInEufta", UIControlsText.NoDistributorFoundInEufta) || "";
        }

        return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoRecordsAvailableText", UIControlsText.NoRecordsAvailableText) || "";
    }
    const isEditEnabled = (selectedRowState: any, isChecked: boolean) : boolean => {
        let selectedRow = selectedItems.find((t: any) => t.key === selectedRowState.key);
        if(JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedRow)) {
            return isChecked;
        }
        if(!JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedRow) 
             && (selectedRow.action === ActionType.Add || selectedRow.action === ActionType.PanelAdd || JavaScriptExtensions.isNullOrUndfinedOrEmpty(selectedRow.action))) {
           return isChecked;
        }

        return true;
    }

    const onRowSelectionCallBack = (selectedState: any) => {
        let selectedTableListConfig = { ...tableListConfig }
        const tableGridDataWithSelection = tableListConfig.kendoGridProps.data.map((item: any) => (
            {
                ...item, [tableListConfig.kendoGridProps.selectedField]: isEditEnabled(item, selectedState[idGetter(item)]),
            }));
        tableGridDataWithSelection.map((item: any) => {
            if (item.selected === true) {
                selectedItems.push(Object.assign(item));
            }
        });
        selectedTableListConfig = {
            kendoGridProps: {
                ...selectedTableListConfig.kendoGridProps,
                data: tableGridDataWithSelection,
            }
        };
        const a = tableGridDataWithSelection.filter((item: any) => item.selected).reduce((acc: any, ikk: any) => {
            return {
                ...acc,
                [`${ ikk.key }`]: ikk.selected,
            };
        }, {});
        const b = {
            ...selectedState,
            ...a,
        };

        const updatedSelectedItems = (Object.keys(b).map(item => {
            const val = selectedItems.filter((i: any) => i.key === item)[0]
            return {
                ...val,
                selected: b[item],
            };
        }).filter((k: any) => k.selected))
        const listUpdated = defaultStateUpdate(updatedSelectedItems);
        selectedTableListConfig.kendoGridProps.defaultMainGridSelectionstate = listUpdated;
        setTableListConfig(selectedTableListConfig);
        setSelectedItems(updatedSelectedItems);
        setShowErrorMessage(false);
        props.onItemSelection(updatedSelectedItems);
    };

    //Function to call to set the selected values
    const setSelectedItemsOnReOpen = () => {
        let selectedTableListConfig = { ...tableListConfig }
        let filteredTableContent = props?.tableContent.filter((fil: any) => fil.countryId === props?.countrySelected);
        if (props?.partnerTypeSelected) {
            filteredTableContent = filteredTableContent.filter((fil: any) => fil.partnerType === props?.partnerTypeSelected);
        }
        const updatedTableData = filteredTableContent?.length ? filteredTableContent.map((val: any) => {
            const keysSelected = props.selectedItems.map((item: any) => item.key);
            const selected = keysSelected.includes(val.key) ? true : undefined;
            const item = props.selectedItems.filter((item: any) => item.key === val.key)
            var iseditMode = val.editMode;
            if (item.length > 0) {
                iseditMode = item[0].editMode
            }
            return {
                ...val,
                selected,
                editMode: iseditMode,
            };
        }) : [];
        const updatedConfig = defaultStateUpdate(props.selectedItems);
        selectedTableListConfig.kendoGridProps.defaultMainGridSelectionstate = updatedConfig;
        selectedTableListConfig.kendoGridProps.totalRecords = updatedTableData.length;
        const sortedData = sortingDataType && propertyName && sortArrayData(updatedTableData, sortingDataType, propertyName)
        selectedTableListConfig = {
            kendoGridProps: {
                ...selectedTableListConfig.kendoGridProps,
                data: sortedData,
            }
        };
        setTableListConfig(selectedTableListConfig);
    }

    const defaultStateUpdate = (selectedValues: any) => {
        const state: any = {};
        selectedValues.forEach((item: any) => { state[idGetter(item)] = true });
        return tableListConfig.kendoGridProps.defaultMainGridSelectionstate = state;
    }
    const updateColumnConfig = () => {
        let selectedTableListConfig = { ...tableListConfig };
        //add selected property
        let filteredTableContent = props?.tableContent.filter((fil: any) => fil.countryId === props?.countrySelected);
        if (props?.partnerTypeSelected) {
            filteredTableContent = filteredTableContent.filter((fil: any) => fil.partnerType === props?.partnerTypeSelected);
        }
        const modifiedData = filteredTableContent.map((val: any) => {
            const keysSelected = props.selectedItems.map((item: any) => item.key);
            const selected = keysSelected.includes(val.key) ? true : undefined;
            return {
                ...val,
                selected: selected,
            };
        });
        const sortedData: any = sortingDataType && propertyName && sortArrayData(modifiedData, sortingDataType, propertyName);
        selectedTableListConfig.kendoGridProps.data = sortedData;
        const updatedConfig = defaultStateUpdate(props.selectedItems);
        selectedTableListConfig.kendoGridProps.defaultMainGridSelectionstate = updatedConfig;
        //Retain Selection When Country is Changed
        const { defaultMainGridSelectionstate } = selectedTableListConfig.kendoGridProps
        const keyList = Object.keys(defaultMainGridSelectionstate || {})
        if (keyList.length > 0) {
            sortedData?.map((x: any) => keyList.forEach(y => { return (y === x.key ? x.selected = true : undefined) }));
        }
        //setMultiple columns -- displayOrder is the unique Key 
        let updatedResellerColumns: any = [];
        const columns: IEntityLookupColumnConfig[] = props.columnDefinitions || [];
        selectedTableListConfig.kendoGridProps.selectionType = props?.selectionType || GridSelectionType.Mulitple;
        selectedTableListConfig.kendoGridProps.columns.map((item: any, i) => {
            const selectedIndex: any = columns.findIndex(x => x.displayOrder === item.displayOrder);
            const obj = {
                ...item,
                ...columns[selectedIndex],
            };
            updatedResellerColumns = [...updatedResellerColumns, obj];
            return updatedResellerColumns;
        });
        selectedTableListConfig.kendoGridProps.columns = updatedResellerColumns;
        selectedTableListConfig.kendoGridProps.totalRecords = filteredTableContent.length;
        setTableListConfig(selectedTableListConfig);
    };


    const onDismiss = React.useCallback((ev?: React.SyntheticEvent | KeyboardEvent) => {
        if (ev) {
            ev.preventDefault();
            dismissPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //Fixing Accessibility issue for PanelGrid FilterOption
        var gridFilter = document.querySelectorAll("#PanelTableContainer .k-dropdown-wrap .k-input");
        for (let i = 0; i < gridFilter.length; i++) {
            gridFilter[i].setAttribute('role', 'option');
            gridFilter[i].setAttribute('title', 'option');
        }

        //Fixing Accessibility issue for Panel grid filterTextbox
        const filterTextFieldColumns = tableListConfig.kendoGridProps.columns.filter(item => item.allowFilter && !item.categoryFilterCell && item.isVisible === true);
        var filterTextbox = document.querySelectorAll("#PanelTableContainer .k-filtercell .k-filtercell-wrapper > .k-textbox");
        filterTextbox && filterTextbox.forEach(c => {
            for (let i = 0; i < filterTextbox.length; i++) {
                if (filterTextFieldColumns[i]) {
                    filterTextbox[i].setAttribute('aria-label', `${ filterTextFieldColumns[i].columnTitle }`);
                }
            }
        });
    }, []);

    const onSearchChange = (search: string) => {
        let selectedTableListConfig = { ...tableListConfig };
        let filteredTableContent = selectedTableListConfig.kendoGridProps.data.filter((fil: any) => fil.countryId === props?.countrySelected);
        if (props?.partnerTypeSelected) {
            filteredTableContent = filteredTableContent.filter((fil: any) => fil.partnerType === props?.partnerTypeSelected);
        }
        // const uniqueTableContent = _.uniqBy(filteredTableContent.map((item: any) => ({ ...item })), 'key');
        selectedTableListConfig.kendoGridProps.data = filteredTableContent;
        if (search.length === 0) {
            setSearchText('');
            //To Keep the selection Retained after clearing the Search
            updateColumnConfig();
            defaultStateUpdate(selectedItems);
            const { data, defaultMainGridSelectionstate } = selectedTableListConfig.kendoGridProps;
            const keyList = Object.keys(defaultMainGridSelectionstate || {})
            data.map((x: any) => keyList.forEach(y => { return y === x.key ? x.selected = true : undefined }));
        }
        const filteredData = selectedTableListConfig.kendoGridProps.data.filter((item: any) => item.name.toLowerCase().includes(search.toLowerCase()));
        setSearchText(search);
        selectedTableListConfig.kendoGridProps.data = filteredData;
        selectedTableListConfig.kendoGridProps.totalRecords = filteredData.length;
        resetPaginationToDefault = { skip: 0, take: selectedTableListConfig.kendoGridProps.noOfRecords }
        return setTableListConfig(selectedTableListConfig);
    }

    const onSubmitClick = (e: any) => {
        onClick && onClick(e);
        setShowErrorMessage(!!!(props.selectedItems.length > 0))
        return props.selectedItems.length === 0 ? () => { } : dismissPanel();
    }

    const onCancel = () => {
        props.onDefaultButtonClick();
        return dismissPanel();
    };

    const onPanelClick = () => {
        props.invokePanelProps.onButtonClick();
        return openPanel();
    }

    const onSearchClear = () => {
        setSearchText('');
    }

    const onClearAllClick = () => {
        props?.onClearAll();
        setSearchText('');
        setSelectedItems([]);
        let selectedTableListConfig = { ...tableListConfig };
        let filteredTableContent = props?.tableContent.filter((fil: any) => fil.countryId === props?.countrySelected);
        if (props?.partnerTypeSelected) {
            filteredTableContent = filteredTableContent.filter((fil: any) => fil.partnerType === props?.partnerTypeSelected);
        }
        const uniqueTableContent = _.uniqBy(filteredTableContent.map((item: any) => ({ ...item })), 'key');
        selectedTableListConfig.kendoGridProps.data = uniqueTableContent;
        selectedTableListConfig.kendoGridProps.defaultMainGridSelectionstate = {};
        return setTableListConfig(selectedTableListConfig);
    };
    const onRenderFooterContent = React.useCallback(
        () => (
            <>
                {messageBar && showErrorMessage &&
                    < div >
                        <MessageBar
                            messageBarType={messageBar.messageBarType}
                            isMultiline={messageBar.isMultiline}
                            onDismiss={resetChoice}
                            dismissButtonAriaLabel="Close"
                        >
                            {props?.errorMessage}
                        </MessageBar>
                    </div>}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <PrimaryButton
                        aria-label={props?.primaryButtonProps?.ariaLabel}
                        style={{
                            ...props?.primaryButtonProps?.style
                        }}
                        text={props?.primaryButtonProps?.text}
                        onClick={onSubmitClick}
                    />
                    {props?.additionalFooterButtons}
                    <DefaultButton
                        aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CancelText", UIControlsText.CancelText)}
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CancelText", UIControlsText.CancelText)}
                        onClick={onCancel}
                        style={PageStyles.panelButtonStyle}
                    />
                    <div style={PageStyles.panelButtonStyle}> {props?.footerTextField}</div>
                </div>
            </>
        ),
        [dismissPanel, selectedItems, messageBar, showErrorMessage],
    );

    return (
        <Stack tokens={stackTokens}>
            {!props.invokePanelProps.disabled && 
                <PrimaryButton
                    iconProps={props.invokePanelProps.iconProps}
                    disabled={props.PageMode?.toLowerCase() === PageMode.View.toLowerCase() || props.invokePanelProps.disabled}
                    style={props.invokePanelProps?.buttonStyles}
                    onClick={onPanelClick}>
                    {props.invokePanelProps.buttonText}
                </PrimaryButton>
            }
            <Panel
                id="PanelTableContainer"
                isOpen={showPanel}
                type={PanelType.custom}
                customWidth='610px'
                onDismiss={onDismiss}
                closeButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CloseText", UIControlsText.CloseText)}
                isFooterAtBottom={true}
                onRenderFooterContent={onRenderFooterContent}
                onOpen={() => {
                    updateColumnConfig();
                    if (props.selectedItems && props.selectedItems.length > 0) {
                        setSelectedItemsOnReOpen();
                    }
                }}
                headerText={props.headerText} >
                {props.tableConfigurationFields}
                < div style={{ display: 'flex', justifyContent: 'space-between' }
                }>
                    <SearchBox
                        placeholder="Search"
                        value={searchText}
                        showIcon
                        onClear={onSearchClear}
                        onChange={(_, newValue: any) => onSearchChange(newValue)}
                        style={{ width: '400px' }}
                        className="searchBoxContainer" />
                    <ActionButton onClick={onClearAllClick} style={{ cursor: 'pointer' }}>
                        Clear All
                    </ActionButton>
                </div >
                <div id='panelTableGrid'>
                    <NextGenKendoGrid
                        {...{
                            kendoGridProps: { ...tableListConfig.kendoGridProps },
                            onRowSelectionCallBack,
                            disableSelectAllCheckbox: false,
                            resetPaginationToDefault: resetPaginationToDefault,
                            customNoRecordsErrorMessage: customNoRecordsErrorMessage,
                        }}
                    />
                </div>
            </Panel >
        </Stack >
    );
};

export default PanelTableContainer;