import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { populateGridColumns, getUserRole, getUserSession, readGridConfig, getPageNumberFromSession, getRuleValidationMessage, getEntityDataFromSession } from "../../components/shared/common/util";
import { GridType } from "../../models/GridType";
import "../../content/styles/AddCaseGrid.css";
import DashboardGrid from "../../components/shared/grid/dashboardgrid";
import { IGridProps } from "../../components/shared/grid/IGridProps";
import { DealType } from "../../models/DealType";
import { IDashboardDataRequest } from "../../models/IDashboardDataRequest";
import { nextGenService } from "../../services/NextGenService";
import { Stack, Spinner, FontIcon, CommandBarButton, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { UIControlsText } from "../../content/UIControlsText";
import { JavaScriptExtensions } from "../../infrastructure/JavaScriptExtensions";
import { IDashboardGridProps } from "./props/IDashboardGridProps";
import { filter, unionBy } from "lodash";
import { IOpportunityDealDetails } from "../../models/IOpportunityDealDetails";
import { filterBy } from "@progress/kendo-data-query";
import { RefreshButtonStyles, _Styles } from "../../content/styles/Page.styles";
import { PageConstants } from "../pageconstants/Constants";
import { CssClassConstants } from "../../content/CssClassConstants";
import { UserSubRole } from "../../models/UserSubRole";
import Row from "react-bootstrap/esm/Row";
import { AddToCase } from "./add-to-case";
import { IconButton } from "@fluentui/react";
import { MessageTypeText } from '../../models/MessageTypeText';
import { SessionStorageConsts } from '../../models/SessionStorageConsts';
import { DashboardSearch } from "./dashboard-search";
import { IAccount } from "../../models/IAccount";
import { getUserMainRole } from "../../components/shared/common/util";
import { UserRole } from "../../models/UserRole";
import moment from "moment";


export const AllCaseView: FC<IDashboardGridProps> = (props: IDashboardGridProps) => {
    const [gridConfig, setGridConfig] = useState<IGridProps>(readGridConfig(GridType.AllCaseView));
    const [isCaseLoading, setIsCaseLoading] = useState<boolean>(false);
    const [isRefreshed, setIsRefreshed] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [userRoles] = useState<string>(getUserRole());
    const [data, setData] = useState<IOpportunityDealDetails[]>([]);
    const [cpStrategicCount, setCpStrategicCount] = useState<number>(0)
    const [nonCpStrategicCount, setNonCPStrategicCount] = useState<number>(0)
    const [otleCount, setOtleCount] = useState<number>(0)
    const [userRole] = useState<string>(getUserRole());
    const [msgBarText, setMsgBarText] = useState<string>();
    const [filteredPartnerAccount, setfilteredPartnerAccount] = useState<IAccount[] | undefined>(undefined);
    const [hideDialog, setHideDialog] = useState<boolean>(true);
    const [gridDataState, setGridDataState] = useState<any>();
    const [errorMessageType, setErrorMessageType] = useState(MessageBarType.success);
    const [showMessageBarOnDashboard, setshowMessageBarOnDashboard] = useState<boolean>(false);
    const resetDashboardMessageBarChoice = useCallback(() => setshowMessageBarOnDashboard(false), []);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [userMainRole] = useState<string>(getUserMainRole());

    useEffect(() => {
        gridConfig.kendoGridProps = populateGridColumns(gridConfig.kendoGridProps)
        gridConfig.kendoGridProps.noOfRecords = getPageNumberFromSession(gridConfig.kendoGridProps)
        setGridConfig(gridConfig)
        getAllCases(pageNumber);
    }, []);

    useEffect(() => {
        if (props.onAddToCaseCallBack === GridType.AllCaseView) {
            getAllCases(1);
        }
    }, [props.onAddToCaseCallBack]);

    useEffect(() => {
        if(filteredPartnerAccount != undefined){
            setIsRefreshed(true);
        }
    }, [filteredPartnerAccount]);
    
    useEffect(() => {
        if(isRefreshed){
            onRefresh()
        }
    }, [isRefreshed]);

    useEffect(() => {
        if(data.length == 0 && isRefreshed){
            setIsRefreshed(false)
            setNonCPStrategicCount(0)
            setCpStrategicCount(0)
            setOtleCount(0)
            setPageNumber(1)
            getAllCases(1, gridDataState)
        }
    }, [data]);

    const getAllCases = async (pageNo?: number, dataState?: any, dealType?: string[], isFilterApplied?: boolean, pageChanged?: boolean) => {
        try {
            setIsRefreshed(false);
            const userSession = getUserSession();
            if (userSession != null) {
                if(isFilterApplied == undefined || !isFilterApplied){
                    setIsCaseLoading(true);
                }
                let loggedInUserInfo = JSON.parse(userSession);
                let roles = userRoles.split(",");
                let sort: any;
                let filter: any;
                let allPricingPrograms: string = ""

                if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(dataState)) {
                    if (dataState.sort[0] && !JavaScriptExtensions.isEmptyOrNullOrUndefined(dataState.sort[0].field)) {
                        sort = dataState.sort[0].field + " " + dataState.sort[0].dir
                    }
                    Date.prototype.toJSON = function(){ return moment(this).format(); }
                    filter = JSON.stringify(dataState.filter)
                }

                if (dealType) {
                    allPricingPrograms = dealType.join(",")
                } else {
                    if (userMainRole === UserRole.MSSeller){
                        allPricingPrograms = `${DealType.CPStrategic},${DealType.OTLE}`
                    }
                    else {
                        allPricingPrograms = `${DealType.CPStrategic},${DealType.DealRegistration},${DealType.SpecialPricing},${DealType.OTLE}`;
                    }
                    
                }
                
                var resellerOrDistributorFilters : IAccount[] = [];
                if(filteredPartnerAccount != undefined && filteredPartnerAccount.length != 0){
                    resellerOrDistributorFilters = filteredPartnerAccount
                }
                const dashboardRequest: IDashboardDataRequest = {
                    userRoleDetails: loggedInUserInfo.userRoleDetails,
                    dealType: allPricingPrograms,
                    email: loggedInUserInfo.email,
                    userRoles: roles.join(","),
                    filter: filter,
                    pageNumber: pageNo,
                    recordsToFetch: gridConfig.kendoGridProps.recordsToFetch,
                    sort: sort,
                    isAllCasesDashboardRequest: true,
                    isLocalEmpowerment: false,
                    organizationId: loggedInUserInfo.organizationId,
                    resellerOrDistributorFilters: resellerOrDistributorFilters,
                    getResellerAndDistiNames: false
                };
                
                
                var apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramDashboardData(dashboardRequest));
                if (apiReturnedData.status === 200) {
                    let newGridConfig = { ...gridConfig };
                    apiReturnedData.data.item1 = apiReturnedData.data.item1.map((item: IOpportunityDealDetails) => ({
                        ...item,
                        expirationDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.expirationDate) ? item.expirationDate : new Date(item.expirationDate as Date)),
                        caseStartDate: (JavaScriptExtensions.isNullOrUndfinedOrEmpty(item.caseStartDate) ? item.caseStartDate : new Date(item.caseStartDate as Date)),
                    }));
                    
                    var finalData: IOpportunityDealDetails[]
                    if(pageChanged === undefined || pageChanged === false) {
                        finalData = apiReturnedData.data.item1;
                    }
                    else {
                        finalData = [...apiReturnedData.data.item1, ...data];
                    }
                    finalData = unionBy(finalData, x => x.id);
                    newGridConfig.kendoGridProps.data = finalData;
                    setData(finalData);
                    newGridConfig.kendoGridProps.totalRecords = finalData.length;
                    setGridConfig(gridConfig);
                    setNonCPStrategicCount(apiReturnedData.data.item2.NonCPStrategicDeals + nonCpStrategicCount)
                    setCpStrategicCount(apiReturnedData.data.item2.CPStrategicDeals + cpStrategicCount)
                    setOtleCount(apiReturnedData.data.item2.OtleDeals + otleCount)
                }
                gridConfig.kendoGridProps.customGridMessage = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoRecordsFound", UIControlsText.NoRecordsFound)  
                if(isFilterApplied == undefined || !isFilterApplied){
                    setIsCaseLoading(false);
                }             
            }
        } catch (error) {
            setIsCaseLoading(false);
        }
    }

    const onGetDataRequest = async (dataState: any, isSortingOrFilterAppliedOrRemoved?: boolean) => {
        setGridDataState(dataState);
        setIsCaseLoading(true);
        let dealType: string[] = []
        if ((((filterBy(data || [], dataState.filter).length - dataState.skip) <= dataState.take) || isSortingOrFilterAppliedOrRemoved === true)) {
            if (userMainRole !== UserRole.MSSeller){
            dealType.push(DealType.SpecialPricing)
            dealType.push(DealType.DealRegistration)
            }
            dealType.push(DealType.CPStrategic)
            dealType.push(DealType.OTLE)
            var pageNo = (!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null) ? pageNumber + 1 : 1;
            if(!isSortingOrFilterAppliedOrRemoved && isSortingOrFilterAppliedOrRemoved !== undefined && isSortingOrFilterAppliedOrRemoved !== null)
            {
                setPageNumber(count => count + 1)
            }
            else
            {
                setPageNumber(1);
            }
            await getAllCases(pageNo, dataState, dealType, true, !isSortingOrFilterAppliedOrRemoved)

        }
        setIsCaseLoading(false);
    }

    function addCase() {
        return (!userRole.includes(UserSubRole.ADD)) ? "" : (<CommandBarButton onClick={() => { setHideDialog(false); }}>
            <div className={_Styles.CommandBarButton}>
                <FontIcon className={_Styles.DashboardIcons} aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddCaseAriaLabel", UIControlsText.AddCaseAriaLabel)} iconName={CssClassConstants.CirclePlusIconName}></FontIcon>
                <span className={_Styles.DashboardBtnText}>{PageConstants.AddCasToDashboard}</span>
            </div>
        </CommandBarButton>)
    }

    const initiateRefresh = () => {
        setIsRefreshed(true)
    }

    const onRefresh = () => {
        if(isRefreshed){
            let newGridConfig = { ...gridConfig };
            newGridConfig.kendoGridProps.isRefresh = true
            newGridConfig.kendoGridProps.data = []
            newGridConfig.kendoGridProps = populateGridColumns(newGridConfig.kendoGridProps)
            newGridConfig.kendoGridProps.noOfRecords = getPageNumberFromSession(newGridConfig.kendoGridProps)
            setGridConfig(newGridConfig)
            setData([])
            newGridConfig.kendoGridProps.customGridMessage = ""
        }
    }

    const onfilterByPartner = (account: IAccount[] | undefined) => {
        setfilteredPartnerAccount(account);
    }

    const onSuccess = (showMessageBar: boolean, messageType: any, messageText: string, isReloadData: boolean, resetHideDialog: boolean): void => {

        if (resetHideDialog) {
            setHideDialog(resetHideDialog);
        }

        if (showMessageBar) {
            setErrorMessageType(messageType);
            setMsgBarText(messageText);
            setshowMessageBarOnDashboard(true);
        }
        if (isReloadData) {
            setIsRefreshed(true);
        }
    };

    function addRefreshIcon() {
        return (
            <CommandBarButton onClick={initiateRefresh}>
                <IconButton iconProps={{ iconName: CssClassConstants.RefreshIconName }} ariaLabel={CssClassConstants.RefreshIconName} title="Refresh" styles={RefreshButtonStyles} />
                <span className={_Styles.DashboardBtnText}>{PageConstants.Refresh}</span>
            </CommandBarButton>
        )

    }

    return (
        <Fragment>
            {(!userRole.includes(UserSubRole.ADD)) ? (
                <div id="RefreshIconButton" className={_Styles.CommandBarButton}>
                    {addRefreshIcon()}
                </div>
            ) : <div id="RefreshButton" className={_Styles.CommandBarButton}>
                {addRefreshIcon()}
            </div>}
            <div className={CssClassConstants.AddToCaseButtonClass}>
                {addCase()}
            </div>
            <div data-nextgen-parent-group="Dashboard - All cases" data-nextgen-parentid= "Dashboard - All cases">
            <Stack>
            { userRoles.includes(UserSubRole.ADD) &&
                    <Row className="text-center mt-2 ms-Label">
                        <Stack>
                            <DashboardSearch  {...{onfilterByPartner : onfilterByPartner } }/>
                        </Stack>
                    </Row>
                }
                <Row>
                    {showMessageBarOnDashboard && (
                        <Stack>
                            <div>
                                <MessageBar messageBarType={errorMessageType} onDismiss={resetDashboardMessageBarChoice} dismissButtonAriaLabel="Close" isMultiline={true}>
                                    {msgBarText}
                                </MessageBar>
                            </div>
                        </Stack>
                    )}
                </Row>
                {isCaseLoading &&
                    <Stack>
                        <div>
                            <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingCases", UIControlsText.LoadingCases)} />
                        </div>
                    </Stack>
                }
                <DashboardGrid {...{ kendoGridProps: { ...gridConfig.kendoGridProps }, onGetDataRequest: onGetDataRequest }} />
                <div id="footerPadding"></div>
            </Stack>
            </div>            
            {/* Add Case Model */}
            <AddToCase {...{ hideDialog: hideDialog, onSuccess }} />
        </Fragment>
    );
};