import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { _Styles, DefaultPageStyles, ActionButtonStyle, textFieldStylesOneColumn } from '../../../../content/styles/Page.styles';
import { Stack, IStackStyles, IStackTokens, StackItem } from "@fluentui/react/lib/Stack";
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Label } from '@fluentui/react/lib/Label';
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import { ILegalEntity } from "../../../../models/ILegalEntity";
import { AffiliationType } from "../../../../models/AffiliationType";
import { UIConfigIdentifierConstants, UIControlsText } from '../../../../content/UIControlsText';
import { getter } from "@progress/kendo-react-common";
import { useInternalPricingContext } from '../context/internalpricing-context';
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { AzSearchStore, Store } from "azsearchstore";
import { SearchBar } from "../../../../components/shared/search/searchbar";
import NextGenKendoGrid from "../../../../components/shared/grid/nextgenkendogrid"
import { IGridProps } from "../../../../components/shared/grid/IGridProps"
import { readGridConfig, getSelectedCountry, isFieldVisible, isNullOrEmpty, getFileArrayBuffer, readExcelHeader, processExcelDataToJson, exportToExcel, getRuleValidationMessage, getEntityDataFromSession, isFieldDisabled, getTextFieldErrorMessage } from "../../../../components/shared/common/util"
import { GridPageChangeEvent } from "@progress/kendo-react-grid";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import { GridType } from "../../../../models/GridType";
import { CustomerType } from "../../../../models/CustomerType";
import { nextGenService } from "../../../../services/NextGenService";
import { IInternalPricingFacetProps } from "../props/IInternalPricingFacetProps";
import { IFacetData } from "../../../../models/IFacetData";
import { SearchableComboBoxComponent } from "../../../../components/shared/searchablecombobox/searchablecombobox";
import { ActionType } from "../../../../models/ActionType";
import { PageMode } from "../../../../models/PageMode";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { CoherenceDialog, CoherenceDialogFooter, CoherencePivot, CoherencePivotTabProps } from "@cseo/controls";
import { ICustomerVertical } from "../../../../models/ICustomerVertical";
import { Guid } from "../../../../infrastructure/Guid";
import { IAttachment } from "../../../../models/IAttachment";
import { encode } from "base64-arraybuffer";
import { UploadLegalEntity } from "../../../../models/UploadLegalEntity";
import { DialogType, Link, PrimaryButton } from "office-ui-fabric-react";
import DropDownGridCell from "../../../../components/shared/grid/dropDownGridCell";
import { dropDownYesOrNoData } from "../../../../services/StaticData";
import { PageConstants } from "../../../pageconstants/Constants";
import { CaseActionTypes } from "../../common/reducer/CaseActionTypes";
import { PageStyles } from "../content/Page.InternalPricingProgram.Styles";
import { RadioGridCell } from "../../../../components/shared/grid/radioGridCell";
import { DealType } from "../../../../models/DealType";
import { PanelType, Panel, ActionButton, getTheme } from "@fluentui/react";
import CreateUnmanagedCustomer from "../../Customer/createUnmanagedCustomer";
import { CustomerStatusCodes } from "../../../../models/CustomerStatusCodes";
import { stackContainerStyles, stackItemCustomerStyles, stackItemStyles } from '../../PricingProgramForm/PricingProgramFormUtils';
import { UIControlType } from '../../../../models/UIControlType';
import { FormRenderingService } from '../../../shared/Components/DynamicFormRendering';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { ErrorMessages } from "../../../../content/ErrorMessages";

const stackStyles: Partial<IStackStyles> = { root: { width: 1100 } };
const stackTokens: IStackTokens = { childrenGap: 20 };
var selectedLegalData: ILegalEntity[] = []
/**
 * InternalPricing LegalEntities Search And Upload Component.
 * @function component
 */
//start: legal entity selection option: search/upload
const InternalPricingLegalEntitiesSearchAndUpload: React.FunctionComponent<IInternalPricingFacetProps> = (props: IInternalPricingFacetProps) => {
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext(); //initialize context
    const [userSelectedAffiliationType, setuserSelectedAffiliationType] = useState<number>(internalPricingContextState.opportunityDeals[0].affiliationType || 0);
    const [searchGridconfig, setSearchGridconfig] = useState<IGridProps>(readGridConfig(GridType.LegalEntitySearch));
    const [selectionGridconfig, setSelectionGridconfig] = useState<IGridProps>(readGridConfig(GridType.LegalEntitySelection, internalPricingContextState.opportunityDeals[0]?.affiliationType === AffiliationType.AffiliateCompanies, ApplicationConstants.LegalEntitySelectionUnitstransact));
    const [otleSearchGridconfig, setOTLESearchGridconfig] = useState<IGridProps>(readGridConfig(GridType.LegalEntitySearchOTLE));
    const [selectedLegalGridData, setSelectedLegalGridData] = useState<ILegalEntity[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<ILegalEntity>();
    const [selectedIsPrimary, setselectedIsPrimary] = useState({ PrimaryName: "", PrimaryCountry: "", PrimaryID: "", CountryName: "" });
    const [legalOption, setlegalOption] = useState<string>(UIControlsText.DefaultLegalOption);
    const [legalOptionDisable, setlegalOptionDisable] = useState<boolean>(false);
    const [legalSearchChecked, setlegalSearchChecked] = useState<boolean>(true);
    const [showSearchFacet, setshowSearchFacet] = useState<boolean>(false);
    const [searchFilterButtonText, setSearchFilterButtonText] = useState<string>(UIControlsText.ShowSearchFilter);
    const [hideSearchFilterButton, setHideSearchFilterButton] = useState<boolean>(true);
    const idGetter = getter(searchGridconfig.kendoGridProps.dataItemKey);
    
    const [crmChildAccountName, setCrmChildAccountName] = useState<IFacetData[]>([]);
    const [selectedCrmChildAccountNameValues, setSelectedCrmChildAccountNameValues] = useState<IFacetData[]>([]);
    const [address, setAddress] = useState<IFacetData[]>([]);
    const [selectedAddressValues, setSelectedAddressValues] = useState<IFacetData[]>([]);
    const [city, setCity] = useState<IFacetData[]>([]);
    const [selectedCityValues, setSelectedCityValues] = useState<IFacetData[]>([]);
    const [postalCode, setPostalCode] = useState<IFacetData[]>([]);
    const [selectedPostalCodeValues, setSelectedPostalCodeValues] = useState<IFacetData[]>([]);
    const [stateProvince, setStateProvince] = useState<IFacetData[]>([]);
    const [selectedStateProvinceValues, setSelectedStateProvinceValues] = useState<IFacetData[]>([]);
    const [childCrmId, setChildCrmid] = useState<IFacetData[]>([]);
    const [selectedCrmidValues, setSelectedCrmidValues] = useState<IFacetData[]>([]);
    const [tpid, setTpid] = useState<IFacetData[]>([]);
    const [selectedTpidValues, setSelectedTpidValues] = useState<IFacetData[]>([]);
    const [gpid, setGpid] = useState<IFacetData[]>([]);
    const [selectedGpidValues, setSelectedGpidValues] = useState<IFacetData[]>([]);
    const [dunsid, setDunsid] = useState<IFacetData[]>([]);
    const [selectedDunsidValues, setSelectedDunsidValues] = useState<IFacetData[]>([]);
    let allFacetNames = ApplicationConstants.CustomerSearchFacetNames;
    let allSelectedFacets = [selectedCrmChildAccountNameValues, selectedAddressValues, selectedCityValues, selectedPostalCodeValues, selectedStateProvinceValues, selectedCrmidValues, selectedTpidValues, selectedGpidValues, selectedDunsidValues];
    const [filterString, setFilterString] = useState<string>("");
    const [oppdealId, setOppdealId] = useState<string>((internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].id) || "");
    const [savedselectionState, setsavedselectionState] = useState<any>({});
    const [currentUserSelection, setCurrentUserSelection] = useState<ILegalEntity[]>([]);
    const [isDialogHidden, setIsDialogHidden] = useState<boolean>(true);
    const [IsOTLE, setIsOTLE] = useState<boolean>(false);
    const [IsCPS, setIsCPS] = useState<boolean>(false);
    const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const internalPricingDealTypeValue = internalPricingContextState?.opportunityDeals[0]?.dealType?.name?.toLowerCase();
    const [hideUnManagedCustomerEdit, SetHideUnManagedCustomerEdit] = useState<boolean>(true);
    const [customerSearchGuidanceText, setCustomerSearchGuidanceText] = useState<string>();
    const [hideCustomerDialog, setHideCustomerDialog] = useState(true);
    const [customerMessage, setCustomerMessage] = useState<string>("");
    const [customerName, setCustomerName] = useState<string>("");

    //Start: Edit mode functionality
    useEffect(() => {
        if (internalPricingContextState?.opportunityDeals[0]?.dealType?.name === DealType.OTLE) {
            setIsOTLE(true);
            setGuidanceText();

            if (internalPricingContextState?.opportunityDeals[0]?.geoCountries) {
                internalPricingContextState.opportunityDeals[0].dealCustomerCountry = internalPricingContextState?.opportunityDeals[0]?.geoCountries[0].countryId;
            }
        }
        else if (internalPricingContextState?.opportunityDeals[0]?.dealType?.name === DealType.CPStrategic) {
            setIsCPS(true);
        }
        if (internalPricingContextState?.opportunityDeals[0]?.id) {
            setOppdealId(internalPricingContextState?.opportunityDeals[0]?.id);
            var isCustomerActive = internalPricingContextState.isCustomerActive;
            if(isCustomerActive !== undefined && !isCustomerActive){
                setHideCustomerDialog(false);
                var customers = internalPricingContextState?.opportunityDeals[0]?.dealCustomers;
                if (customers !== undefined && customers.length > 0) {
                    setCustomerName(customers[0]?.legalEntityName);
                    if (customers[0]?.isManaged) {
                        setCustomerMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InActiveManagedCustomerMessage", ErrorMessages.InActiveManagedCustomerMessage))
                    }
                    else {
                        setCustomerMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InActiveUnManagedCustomerMessage", ErrorMessages.InActiveUnManagedCustomerMessage))
                    }
                }
            }
        }
        if (internalPricingContextState.selectedPivot === UIControlsText.LegalEntitiesText && (
            internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() || internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase())) {
            if (internalPricingContextState?.opportunityDeals[0]?.dealCustomers) {
                let legalentity: ILegalEntity[] = internalPricingContextState?.opportunityDeals[0]?.dealCustomers;
                if (internalPricingContextState?.opportunityDeals[0].dealType?.name === DealType.OTLE) {
                    setSelectedCustomer(legalentity[0]);
                    if (legalentity && legalentity?.length > 0 && internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()
                        && legalentity[0].isManaged === false && (internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Approved || OpportunityDealStatusCodes.Incomplete)) {
                        SetHideUnManagedCustomerEdit(false);
                    }
                }
                let primaryLegalEntityId: string = "";
                const selectionState: any = {};
                if (internalPricingContextState.pageMode?.toLowerCase() !== PageMode.View.toLowerCase()) {
                    var primaryLegal = legalentity.filter(le => le.customerType === CustomerType.Primary);
                    primaryLegal.forEach(item => { selectionState[idGetter(item)] = true });
                    setsavedselectionState(selectionState);

                    setlegalOptionDisable(legalentity.length > 0);
                    if (primaryLegal.length > 0) {
                        //there will be one primary, so taking 0th element from filter list
                        setPrimaryLegalentity(primaryLegal[0]);
                        primaryLegalEntityId = primaryLegal[0].id || "";
                    }
                }
                legalentity = getLegalEnityModel(legalentity, primaryLegalEntityId, false, false, ActionType.Update);
                updateSelectedLegalAndDispatch(legalentity, selectionState);
            }
        }
    }, [internalPricingContextState.selectedPivot, internalPricingContextState?.opportunityDeals[0]?.id]);
    //end: Edit mode functionality

    //spliter related: helps to divide page in two sections and define content % here.
    const [panes, setPanes] = useState<Array<any>>();
    React.useEffect(() => {
        setPanes(props.splitterConfig);
    }, []);
    //change event for expand an collapse search filter
    const onSplitterChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
        var isCollapsed = event.newState[0].collapsed || false;
        setSearchFilterButtonText(isCollapsed ? UIControlsText.ShowSearchFilter : UIControlsText.HideSearchFilter)
    };
    useEffect(() => {
        setuserSelectedAffiliationType(internalPricingContextState.opportunityDeals[0].affiliationType || 0);
        var isUnitstransactVisible = false;
        if (JavaScriptExtensions.isDefined(internalPricingContextState.opportunityDeals[0].affiliationType)) {
            isUnitstransactVisible = internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies;
            var legalEntityConfig = readGridConfig(GridType.LegalEntitySelection, isUnitstransactVisible, ApplicationConstants.LegalEntitySelectionUnitstransact);
            var primaryLegalEntity: ILegalEntity = {} as ILegalEntity;
            //reset unitscommitted when affiliationtype changed in deal overview tab
            var updatedLegalData = selectedLegalGridData.map(item => {
                item.unitsCommitted = 0
                return item;
            });
            legalEntityConfig.kendoGridProps.data = updatedLegalData;
            legalEntityConfig.kendoGridProps.totalRecords = updatedLegalData.length;
            setSelectionGridconfig(legalEntityConfig)
            let primaryFilter = updatedLegalData.filter((item) => item.customerType === CustomerType.Primary);
            if (primaryFilter && primaryFilter.length > 0) {
                primaryLegalEntity = primaryFilter[0];
            }
            dispatchSelectedData(updatedLegalData, primaryLegalEntity.id || "", false);
            if (isUnitstransactVisible) {
                internalPricingContextDispatch({ type: CaseActionTypes.totalunitsTransactedChanged, dealVolume: { key: oppdealId, value: 0 } });
            }
        }

        if (isUnitstransactVisible){
            setExcelHeaderColumns([UploadLegalEntity.Country, UploadLegalEntity.LegalEntityName, UploadLegalEntity.TPID, UploadLegalEntity.CustomerAccountId, UploadLegalEntity.Segment, UploadLegalEntity.CustomerVertical, UploadLegalEntity.DealVolume, UploadLegalEntity.IsManaged])
        }
        else{
            setExcelHeaderColumns([UploadLegalEntity.Country, UploadLegalEntity.LegalEntityName, UploadLegalEntity.TPID, UploadLegalEntity.CustomerAccountId, UploadLegalEntity.Segment, UploadLegalEntity.CustomerVertical, UploadLegalEntity.IsManaged])
        }
    }, [internalPricingContextState.opportunityDeals[0].affiliationType]);
    
    //On Change of context object Deal customers Refresh/Set the grid data.
    useEffect(() => {
        let legalEntityRecords = internalPricingContextState.opportunityDeals[0].dealCustomers;
        if (legalEntityRecords != undefined && legalEntityRecords != null && legalEntityRecords.length > 0) {
            let updatedConfig = { ...selectionGridconfig };
            updatedConfig.kendoGridProps.data = legalEntityRecords;
            selectedLegalData = legalEntityRecords;
            setSelectedLegalGridData(legalEntityRecords);
            setSelectionGridconfig(updatedConfig);
        }
    }, [internalPricingContextState.opportunityDeals[0].dealCustomers]);

    const dispatchSelectedData = (selectedLegals: ILegalEntity[], primaryLegalID: string, isAddition: boolean, action?: string) => {
        let legalData = getLegalEnityModel(selectedLegals, primaryLegalID, true, isAddition, action);
        var totalunits = 0;
        //get total units except deleted items
        if (internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies) {
            legalData.map((item: ILegalEntity) => { item.action !== ActionType.Delete ? totalunits += item.unitsCommitted : totalunits += 0 });
        }
        else {
            totalunits = internalPricingContextState.opportunityDeals[0].dealVolume || 0;
        }
        internalPricingContextDispatch({ type: CaseActionTypes.selectedLegalEntityChanged, dealCustomers: { key: oppdealId, value: legalData } });
        internalPricingContextDispatch({ type: CaseActionTypes.totalunitsTransactedChanged, dealVolume: { key: oppdealId, value: totalunits } });
    }
    //construct legalentity model for selection grid
    const getLegalEnityModel = (legalEntities: ILegalEntity[], primaryLegalID: string, isManaged: boolean, isAddition: boolean, action?: string): ILegalEntity[] => {
        let selectedEntites: ILegalEntity[] = [];
        selectedEntites = legalEntities.map((item: any) => {
            if(!item.isUploadedRecord){
                return {
                    id: item.id ? item.id : item.customerId,
                    customerId: item.customerId ? item.customerId : item.id,
                    country: item.Country ? item.Country : item.country,
                    crmAccountId: item.ChildCRMID ? item.ChildCRMID : item.crmAccountId,
                    legalEntityName: item.OrganizationName ? item.OrganizationName : item.legalEntityName,
                    tpid: item.TPID ? item.TPID : item.tpid,
                    segment: item.Segment ? item.Segment : item.segment,
                    vertical: item.Vertical ? item.Vertical : item.vertical,
                    verticalCategory: item.VerticalCategory ? item.VerticalCategory : item.verticalCategory,
                    unitsCommitted: item.unitsCommitted === undefined ? 0 : item.unitsCommitted,
                    isManaged: item.isManaged !== undefined ? item.isManaged : isManaged,
                    customerType: item.id === primaryLegalID ? CustomerType.Primary : CustomerType.Affiliates,
                    errors: "",
                    warnings: "",
                    action: item.action ? item.action : action,
                    managedCustomerId: item.managedCustomerId,
                    editMode: item.editMode,
                    legalEntityType: item.legalEntityType,
                    isUploadedRecord: false
                } as ILegalEntity
            }
            else{
                return {
                    id: item.id? item.id : Guid.newGuid(),
                    customerId: item.customerId,
                    customerType: item.customerType,
                    country: item.country?.toString().trim(),
                    crmAccountId: item.crmAccountId?.toString().trim(),
                    legalEntityName: item.legalEntityName?.toString().trim(),
                    tpid: Number.isNaN(Number(item.tpid))? 0 : Number(item.tpid),
                    segment: item.segment?.toString().trim(),
                    vertical: item.vertical?.toString().trim(),
                    unitsCommitted: item.unitsCommitted,
                    isManaged: item.isManaged,
                    errors: item.errors?.toString().trim(),
                    action: ActionType.Add,
                    isUploadedRecord: true
                } as ILegalEntity
            }
        })
        return selectedEntites;
    }
    /** Start: AZ Search Configuration */
    const store = new AzSearchStore();
    const [selectedCountries, setSelectedCountries] = useState<IDropdownOption[]>([]);
    const [countriesDropDown, setCountries] = useState<IDropdownOption[]>([]);
    const [selectedSearchValue, setSelectedSearchValue] = useState<string>("");
    const [globalSearchFilterExpression, setGlobalSearchFilterExpression] = useState<string>("");

    useEffect(() => {
        setCountriesDropdown(internalPricingContextState.opportunityDeals[0].geoCountries?.filter(c => c.action !== ActionType.Delete).map(x => x.countryId) || []);
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() || internalPricingContextState.pageMode?.toLowerCase() === PageMode.Create.toLowerCase()) {
            resetSelectedData(internalPricingContextState.opportunityDeals[0].geoCountries?.filter(c => c.action !== ActionType.Delete).map(x => x.countryId) || []);
        }

        let countryValues = internalPricingContextState.opportunityDeals[0].geoCountries?.filter(c => c.action !== ActionType.Delete).map(x => x.countryName) || [];
        //set the global filter search expression
        if (countryValues.length > 0) {
            setGlobalSearchFilterExpression(countryValues.map(item => ApplicationConstants.CustomerSearchCountryGlobalIndex + " eq '" + item + "'").join(" or "));
        }
    }, [internalPricingContextState.opportunityDeals[0].geoCountries]);

    // define search configuration
    store.setConfig({
        index: ApplicationConstants.CustomerSearchIndex,
        queryKey: process.env.REACT_APP_SEARCH_API_KEY || "",
        service: process.env.REACT_APP_SEARCH_API_ENV || ""
    });

    // set suggester, project some additional fields into the returned suggestions
    store.updateSuggestionsParameters({
        suggesterName: ApplicationConstants.CustomerSearchSuggesterName,
        select: ApplicationConstants.CustomerSearchSelectColumn,
        top: ApplicationConstants.TopSearchSuggesstions,
        fuzzy: ApplicationConstants.IsFuzzy,
        filter: globalSearchFilterExpression,
    });

    const suggestionsProcessor = function (results: any) {
        return results.map(function (result: any) {
            result.searchText = result["@search.text"];
            return result;
        });
    };
    store.setSuggestionsProcessor(suggestionsProcessor);

    // Country selection change
    const onCountrySelectionChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setSelectedCountries((prevState: IDropdownOption[]) => {
                let newState = item.selected ? [...prevState, item as IDropdownOption] : prevState.filter(key => key.key !== item.key)
                return newState;
            });
        }
    };
    //this function used to reset unit transact value and filter country based on geo selection in deal overview
    const resetSelectedData = (dealCountries: string[]): void => {
        var countriesDropdownValues: IDropdownOption[] = getSelectedCountry(dealCountries);
        var newSelection: Array<ILegalEntity> = [];
        countriesDropdownValues.forEach((countryItem: IDropdownOption) => {
            internalPricingContextState.opportunityDeals[0].dealCustomers?.filter(
                (selectedItem: ILegalEntity) => selectedItem.country === countryItem.text)
                .map((filteredItem: ILegalEntity) => {
                    newSelection.push(filteredItem);
                });
        });
        //reset when primary country removed from deal overview
        if (countriesDropdownValues.findIndex((item) => item.text === selectedIsPrimary.CountryName) === -1) {
            setselectedIsPrimary({ ["PrimaryName"]: "", ["PrimaryID"]: "", ["PrimaryCountry"]: "", ["CountryName"]: "" })
        }
        setCustomerInfoForOpportunityDeal();
        updateSelectedLegalAndDispatch(newSelection, savedselectionState, ActionType.Update);
        selectedLegalData = newSelection;

        //reset search when geo country changed 
        let searchlegalConfig = { ...searchGridconfig };
        searchlegalConfig.kendoGridProps.data = [];
        searchlegalConfig.kendoGridProps.totalRecords = 0;
        setSearchGridconfig({ ...searchlegalConfig });
        onClear();
    };
    //reset search grid selection after adding
    const resetSearchgrid = (deletedItem: ILegalEntity, gridData: ILegalEntity[]): void => {
        let searchlegalConfig = { ...searchGridconfig };
        const state: any = {};
        if (gridData.length > 0) {
            currentUserSelection?.forEach(item => {
                if (item.id !== deletedItem.id) {
                    state[idGetter(item)] = true
                }
            });
            setCurrentUserSelection(currentUserSelection.filter(item => item.id !== deletedItem.id));
        }
        else {
            setCurrentUserSelection(state);
        }
        searchlegalConfig.kendoGridProps.defaultMainGridSelectionstate = state;
        setSearchGridconfig({ ...searchlegalConfig });
    }
    //function to update country dropdown according to the geo country selection
    const setCountriesDropdown = (dealCountries: string[]): void => {
        setCountries(getSelectedCountry(dealCountries));
        setSelectedCountries(getSelectedCountry(dealCountries));
    }

    // Get customer data from Index and populate grid
    const getCustomerSearchData = async (filterStringExpression: string, selectedValue: string, skip: number): Promise<void> => {
        try {
            if (selectedCountries.length > 0) {
                store.setGlobalFilter(ApplicationConstants.CustomerSearchCountryGlobalIndex, selectedCountries.map(item => ApplicationConstants.CustomerSearchCountryGlobalIndex + " eq '" + item.text + "'").join(" or "));
                if (filterStringExpression.length == 0) {
                    filterStringExpression = props.createGlobalFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex], [selectedCountries.map(item => item.text)], false);

                    if (filterStringExpression.substring(filterStringExpression.length - 4) == 'and ') {
                        filterStringExpression = filterStringExpression.slice(0, -4);
                    }
                }
            }

            let facetString = props.facetList(allFacetNames);
            let apiReturnedData = await Promise.resolve(nextGenService.getCustomerSearchResults(filterStringExpression, selectedValue, skip, facetString));

            if (apiReturnedData.status === 200) {
                let searchConfig;
                if (IsCPS) {
                    searchConfig = { ...searchGridconfig };
                }
                else {
                    searchConfig = { ...otleSearchGridconfig }
                }
                searchConfig.kendoGridProps.data = (apiReturnedData.data as { [key: string]: any })["value"] as string;
                searchConfig.kendoGridProps.totalRecords = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                setSearchGridconfig({ ...searchConfig });

                for (var i = 0; i < allFacetNames.length; i++) {
                    setComboBoxDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as string[], allFacetNames[i]);
                }           
                setshowSearchFacet(true);
                setPanes(props.splitterConfig);
                setHideSearchFilterButton(false);
            }
        }
        catch (error) {
            // if there's an error, log it
            console.log(error);
        }
    }

    // Grid Page change
    const onPageChange = (event: GridPageChangeEvent): void => {
        store.setInput(selectedSearchValue);
        getCustomerSearchData(filterString, selectedSearchValue, event.page.skip);
    };

    const onCustomerSelectionHandler = (cellId: string, selectedValue: any): void => {
        let searchConfig = { ...otleSearchGridconfig };
        let customerData;
        if (cellId != "unManaged") {
            customerData = searchConfig.kendoGridProps.data.filter((x: { id: Guid; }) => x.id === selectedValue)[0];
        }
        else {
            customerData = selectedValue;
        }

        let opportunityDeal = internalPricingContextState.opportunityDeals[0];
        
        let newSelection = customerData != undefined ? [{ ...customerData}] : [];
        opportunityDeal.dealCustomers = getLegalEnityModel(newSelection, getPrimaryLegalID(newSelection), true, true, ActionType.Add);
        opportunityDeal.dealCustomers[0].customerType = CustomerType.Primary;
        setSelectedCustomer(opportunityDeal.dealCustomers[0]);
        setPrimaryLegalentity(opportunityDeal.dealCustomers[0])
        setCustomerInfoForOpportunityDeal();
    }

    // make generic for OTLE/CPS, using primary legal entity for customer name in case of CPS. If more than one country for CPS don't set customer country.
    const setCustomerInfoForOpportunityDeal = () => {
        let opportunitydeal = internalPricingContextState.opportunityDeals;
        let oppDealCustomer = opportunitydeal[0].dealCustomers;
        let geoCountries = opportunitydeal[0].geoCountries;

        if (geoCountries != undefined && oppDealCustomer != undefined) {
            if (IsOTLE && oppDealCustomer[0]?.legalEntityName != undefined) {
                let customerCountryName = oppDealCustomer[0].country;
                let dealCustomerCountry = geoCountries.filter(c => c.countryName == customerCountryName);

                opportunitydeal[0].dealCustomerCountry = dealCustomerCountry[0].countryId;
                opportunitydeal[0].customerName = oppDealCustomer[0].legalEntityName;
            }
            else if (IsCPS && (oppDealCustomer.find(x => x.customerType === 1) != undefined))
            {
                let primaryCust = oppDealCustomer.find(x => x.customerType === 1);
                if (geoCountries.length === 1 && (primaryCust?.country != '' || undefined)) {
                    let PrimaryCountry = geoCountries.filter(x => x.countryName == primaryCust?.country)
                    opportunitydeal[0].dealCustomerCountry = PrimaryCountry[0].countryId;
                }
                else 
                {
                    opportunitydeal[0].dealCustomerCountry = undefined;
                }
                opportunitydeal[0].customerName = primaryCust?.legalEntityName;
            }
        }
    }

    // On click Searchbar Submit button
    const onSubmit = (selectedValue: string): void => {
        setSelectedSearchValue(selectedValue);
        clearFacets();
        setSearchFilterButtonText(UIControlsText.ShowSearchFilter);
        getCustomerSearchData("", selectedValue, 0);
    };

    // Reset facets
    const clearFacets = (): void => {
        for (let i = 0; i < allFacetNames.length; i++) {
            setComboBoxDatasource([], allFacetNames[i]);
            setSelectedValuesDatasource([], allFacetNames[i]);
        }
    };

    // Clear Search
    const onClear = (): void => {
        setSelectedSearchValue("");
        if (IsOTLE) {
            setOTLESearchGridconfig(readGridConfig(GridType.LegalEntitySearchOTLE));
        }
        else {
            setSearchGridconfig(readGridConfig(GridType.LegalEntitySearch));
        }
        clearFacets();
        setshowSearchFacet(false);
        setPanes(props.splitterConfig);
        setSearchFilterButtonText(UIControlsText.ShowSearchFilter)
        setHideSearchFilterButton(true);
    };

    // method to get primarylegalid
    const getPrimaryLegalID = (legalEntity: ILegalEntity[]): string => {
        var primaryIndex = legalEntity.findIndex((item) => item.customerType === CustomerType.Primary);
        let primaryId = (primaryIndex !== -1 ? legalEntity[primaryIndex].id : selectedIsPrimary.PrimaryID) || ""
        return primaryId;
    }
    /** End: AZ Search Configuration */


    //on click checkbox, to selection grid at bottom
    const addSelectedLegalEntites = (selectedLegals: ILegalEntity[]): void => {
        var newSelectedLegals: ILegalEntity[] = [];
        let legalData = getLegalEnityModel(selectedLegals, getPrimaryLegalID(selectedLegals), true, true, ActionType.Add);
        if (legalData.length > 0) {
            newSelectedLegals = internalPricingContextState.opportunityDeals[0].dealCustomers || [];
            legalData.map(item => {
                var findItem = undefined;
                //validate new customer by managedCustomerId on approved case. as customerId that retruns from api is not matching with legal search(both are different entity)
                if (internalPricingContextState.pageMode === PageMode.Edit && internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft) {
                    findItem = newSelectedLegals.find(x => x.managedCustomerId ? x.managedCustomerId === item.customerId : x.customerId === item.customerId);
                }
                else {
                    findItem = newSelectedLegals.find(x => x.customerId === item.customerId);
                }
                if (findItem === undefined) {
                    newSelectedLegals.push({ ...item, editMode: item.editMode });
                }
            });
            setlegalOptionDisable(newSelectedLegals.length === 0 ? false : true);
            selectedLegalData = newSelectedLegals;
            updateSelectedLegalAndDispatch(newSelectedLegals, savedselectionState, ActionType.Add);
            setCurrentUserSelection(selectedLegals);
        }
    }

    //hide clearall based on case status/pagemode
    const hideClearAll = (legalEnities: ILegalEntity[]): boolean => {
        return (internalPricingContextState.pageMode === PageMode.View ||
            (internalPricingContextState.pageMode === PageMode.Edit && internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft)) ? false : true;
    }

    //function used to update grid data based on add/reset
    const updateSelectedLegalAndDispatch = (legalEnities: ILegalEntity[], selectionState?: any, action?: string): void => {
        var legalEntityWithoutDelete = legalEnities.filter(item => item.action !== ActionType.Delete)
        setSelectedLegalGridData(legalEntityWithoutDelete);
        var isUnitstransactVisible = internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies;
        let selectedlegalConfig = readGridConfig(GridType.LegalEntitySelection, isUnitstransactVisible, ApplicationConstants.LegalEntitySelectionUnitstransact);
        if (internalPricingContextState.pageMode === PageMode.View) {
            selectedlegalConfig.kendoGridProps.data = legalEntityWithoutDelete;
            //avoid editing deal volume in view mode
            var idx = selectedlegalConfig.kendoGridProps.columns.findIndex(item => item.apiField === ApplicationConstants.LegalEntitySelectionUnitstransact);
            if (idx !== -1) {
                selectedlegalConfig.kendoGridProps.columns[idx] = {
                    ...selectedlegalConfig.kendoGridProps.columns[idx], ["isEditable"]: false
                };
            }
            //hiding is Primary column in view mode
            if (selectedlegalConfig.kendoGridProps.additionalColumns) {
                var index = selectedlegalConfig.kendoGridProps.additionalColumns?.findIndex(item => item.apiField === ApplicationConstants.LegalEntitySelectionIsPrimary);
                if (index !== -1) {
                    selectedlegalConfig.kendoGridProps.additionalColumns[index] = {
                        ...selectedlegalConfig.kendoGridProps.additionalColumns[index], ["isVisible"]: false
                    };
                }
            }
            //Showing Legal Entity Type in view mode
            var idx = selectedlegalConfig.kendoGridProps.columns.findIndex(item => item.apiField === ApplicationConstants.LegalEntityType);
            if (idx !== -1) {
                selectedlegalConfig.kendoGridProps.columns[idx] = {
                    ...selectedlegalConfig.kendoGridProps.columns[idx], ["isVisible"]: true
                };
            }
            if (selectedlegalConfig.kendoGridProps.actionTypes) {
                selectedlegalConfig.kendoGridProps.actionTypes.showDownloadExcelGridAction = true
                selectedlegalConfig.kendoGridProps.actionTypes.showRowEditGridAction = false
                selectedlegalConfig.kendoGridProps.actionTypes.showRowDeleteGridAction = false
                selectedlegalConfig.kendoGridProps.actionTypes.showSelectAllCheckboxGridAction = false
                selectedlegalConfig.kendoGridProps.actionTypes.showAddNewRowGridAction = false
            }
        }
        else {
            if (selectedlegalConfig.kendoGridProps.actionTypes) {
                selectedlegalConfig.kendoGridProps.actionTypes.showDownloadExcelGridAction = false
            }
            selectedlegalConfig.kendoGridProps.data = legalEntityWithoutDelete;
        }
        if (selectionState && internalPricingContextState.pageMode !== PageMode.Create) {
            selectedlegalConfig.kendoGridProps.defaultMainGridSelectionstate = selectionState;
            if (internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft) {
                selectedlegalConfig.kendoGridProps.editMode = true;
            }
            else {
                selectedlegalConfig.kendoGridProps.editMode = false;
            }
        }
        else {
            selectedlegalConfig.kendoGridProps.editMode = false;
        }
        //to hide clear all button in view mode
        if (selectedlegalConfig.kendoGridProps.actionTypes?.showClearAllGridAction) {
            selectedlegalConfig.kendoGridProps.actionTypes.showClearAllGridAction = hideClearAll(legalEntityWithoutDelete)
        }
        selectedlegalConfig.kendoGridProps.totalRecords = legalEntityWithoutDelete.length;
        //sending selection state to grid to avoid default selection when remove rows, this is applicable for all create/edit/view
        const primarySelectionState: any = {};
        legalEntityWithoutDelete.forEach(item => { primarySelectionState[idGetter(item)] = item.customerType === CustomerType.Primary });
        selectedlegalConfig.kendoGridProps.defaultMainGridSelectionstate = primarySelectionState;
        setSelectionGridconfig({ ...selectedlegalConfig });
        setSelectedLegalGridData(legalEntityWithoutDelete);
        selectedLegalData = legalEntityWithoutDelete;
        dispatchSelectedData(legalEnities, getPrimaryLegalID(legalEnities), false, action);
    }

    //callback function on checkbox selection of header and/or row(s)
    const onRowSelectionCallBack = (selectedState: any) => {
        const selectedlegals: ILegalEntity[] = [];
        const searchGridDatawithSelection = searchGridconfig.kendoGridProps.data?.map((item: any) => (
            {
                ...item, [searchGridconfig.kendoGridProps.selectedField]: selectedState[idGetter(item)]
            }));
        searchGridDatawithSelection?.map((item: any) => {
            if (item.selected === true) {
                selectedlegals.push(Object.assign({ inEdit: undefined }, item));
            }
        });
        addSelectedLegalEntites(selectedlegals);
    }

    //callback function on checkbox selection of isPrimary
    const onPrimarySelectionCallBack = async (dataItem: any, checked: boolean) => {
        checked ?
            setPrimaryLegalentity(dataItem) : setselectedIsPrimary({ ["PrimaryName"]: "", ["PrimaryID"]: "", ["PrimaryCountry"]: "", ["CountryName"]: "" })
        const selectionState: any = {};
        let updatedLegal = selectedLegalData.map((item: ILegalEntity) => {
            if (item.id === dataItem.id) {
                selectionState[idGetter(item)] = checked;
                item.customerType = checked ? CustomerType.Primary : CustomerType.Affiliates;
            }
            else {
                selectionState[idGetter(item)] = false;
                item.customerType = CustomerType.Affiliates;
            }
            return item;
        });
        setCustomerInfoForOpportunityDeal();
        var isUnitstransactVisible = internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies;
        let updatedConfig = readGridConfig(GridType.LegalEntitySelection, isUnitstransactVisible, ApplicationConstants.LegalEntitySelectionUnitstransact);
        updatedConfig.kendoGridProps.defaultMainGridSelectionstate = selectionState;
        updatedConfig.kendoGridProps.data = updatedLegal;
        setSelectionGridconfig(updatedConfig);
        setsavedselectionState(selectionState);
        dispatchSelectedData(updatedLegal, checked ? dataItem.id : "", false);
        selectedLegalData = updatedLegal;
        try {
            if (checked && !isNullOrEmpty(dataItem.vertical) && !isNullOrEmpty(dataItem.verticalCategory)) {
                let customerVertical: ICustomerVertical = { name: dataItem.vertical, category: dataItem.verticalCategory };
                let apiReturnedData = await Promise.resolve(nextGenService.getBusinessVertical(customerVertical));
                if (apiReturnedData.status === 200 && apiReturnedData.data) {
                    internalPricingContextDispatch({ type: CaseActionTypes.dealVerticalChanged, dealDropDownValue: { key: oppdealId, value: apiReturnedData.data.id } })
                }
            }
            else {
                internalPricingContextDispatch({ type: CaseActionTypes.dealVerticalChanged, dealDropDownValue: { key: oppdealId, value: Guid.emptyGuid() } })
            }
        }
        catch (error) {
            // if there's an error, log it
            console.log(error);
        }
    }

    function setPrimaryLegalentity(dataItem: any) {
        setselectedIsPrimary({
            ["PrimaryName"]: " Primary Legal Entity Name : " + dataItem.legalEntityName,
            ["PrimaryID"]: dataItem.id,
            ["PrimaryCountry"]: " Primary Legal Country : " + dataItem.country + ",",
            ["CountryName"]: dataItem.country
        })
        setCustomerInfoForOpportunityDeal();
    }

    //callback function on delete click event of selected legal entites
    const onDeleteCallBack = (deletedRowData: any, refreshedGridData: ILegalEntity[]) => {
        var isUnitstransactVisible = internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies;
        let deletedlegalConfig = readGridConfig(GridType.LegalEntitySelection, isUnitstransactVisible, ApplicationConstants.LegalEntitySelectionUnitstransact);
        deletedlegalConfig.kendoGridProps.data = refreshedGridData
        deletedlegalConfig.kendoGridProps.totalRecords = refreshedGridData.length;
        //to hide clear all button in approved case
        if (deletedlegalConfig.kendoGridProps.actionTypes?.showClearAllGridAction) {
            deletedlegalConfig.kendoGridProps.actionTypes.showClearAllGridAction = hideClearAll(refreshedGridData)
        }
        if (internalPricingContextState.opportunityDeals[0].dealCustomers?.findIndex((item) => item.customerType === CustomerType.Primary) != -1 && internalPricingContextState.pageMode !== PageMode.Create) {
            deletedlegalConfig.kendoGridProps.editMode = true;
        }
        else {
            deletedlegalConfig.kendoGridProps.editMode = false;
        }
        setSelectionGridconfig({ ...deletedlegalConfig });
        setSelectedLegalGridData(refreshedGridData);
        selectedLegalData = refreshedGridData;
        setlegalOptionDisable(refreshedGridData.length === 0 ? false : true);
        if (selectedIsPrimary.PrimaryID === deletedRowData.id || isNullOrEmpty(deletedRowData.id))
            setselectedIsPrimary({ ["PrimaryName"]: "", ["PrimaryID"]: "", ["PrimaryCountry"]: "", ["CountryName"]: "" });

        dispatchSelectedData(refreshedGridData, getPrimaryLegalID(refreshedGridData), false);
        resetSearchgrid(deletedRowData, refreshedGridData);
    }
    
    //callback function on edit click event of selected legal entites
    const onEditCallBack = (data: any) => {
        if (!isNullOrEmpty(data)) {
            data.errors = validateExcelRecord(data);
            let tempData = internalPricingContextState.opportunityDeals[0].dealCustomers || [];;
            let editedRowIndex = tempData.findIndex((obj => obj.id == data.id));
            if (editedRowIndex != -1)
                tempData[editedRowIndex] = data;
            else {
                data.Id = tempData.length + 1;
                tempData.unshift(data);
            }
            dispatchSelectedData([...tempData], tempData.filter(x => x.customerType == CustomerType.Primary)[0]?.id ||"",true);
            validateDealCustomers();
            let updatedConfig = readGridConfig(GridType.LegalEntitySelection, internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies, ApplicationConstants.LegalEntitySelectionUnitstransact);
            updatedConfig.kendoGridProps.data = tempData;
            setSelectionGridconfig(updatedConfig);
        }
    }

    //callback function on click of process button in  legal entities, this will help us to enable/disable radio selection
    const onProcessCallBack = (excelProps: any) => {
        setlegalOptionDisable(excelProps.processedCount === 0 ? false : true);
    }

    // Method called on onChange event of any child combobox (facet)
    const selectedValues = async (selectedValue: any[], facetName: string): Promise<void> => {
        try {
            // create filter expression based on selected facet data
            let filterStringExpression = "";
            if (selectedCountries.length > 0) {
                filterStringExpression = filterStringExpression + props.createGlobalFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex], [selectedCountries.map(item => item.text)], false);
            }

            filterStringExpression = filterStringExpression + props.createFilterExpression(allFacetNames, facetName, selectedValue, allSelectedFacets);

            if (filterStringExpression.substring(filterStringExpression.length - 4) == 'and ') {
                filterStringExpression = filterStringExpression.slice(0, -4);
            }

            setFilterString(filterStringExpression);

            let facetString = props.facetList(allFacetNames);
            let apiReturnedData = await Promise.resolve(nextGenService.getCustomerSearchResults(filterStringExpression, selectedSearchValue, 0, facetString))

            if (apiReturnedData.status === 200) {

                let searchlegalConfig;
            if (IsOTLE) {
                searchlegalConfig = { ...otleSearchGridconfig };
            }
            else {
                searchlegalConfig = { ...searchGridconfig };
            }
                searchlegalConfig.kendoGridProps.data = (apiReturnedData.data as { [key: string]: any })["value"] as string;
                searchlegalConfig.kendoGridProps.totalRecords = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                setSearchGridconfig({ ...searchlegalConfig });

                for (var i = 0; i < allFacetNames.length; i++) {
                    if (allFacetNames[i] != facetName) {
                        setComboBoxDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as string[], allFacetNames[i]);
                        setSelectedValuesDatasource(allSelectedFacets[i], allFacetNames[i]);
                    }
                    else {
                        setSelectedValuesDatasource(selectedValue, facetName);
                    }
                }
            }
        }
        catch (error) {
            // if there's an error, log it
            console.log(error);
        }
    }

    //Set selected values of facets
    const setSelectedValuesDatasource = (selectedValue: any[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.CRMChildAccountFacetName:
                setSelectedCrmChildAccountNameValues(selectedValue);
                break;

            case ApplicationConstants.AddressFacetName:
                setSelectedAddressValues(selectedValue);
                break;

            case ApplicationConstants.CityFacetName:
                setSelectedCityValues(selectedValue);
                break;

            case ApplicationConstants.PostalCodeFacetName:
                setSelectedPostalCodeValues(selectedValue);
                break;

            case ApplicationConstants.StateFacetName:
                setSelectedStateProvinceValues(selectedValue);
                break;

            case ApplicationConstants.ChildCRMIDFacetName:
                setSelectedCrmidValues(selectedValue);
                break;

            case ApplicationConstants.TPIDFacetName:
                setSelectedTpidValues(selectedValue);
                break;

            case ApplicationConstants.GPIDFacetName:
                setSelectedGpidValues(selectedValue);
                break;

            case ApplicationConstants.DunsNumberFacetName:
                setSelectedDunsidValues(selectedValue);
                break;

            default: console.log("Default setSelectedValuesDatasource");
        }
    }

    //Set data source of facets
    const setComboBoxDatasource = (values: any[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.CRMChildAccountFacetName:
                setCrmChildAccountName(values);
                break;

            case ApplicationConstants.AddressFacetName:
                setAddress(values);
                break;

            case ApplicationConstants.CityFacetName:
                setCity(values);
                break;

            case ApplicationConstants.PostalCodeFacetName:
                setPostalCode(values);
                break;

            case ApplicationConstants.StateFacetName:
                setStateProvince(values);
                break;

            case ApplicationConstants.ChildCRMIDFacetName:
                setChildCrmid(values);
                break;

            case ApplicationConstants.TPIDFacetName:
                setTpid(values);
                break;

            case ApplicationConstants.GPIDFacetName:
                setGpid(values);
                break;

            case ApplicationConstants.DunsNumberFacetName:
                setDunsid(values);
                break;

            default: console.log("Default setSelectedValuesDatasource");
        }
    }

    /**
    * callback handler for Pivot change action.
    * @function
    */
    const onPivotChange = (item: CoherencePivotTabProps) => {
        if (item.key) {
            setlegalOption(item.key);
            if (item.key === UIControlsText.DefaultLegalOption) {
                setlegalSearchChecked(true)
            }
            else {
                setlegalSearchChecked(false)
            }
        }
    };

    //hide or show facets on click of button
    const onShowFacets = () => {
        var isCollapsed = false;
        if (panes) {
            isCollapsed = panes[0]?.collapsed;
        }
        setPanes([
            { size: "15%", collapsible: true, resizable: true, collapsed: !isCollapsed },
            {}
        ]);
        setSearchFilterButtonText(isCollapsed ? UIControlsText.HideSearchFilter : UIControlsText.ShowSearchFilter)
    }

    //#region #Upload
    let uploadFileObj: any = { file: {} }
    const [attachment, setAttachment] = useState<IAttachment>();
    const [errorMessage, seterrorMessage] = useState<string>("");
    const [hideDialog, sethideDialog] = useState<boolean>(true);
    const [excelHeaderColumns, setExcelHeaderColumns] = useState<string[]>([]);

    //#region #Upload File Handler and Process Excel File Handler
    // Function to trigger on File Upload and store in UploadFileObj and Call to Process the  excel data
    const uploadFileHandler = (e: any) => {
        const files = e.target.files;
        if (files && files[0]) {
            uploadFileObj = { file: files[0] };
        }
        e.target.files = null;
        e.target.value = null;
        processFileToBuffer();
    };

    // Function to convert the uploaded excel file to array buffer and store to attachment object 
    const processFileToBuffer = () => {
        if (uploadFileObj.file["type"] != undefined) {
            let fileSize = Math.round((uploadFileObj.file.size / 1024) / 1024);
            if (fileSize < 10) {
                getFileArrayBuffer(uploadFileObj, function (arrayBuffer: any) {
                    let attachmentObj: IAttachment = {
                        id: Guid.newGuid(),
                        name: uploadFileObj.file.name,
                        extension: uploadFileObj.file.extension,
                        size: uploadFileObj.file.size,
                        source: "",
                        content: encode(arrayBuffer)
                    };
                    setAttachment(attachmentObj);
                });
                processExcelData();
            }
            else {
                seterrorMessage(UIControlsText.UploadFileSizeErrorMessage);
                sethideDialog(false);
            }
        }
    }

    // Function to process the excel data to JSON and Bind to ILegal Entites
    const processExcelData = () => {
        if (uploadFileObj.file["type"] != undefined) {
            readExcelHeader(uploadFileObj, function (excelHeaderResponse: any) {
                if (validateExcelTemplate(excelHeaderResponse)) {
                    processExcelDataToJson(uploadFileObj, function (processedJson: any) {
                        if (processedJson.length == 0) { // validate atleast one record is entered in the excel file
                            seterrorMessage(UIControlsText.UploadLegalEntityNoData);
                            sethideDialog(false);
                        }
                        else {
                            let excelEntites: ILegalEntity[] = [];
                            let isDealVolumeApplicable = internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies;
                            let newSelectedLegals: ILegalEntity[] = internalPricingContextState.opportunityDeals[0].dealCustomers || [];
                            let isDuplicateRowDetected: boolean = false;
                            if(internalPricingContextState.pageMode?.toLowerCase() === PageMode.Create.toLowerCase()){
                                newSelectedLegals = newSelectedLegals.filter(x => x.isUploadedRecord == false);
                            }
                            excelEntites = processedJson.map((eachItem: any, index: number) => {
                                let newGuid = Guid.newGuid();
                                let eachEntity: ILegalEntity = {
                                    id: newGuid,
                                    customerId: newGuid,
                                    customerType: isNullOrEmpty(eachItem[UploadLegalEntity.IsPrimary]) ? CustomerType.Affiliates : eachItem[UploadLegalEntity.IsPrimary] ? CustomerType.Primary : CustomerType.Affiliates,
                                    country: eachItem[UploadLegalEntity.Country]?.toString().trim(),
                                    crmAccountId: eachItem[UploadLegalEntity.CustomerAccountId]?.toString().trim(),
                                    legalEntityName: eachItem[UploadLegalEntity.LegalEntityName]?.toString().trim(),
                                    tpid: typeof eachItem[UploadLegalEntity.TPID] != 'string' ? eachItem[UploadLegalEntity.TPID]?.toString().trim() : 0,
                                    segment: eachItem[UploadLegalEntity.Segment]?.toString().trim(),
                                    vertical: eachItem[UploadLegalEntity.CustomerVertical]?.toString().trim(),
                                    unitsCommitted: isDealVolumeApplicable ? eachItem[UploadLegalEntity.DealVolume] != null && eachItem[UploadLegalEntity.DealVolume] != "" && typeof eachItem[UploadLegalEntity.DealVolume] == 'number' ? eachItem[UploadLegalEntity.DealVolume] : 0 : 0,
                                    isManaged: (isNullOrEmpty(eachItem[UploadLegalEntity.IsManaged]) ? false : eachItem[UploadLegalEntity.IsManaged].toString().trim().toLowerCase() === "yes" || eachItem[UploadLegalEntity.IsManaged] === true ? true : false),
                                    errors: "",
                                    action: ActionType.Add,
                                    isUploadedRecord: true
                                }
                                if(internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()){
                                    let duplicateRowIndex = newSelectedLegals.findIndex((obj => obj.country == eachEntity.country && obj.legalEntityName == eachEntity.legalEntityName));
                                    if (duplicateRowIndex == -1){
                                        newSelectedLegals = [...newSelectedLegals,eachEntity];
                                    }
                                    else
                                    {
                                        isDuplicateRowDetected = true;
                                    }
                                }
                                else{
                                    newSelectedLegals = [...newSelectedLegals,eachEntity];
                                }
                                return eachEntity;
                            });
                            dispatchSelectedData([...newSelectedLegals], newSelectedLegals.filter(x => x.customerType == CustomerType.Primary)[0]?.id ||"",true);
                            uploadFileObj = { file: {} };
                            validateDealCustomers();
                            let updatedConfig = readGridConfig(GridType.LegalEntitySelection, internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies, ApplicationConstants.LegalEntitySelectionUnitstransact);
                            updatedConfig.kendoGridProps.data = newSelectedLegals;
                            setSelectionGridconfig(updatedConfig);
                            if(isDuplicateRowDetected){
                                setIsDialogHidden(false);
                            }
                        }
                    });
                }
                else {
                    seterrorMessage(UIControlsText.UploadLegalEntityIncorrectTemplate);
                    sethideDialog(false);
                }
            });
        }
    }

    //Validate Legal Entities Upon uploading excel file.
    const validateDealCustomers = () => {
        nextGenService.validateDealCustomers(internalPricingContextState)
            .then((response: any) => {
                if (!isNullOrEmpty(response.data)) {
                    let dealCustomers = response.data.opportunityDeals[0].dealCustomers;
                    let customers = internalPricingContextState.opportunityDeals[0].dealCustomers;
                    dealCustomers = dealCustomers.map((item: any) => ({
                        ...item,
                        isUploadedRecord: customers?.find(x => x.id === item.id)?.isUploadedRecord == false ? false : true,
                    }));
                    dispatchSelectedData(dealCustomers, dealCustomers.filter((x: { customerType: CustomerType; }) => x.customerType == CustomerType.Primary)[0]?.id ||"", true);
                }
            }).catch((error) => {
                console.log("error occurred");
            });
    }

    // Function to validate template
    const validateExcelTemplate = (headerColumns: any): boolean => {
        let isValidTemplate = true;
        if (isNullOrEmpty(headerColumns)) {
            isValidTemplate = false;
            seterrorMessage(UIControlsText.UploadLegalEntityIncorrectTemplate);
            sethideDialog(false);
        }
        else { // validate if uploaded excel header keys are matching and valid 
            if (!(headerColumns.includes(UploadLegalEntity.Country.toString()) &&
                headerColumns.includes(UploadLegalEntity.CustomerAccountId.toString()) &&
                headerColumns.includes(UploadLegalEntity.LegalEntityName.toString()) &&
                headerColumns.includes(UploadLegalEntity.TPID.toString()) &&
                headerColumns.includes(UploadLegalEntity.CustomerVertical.toString()) &&
                headerColumns.includes(UploadLegalEntity.IsManaged.toString())
            )) {
                isValidTemplate = false;
                seterrorMessage(UIControlsText.UploadLegalEntityIncorrectTemplate);
                sethideDialog(false);
            }
            if (internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies && !headerColumns.includes(UploadLegalEntity.DealVolume.toString())) {
                isValidTemplate = false;
                seterrorMessage(UIControlsText.UploadLegalEntityIncorrectTemplate);
                sethideDialog(false);
            }
        }
        return isValidTemplate;
    }
    //#endregion #Upload File Handler and Process Excel File Handler

    //#region #Download Excel Template
    const downloadTemplate = () => {
        exportToExcel(excelHeaderColumns, "Upload_Legal_Entities", "Legal Entities");
    }
    
    // IsManaged custom cell rendering
    const onCustomCellRenderCallBack = (selectedRowData: any) => {
        if (IsOTLE) {
            let option = [{ key: selectedRowData.dataItem.id, text: "", ariaLabel: "pick one" }];
            return (
            <RadioGridCell
                cellId={selectedRowData.dataItem.id}
                options={option}
                selectedOption={selectedCustomer?.id}
                itemSelectionUpdate={onCustomerSelectionHandler}
            />
            );
        }

        const isManagedView = selectedRowData.dataItem.isManaged ? (<td className="k-command-cell">Yes</td>) : (<td className="k-command-cell">No</td>);

       return selectedRowData.editMode || internalPricingContextState.pageMode === PageMode.View ? isManagedView : (
                    <DropDownGridCell
                        isMultiSelect={false}                    
                        cellId={selectedRowData.dataItem.id}
                        data={dropDownYesOrNoData}
                        selectedValues={selectedRowData.dataItem.isManaged ? [1] : [0]}
                        itemSelectionUpdate={onHandleIsManagedCallBack}
                        isUploadedRecord={selectedRowData.dataItem.isUploadedRecord}                
                    />
        );
    };
    
    const onHandleIsManagedCallBack = (cellId: string, selectedDropDownVal: any): void => {
        let tempData = internalPricingContextState.opportunityDeals[0].dealCustomers || [];
        if (!isNullOrEmpty(cellId) && !isNullOrEmpty(tempData)) {
            let editedRowIndex = tempData.findIndex((obj => obj.id == cellId));
            if (editedRowIndex != -1 && tempData[editedRowIndex].isUploadedRecord) {
                tempData[editedRowIndex].isManaged = selectedDropDownVal[0] == 1 ? true : false;
                tempData[editedRowIndex].unManaged = !tempData[editedRowIndex].isManaged
                tempData[editedRowIndex].errors = validateExcelRecord(tempData[editedRowIndex]);
                let updatedConfig = readGridConfig(GridType.LegalEntitySelection, internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies, ApplicationConstants.LegalEntitySelectionUnitstransact);
                updatedConfig.kendoGridProps.data = [...tempData];
                // legalEntityUploadGridConfig.kendoGridProps.data = [...tempData];
                dispatchSelectedData([...tempData], tempData.filter(x => x.customerType == CustomerType.Primary)[0]?.id || "",  false);
            }
        }
    }// Function to Init single excel Item and perform validations on it and return errormessages.
    const validateExcelRecord = (item: ILegalEntity) => {
        let errorMessage: string = "";
        if (!isNullOrEmpty(item)) {
            errorMessage += validateRequiredField(item);
            errorMessage += validateSegmentField(item);
            errorMessage += validateCountryExists(item);
        }
        return errorMessage != "" ? errorMessage.replace(/,\s*$/, "") : "";
    }
    // Function to check the Required Fields for the Excel based in IsManaged
    const validateRequiredField = (item: ILegalEntity) => {
        let errorMessage: string = "";
        if (isNullOrEmpty(item.country))
            errorMessage = UIControlsText.Country + ", ";
        if (isNullOrEmpty(item.legalEntityName))
            errorMessage += UIControlsText.LegalEntityName + ", ";
        if (item.isManaged) {
            if (isNullOrEmpty(item.crmAccountId))
                errorMessage += UIControlsText.CRMAccountID + ", ";
            if (isNullOrEmpty(item.tpid))
                errorMessage += UIControlsText.TPID + ", ";
        }
        return errorMessage != "" ? "".concat(UIControlsText.UploadRequiredErrorMessage, errorMessage) : "";
    }

    const validateCountryExists = (item: ILegalEntity) => {
        if (isNullOrEmpty(item.country) || selectedCountries?.filter(x => x.text.toLowerCase() == item.country.toLowerCase()).length == 0)
            return UIControlsText.UploadCountryGEOErrorMessage;
        return "";
    }
    // Function to check the segmane contains SMB  return error message if does not exits
    const validateSegmentField = (item: ILegalEntity) => {
        if (!item.isManaged && (isNullOrEmpty(item.segment) || (!item.segment.toLowerCase().includes(PageConstants.CustomerSegmentContainsSMB))))
            return UIControlsText.UploadSegmentErrorMessage + ", ";;
        return "";
    }

    const onAddNewCustomerSelectionHandler = (customerEntity: ILegalEntity) => {
        customerEntity.reviewDecision = CustomerStatusCodes.Approved;
        customerEntity.action = ActionType.Add;
        customerEntity.customerType = CustomerType.Primary;
        onCustomerSelectionHandler('unManaged', customerEntity);
        setSelectedCustomer({ ...customerEntity });
        setSelectedDunsidValues([]);
        closePanel();
    }

    const onAddNewPanelCallBack = () => {
        setShowAddCustomer(true);
    }
    const closePanel = () => {
        setShowAddCustomer(false);
    }

    const setGuidanceText = () => {
        if (IsOTLE) {
            setCustomerSearchGuidanceText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CustomerSearchGuidanceOTLE", UIControlsText.CustomerSearchGuidanceOTLE))
        }
        else {
            setCustomerSearchGuidanceText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CustomerSearchGuidance", UIControlsText.CustomerSearchGuidance))
        }
    }
    
    /**
     * Customer Domain change Callback function.
     * @method
     * @param {React.FormEvent<HTMLInputElement>} event Element Click Event.
     * @param {string} item Value.
     */
    const onCustomerDomainTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        if (newValue) {
            internalPricingContextDispatch({ type: CaseActionTypes.dealCustomerValueChanged, dealInputControlValue: { key: ApplicationConstants.DealCustomerDomain, value: newValue, id: internalPricingContextState?.opportunityDeals[0]?.id } })
        }
    }

    const triggerEditCustomer = () => {
        setShowAddCustomer(true);
    }

    const customerDetails = {
        type: "CustomTileCardComponent",
        data: {
            id: "ViewCardCustomer",
            baseState: internalPricingContextState,
            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetails", UIControlsText.CustomerDetails),
            iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetailsIcon", UIControlsText.CustomerDetailsIcon),
            components: [
                {
                    type: 'Stack',
                    data: {
                        id: "customerCardStack",
                        horizontal: "true",
                        wrap: true,
                        components: [
                            {
                                type: 'StackItem',
                                data: {
                                    id: "customerCardStackItem",
                                    align: "auto",
                                    components: [
                                        {
                                            type: "GuidanceTextInformation",
                                            data: {
                                                id: "CustomerSearchGuidanceText",
                                                label: customerSearchGuidanceText,
                                            }
                                        }

                                    ]
                                }
                            },
                        ]
                    },
                },
                {
                    type: 'Stack',
                    data: {
                        id: "customerCardRow",
                        horizontal: "true",
                        disableshrink: true,
                        components: [
                            {
                                type: 'StackItem',
                                data: {
                                    id: "customerCardColumn",
                                    styles: stackItemStyles,
                                    align: "auto",
                                    visible: selectedCustomer != undefined ? true : false,
                                    components: [
                                        {
                                            type: "InnerHTMLContainer",
                                            data: {
                                                id: "SelectedCustomerInfo",
                                                    contolType: UIControlType.CustomerTable,
                                                    selectedCustomer: selectedCustomer,
                                                    showEdit: hideUnManagedCustomerEdit,
                                                    editEvent: triggerEditCustomer
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: "StackItem",
                                data: {
                                    id: "StackContainerDomain",
                                    //Only for OTLE
                                    visible: internalPricingDealTypeValue == PageConstants.OTLEDealtype,
                                    styles: stackItemCustomerStyles,
                                    align: "auto",
                                    components: [
                                        {
                                            type: "TextField",
                                            visible: !isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, internalPricingDealTypeValue),
                                            data: {
                                                id: "CustomerDomainTextField",
                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                styles: PageStyles.textFieldStylesOneColumn,
                                                placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "CustomerDomainPlaceholderTextExample", UIControlsText.CustomerDomainPlaceholderTextExample),
                                                required: true,
                                                validateOnLoad: false,
                                                validateOnFocusOut: true,
                                                maxLength: 100,
                                                disabled: internalPricingContextState?.opportunityDeals && (internalPricingContextState?.opportunityDeals[0] !== undefined) && (internalPricingContextState?.opportunityDeals[0]?.dealCustomers?.length == 0),
                                                onGetErrorMessage: getTextFieldErrorMessage,
                                                value: selectedCustomer?.dealCustomerDomain || (internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.dealCustomers && (internalPricingContextState?.opportunityDeals[0]?.dealCustomers[0]?.dealCustomerDomain)),
                                                onChange: onCustomerDomainTextChange
                                            }
                                        },
                                        {
                                            type: "InnerHTMLContainer",
                                            visible: isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0]?.statusCode, undefined, undefined, internalPricingDealTypeValue),
                                            data: {
                                                id: "CustomerDomainTextField",
                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDomainText", UIControlsText.CustomerDomainText),
                                                contolType: UIControlType.LabelValueView,
                                                value: selectedCustomer?.dealCustomerDomain || (internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.dealCustomers && (internalPricingContextState?.opportunityDeals[0]?.dealCustomers[0]?.dealCustomerDomain)),
                                            }
                                        },
                                        {
                                            type: 'DialogComponent',
                                            data: {
                                                id: 'invalidCustomerDialog',
                                                hidden:hideCustomerDialog,
                                                isBlocking: true,
                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InactiveCustomer", UIControlsText.InactiveCustomer),
                                                subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectedCustomer", UIControlsText.SelectedCustomer) + customerName,
                                                DialogContent:customerMessage,
                                                onDismiss: () => {
                                                    setHideCustomerDialog(true);
                                                },
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                },
            ]
        }
    };

    const createUnmanagedCust = {
        type: "Row",
            visible: (internalPricingContextState.pageMode === PageMode.Create || internalPricingContextState.pageMode === PageMode.Edit),
            data: {
                id: "yeah",
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: "InnerHTMLContainer",
                        data: {
                            id: "addCustomer",
                            contolType: UIControlType.Panel,
                            isOpen: showAddCustomer,
                            dismissPanel: closePanel,
                            headerText: hideUnManagedCustomerEdit ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNewCustomerText", UIControlsText.AddNewCustomerText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditCustomerText", UIControlsText.EditCustomerText),
                            content: <CreateUnmanagedCustomer {...{ onSubmitToPricingProgramCallBack: onAddNewCustomerSelectionHandler, onCloseCallBack: closePanel, savedCustomer: selectedCustomer }} />,
                            type: PanelType.custom,
                            customWidth: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PanelCustomerWidth55Percent", UIControlsText.PanelCustomerWidth55Percent)
                        }
                    }]
            }
    }

    const customerGuidanceText = {
        type: 'Row',
        data: {
            id: "CustomerSearchGuidanceText",
            horizontal: "true",
            disableshrink: 'true',
            components: [
                {
                    type: 'Col',
                    data: {
                        id: "CustomerSearchGuidanceColumn",
                        style: DefaultPageStyles.padding8,
                        styles: stackItemStyles,
                        align: "auto",
                        components: [
                            {
                                type: "GuidanceTextInformation",
                                visible: internalPricingContextState.pageMode === PageMode.Create || internalPricingContextState.pageMode === PageMode.Edit,
                                data: {
                                    id: "CustomerSearchResultsGuidance",
                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CustomerSearchResultsGuidance", UIControlsText.CustomerSearchResultsGuidance),
                                    styles: PageStyles.textFieldStylesTwoColumn,
                                }
                            }
                        ]
                    }
                },
            ]
        }
    };

     //#endregion #Upload
    if (IsCPS) {
        return (
            <div id='legalEntityContent' className={_Styles.LegalEntityForm}
                data-nextgen-parent-group={UIControlsText.LegalEntitiesText} data-nextgen-parentid={UIControlsText.LegalEntitiesText}>
                {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode, (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                    <div>
                        <CoherencePivot
                            className="legal-entity-subtab"
                            onTabSelected={onPivotChange}
                            useGrayPivot={false}
                            selectedKey={legalOption}
                            tabs={[
                                {
                                    name: UIControlsText.SearchTabText,
                                    ariaLabel: UIControlsText.SearchTabText,
                                    key: UIControlsText.DefaultLegalOption,
                                    headerButtonProps: {
                                        ...{
                                            "data-nextgen-id": UIControlsText.SearchTabText
                                        }
                                    }
                                },
                                {
                                    name: UIControlsText.UploadTabText,
                                    ariaLabel: UIControlsText.UploadTabText,
                                    key: UIControlsText.UploadLegalOption,
                                    headerButtonProps: {
                                        ...{
                                            "data-nextgen-id": UIControlsText.UploadTabText
                                        }
                                    }
                                }
                            ]}
                        />
                    </div>
                }
    
                <React.Fragment>
                    <Row>
                        {legalOption === UIControlsText.DefaultLegalOption &&
                            <Col>
                            <div className="in-left">
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                                        <Stack>
                                            <Dropdown
                                                label={UIControlsText.DealCountryText}
                                                ariaLabel={UIControlsText.DealCountryText}
                                                placeholder={UIControlsText.DealCountryPlaceHolderText}
                                                styles={PageStyles.textFieldStylesTwoColumn}
                                                options={countriesDropDown}
                                                multiSelect={true}
                                                defaultSelectedKeys={countriesDropDown.map(item => item?.key.toString())}
                                                onChange={onCountrySelectionChange} />
                                        </Stack>
                                    }
                                    {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                                        <Stack id="legalEntitySearchBar" className={_Styles.legalTextStyles}>
                                            <Col>
                                                <SearchBar {
                                                    ...{
                                                        store: store, onSubmit, placeHolderText: UIControlsText.Search, onClear, onShowFacets,
                                                        searchID: UIControlsText.CustomerSearchID,
                                                        showFacetsText: searchFilterButtonText,
                                                        hideFacetsButton: hideSearchFilterButton
                                                    }
                                                } />
                                                <Label>{UIControlsText.CustomerSearchPlaceHolderText}</Label>
                                            </Col>
                                        </Stack>
                                    }
                                </Stack>
                                <Splitter className={_Styles.SplitterStyle} panes={internalPricingContextState.pageMode !== PageMode.View && showSearchFacet ? panes : []} onChange={onSplitterChange}>
                                    {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) && showSearchFacet &&
                                        <div>
                                            <Label id="gridTitle" className={_Styles.LegalEntityLabel} >{UIControlsText.SearchFilter}</Label>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.CRMChildAccountFacetName,
                                                        facetLabel: UIControlsText.CRMChildAccountFacetText,
                                                        facetData: crmChildAccountName,
                                                        selectedData: selectedCrmChildAccountNameValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.AddressFacetName,
                                                        facetLabel: UIControlsText.AddressFacetText,
                                                        facetData: address,
                                                        selectedData: selectedAddressValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.CityFacetName,
                                                        facetLabel: UIControlsText.CityFacetText,
                                                        facetData: city,
                                                        selectedData: selectedCityValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.PostalCodeFacetName,
                                                        facetLabel: UIControlsText.PostalCodeFacetText,
                                                        facetData: postalCode,
                                                        selectedData: selectedPostalCodeValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.StateFacetName,
                                                        facetLabel: UIControlsText.StateProvinceFacetText,
                                                        facetData: stateProvince,
                                                        selectedData: selectedStateProvinceValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.ChildCRMIDFacetName,
                                                        facetLabel: UIControlsText.CRMIDFacetText,
                                                        facetData: childCrmId,
                                                        selectedData: selectedCrmidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.TPIDFacetName,
                                                        facetLabel: UIControlsText.TPIDFacetText,
                                                        facetData: tpid,
                                                        selectedData: selectedTpidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.GPIDFacetName,
                                                        facetLabel: UIControlsText.GPIDFacetText,
                                                        facetData: gpid,
                                                        selectedData: selectedGpidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.DunsNumberFacetName,
                                                        facetLabel: UIControlsText.DunsNumberFacetText,
                                                        facetData: dunsid,
                                                        selectedData: selectedDunsidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                        </div>
                                    }
                                    <div id="searchAndSelectGrid">
                                        {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode, (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                                            <div>
                                                    <Stack horizontal horizontalAlign="space-between">
                                                        <Label id="gridTitle" className={_Styles.LegalEntityLabel} >{UIControlsText.LegalEntitiesSearchResult}</Label>
                                                    </Stack>   
                                                    <div>
                                                        <NextGenKendoGrid {...{ kendoGridProps: { ...searchGridconfig.kendoGridProps }, onRowSelectionCallBack, onPageChange }} />
                                                    </div>
                                            </div>
                                        }
    
                                    </div>
                                </Splitter>
                            </div>
                            </Col>
                        }
                        {legalOption === "upload" &&
                            <div className="mt-5 mb-5 text-center in-left">
                                <input type="file"
                                    className="border  w-50"
                                    id="file"
                                    onChange={uploadFileHandler}
                                />
                                <Link onClick={downloadTemplate} derline className="ps-2">
                                    Download Template
                                </Link>
                            </div>
                        }
                    </Row>
                   
                    <Row>
                        <Col>
                            {isFieldVisible(UIControlsText.AdditionalLegalEntities, internalPricingContextState.pageMode) &&
                                <div>
                                    {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                                        <Stack horizontal tokens={stackTokens} >
                                            <div id="primarylegalparentdiv">
                                                <hr />
                                        <span className={_Styles.PrimarySelection}>
                                                    <span id="gridTitle"> {UIControlsText.SelectedLegalEntities} </span> {selectedIsPrimary.PrimaryCountry}
                                                    {selectedIsPrimary.PrimaryName}</span>
                                                <hr />
                                            </div>
                                        </Stack>
                                    }
                                    {internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() && (
                                        <Row>
                                            <Label id="gridTitleLegalEntity" className={_Styles.LegalEntityLabel}>{UIControlsText.AdditionalLegalEntities}</Label>
                                        </Row>
                                    )}
                                    <div>
                                        <NextGenKendoGrid {...{ kendoGridProps: { ...selectionGridconfig.kendoGridProps }, onPrimarySelectionCallBack, onEditCallBack, onDeleteCallBack, onCustomCellRenderCallBack }} />
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </React.Fragment>
                <CoherenceDialog
                    hidden={isDialogHidden}
                    onDismiss={() => {
                        setIsDialogHidden(true);
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: UIControlsText.ConfirmTitle,
                        subText: UIControlsText.DuplicateLEMessage
                    }}
                >
                    <CoherenceDialogFooter>
                        <PrimaryButton
                            text={UIControlsText.OkText}
                            ariaLabel={UIControlsText.OkText}
                            onClick={() => {
                                setIsDialogHidden(true);
                            }}
                        />
                    </CoherenceDialogFooter>
                </CoherenceDialog>
            </div>
        )
    }
    else {
        return (

            <div id='legalEntityContent' className={_Styles.LegalEntityForm}
                data-nextgen-parent-group={UIControlsText.LegalEntitiesText} data-nextgen-parentid={UIControlsText.LegalEntitiesText}>
                <React.Fragment>
                {FormRenderingService(customerDetails, internalPricingContextState)}
                    <Row>
                        {legalOption === UIControlsText.DefaultLegalOption &&
                            <><Col>
                            <div className="in-left">
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                                        <Stack>
                                            <Dropdown
                                                label={UIControlsText.DealCountryText}
                                                ariaLabel={UIControlsText.DealCountryText}
                                                placeholder={UIControlsText.DealCountryPlaceHolderText}
                                                styles={PageStyles.textFieldStylesTwoColumn}
                                                options={countriesDropDown}
                                                multiSelect={true}
                                                disabled={true}
                                                defaultSelectedKeys={countriesDropDown.map(item => item?.key.toString())}
                                                onChange={onCountrySelectionChange} />
                                        </Stack>
                                    }
                                    {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) &&
                                        <Stack id="legalEntitySearchBar" className={_Styles.legalTextStyles}>
                                            <Col>
                                                <SearchBar {
                                                    ...{
                                                        store: store, onSubmit, placeHolderText: UIControlsText.Search, onClear, onShowFacets,
                                                        searchID: UIControlsText.CustomerSearchID,
                                                        showFacetsText: searchFilterButtonText,
                                                        hideFacetsButton: hideSearchFilterButton
                                                    }
                                                } />
                                                <Label>{UIControlsText.CustomerSearchPlaceHolderText}</Label>
                                            </Col>
                                        </Stack>
                                    }
                                </Stack>
                                {FormRenderingService(customerGuidanceText, internalPricingContextState)}
                                <Splitter className={_Styles.SplitterStyle} panes={internalPricingContextState.pageMode !== PageMode.View && showSearchFacet ? panes : []} onChange={onSplitterChange}>
                                {isFieldVisible(UIControlsText.LegalEntitiesSearchElements, internalPricingContextState.pageMode,  (internalPricingContextState.opportunityDeals[0]?.dealType == ApplicationConstants.CPstrategicPageName ? ApplicationConstants.CPstrategicPageName : ApplicationConstants.otlePageName)) && showSearchFacet &&
                                        <div>
                                            <Label id="gridTitle" className={_Styles.LegalEntityLabel} >{UIControlsText.SearchFilter}</Label>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.CRMChildAccountFacetName,
                                                        facetLabel: UIControlsText.CRMChildAccountFacetText,
                                                        facetData: crmChildAccountName,
                                                        selectedData: selectedCrmChildAccountNameValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.AddressFacetName,
                                                        facetLabel: UIControlsText.AddressFacetText,
                                                        facetData: address,
                                                        selectedData: selectedAddressValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.CityFacetName,
                                                        facetLabel: UIControlsText.CityFacetText,
                                                        facetData: city,
                                                        selectedData: selectedCityValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.PostalCodeFacetName,
                                                        facetLabel: UIControlsText.PostalCodeFacetText,
                                                        facetData: postalCode,
                                                        selectedData: selectedPostalCodeValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.StateFacetName,
                                                        facetLabel: UIControlsText.StateProvinceFacetText,
                                                        facetData: stateProvince,
                                                        selectedData: selectedStateProvinceValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.ChildCRMIDFacetName,
                                                        facetLabel: UIControlsText.CRMIDFacetText,
                                                        facetData: childCrmId,
                                                        selectedData: selectedCrmidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.TPIDFacetName,
                                                        facetLabel: UIControlsText.TPIDFacetText,
                                                        facetData: tpid,
                                                        selectedData: selectedTpidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                            <SearchableComboBoxComponent
                                                {
                                                ...{
                                                    facetProps: {
                                                        facetName: ApplicationConstants.DunsNumberFacetName,
                                                        facetLabel: UIControlsText.DunsNumberFacetText,
                                                        facetData: dunsid,
                                                        selectedData: selectedDunsidValues,
                                                        showFacetCount: true
                                                    },
                                                    selectedValues
                                                }
                                                }>
                                            </SearchableComboBoxComponent>
                                        </div>
                                    }
                                    <div id="searchAndSelectGrid">
                                        <div>
                                            <NextGenKendoGrid {...{ kendoGridProps: { ...otleSearchGridconfig.kendoGridProps }, onCustomCellRenderCallBack, onPageChange, onAddNewPanelCallBack, onPrimarySelectionCallBack, savedCustomer: selectedCustomer }} />
                                        </div>
                                    </div>
                                </Splitter>
                            </div>
                            {FormRenderingService(createUnmanagedCust, internalPricingContextState)}
                            </Col></>
                        }
                    </Row>
                    <Row>

                    </Row>
                </React.Fragment>
                <CoherenceDialog
                    hidden={isDialogHidden}
                    onDismiss={() => {
                        setIsDialogHidden(true);
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: UIControlsText.ConfirmTitle,
                        subText: UIControlsText.DuplicateLEMessage
                    }}
                >
                    <CoherenceDialogFooter>
                        <PrimaryButton
                            text={UIControlsText.OkText}
                            ariaLabel={UIControlsText.OkText}
                            onClick={() => {
                                setIsDialogHidden(true);
                            }}
                        />
                    </CoherenceDialogFooter>
                </CoherenceDialog>
            </div>
        )
    }
    
}
export default InternalPricingLegalEntitiesSearchAndUpload;