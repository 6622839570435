/**
 * Enum for OpportunityDealStatusCodeNames.
 * @readonly
 * @enum
 */
export enum OpportunityDealStatusNames {
    /**
     * Draft Name.
     */
    Draft = "Draft",

    /**
     * Cancelled Name.
     */
    Cancelled = "Cancelled",

    /**
     * Forced Expire Name.
     */
    ForcedExpire = "Early Expired",

    /**
    * Approved Name.
    */
    Approved = "Approved",

    /**
    * DealPricingApprovedPendingChannel Name.
    */
    DealPricingApprovedPendingChannel = "Deal Pricing Approved - Pending Channel",

    /**
    * Denied Name.
    */
    Denied = "Denied",

    /**
    * Decision Pending Name.
    */
    DecisionPending  = "Decision Pending",

    /**
    * Expired Name.
    */
    Expired = "Expired",

    /**
    * Need More Info.
    */    
    NeedMoreInfo = "Need More Info",   

    /**
    * InComplete.
    */    
    Incomplete = "Incomplete",  
    
    /**
    * DraftRenew.
    */    
     DraftRenew = "Draft Renew",

     /**
    * InsufficientInformation.
    */
     InsufficientInformation = "Insufficient Information",
}
