import React, { FormEvent } from "react";
import { IRadioGridCellProps } from "./IRadioGridCellProps";
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";

export const RadioGridCell = (props: IRadioGridCellProps) => {
    const [selectedKey, setSelectedKey] = React.useState<string | undefined>(props.selectedOption);

    const onchange = (event: FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) => {
        setSelectedKey(option?.key);

        props.itemSelectionUpdate && props.itemSelectionUpdate(props.cellId, option?.key);
    }

    return (
        <React.Fragment>
            <td>
                <ChoiceGroup disabled={props.disabled} selectedKey={selectedKey} options={props.options} onChange={onchange} />
            </td>
        </React.Fragment>
    )
}