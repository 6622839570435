/**
 * Enum for FeatureFlags.
 * @readonly
 * @enum 
 */
 export enum FeatureFlags {

    /**
     * UserVoice Feature.
     */
    UserVoice = "UserVoice",

    /**
     * Chatbot Feature.
     */
    Chatbot = "Chatbot"
}