import React, { useEffect, useReducer, useState } from 'react';
import { useContext } from 'react';
import { UIControlsText } from '../../../../content/UIControlsText';
import { Guid } from '../../../../infrastructure/Guid';
import { PageMode } from '../../../../models/PageMode';
import { getLoggedinUserRoleDetails, getURLParamValue, getUserSession, intializeSessionConstants, isMsStoreUser, isNullOrEmpty, getRuleValidationMessage, getEntityDataFromSession } from '../../../../components/shared/common/util';
import { ICommonPPContext } from './ICommonPPContext';
import commonPPReducer from '../reducer/CommonPPReducer';
import { IPageState } from '../state/IPageState';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { nextGenService } from '../../../../services/NextGenService';
import { IUserRoleDetail } from '../../../../models/IUserRoleDetail';
import { CaseActionTypes } from '../reducer/CaseActionTypes';
import { ErrorCode } from '../../../../models/ErrorCode';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { ChannelType } from '../../../../models/ChannelType';
import { StatePropertiesText } from '../state/StatePropertiesText';
import { Spinner, Stack } from '@fluentui/react';
import { PageStyles } from '../content/Page.Styles';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { PartnerType } from '../../../../models/PartnerType';
import { IOpportunityAccount } from '../../../../models/IOpportunityAccount';

const CommonPPContext = React.createContext<ICommonPPContext>({} as ICommonPPContext);
const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

const oppNumber = getURLParamValue(ApplicationConstants.OpportunityNumberParamName);
let oppDealNumber = getURLParamValue(ApplicationConstants.OpportunityDealNumberParamName);
const pageMode = oppNumber && oppNumber.length > 0 ?
    isNullOrEmpty(sessionStorage.getItem(SessionStorageConsts.pageMode)) ? PageMode.View : sessionStorage.getItem(SessionStorageConsts.pageMode) : PageMode.Create;
const pageSubMode = isNullOrEmpty(sessionStorage.getItem(SessionStorageConsts.pageSubMode)) ? undefined : sessionStorage.getItem(SessionStorageConsts.pageSubMode);

const caseInitialState: IPageState = {
    id: Guid.newGuid(),
    dataLoading: false,
    selectedPivot: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerText", UIControlsText.CustomerText),
    submitterDetails: JSON.parse(getUserSession() || "{}"),
    pageMode: pageMode || PageMode.Create,
    pageSubMode: pageSubMode || undefined,
    opportunityDeals: [{}],
    opportunityAccounts: [],
    isMultiNational: false,
    applicableDealTypes: [],
    opportunityChannelType: isMsStoreUser() ? ChannelType.Direct : ChannelType.Indirect
};

/**
 * Common PP Provider.
 * @function
 */
export function CommonPPProvider(props: any) {
    const [commonPPContextState, commonPPContextDispatch] = useReducer(commonPPReducer, caseInitialState);
    const contextValue = { commonPPContextState, commonPPContextDispatch };
    const [isSessionDataLoadComplete, setIsSessionDataLoadComplete] = useState<boolean>(false);

    useEffect(() => {
        const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
        async function getOpportunity() {

            if (oppNumber && oppNumber.length > 0) {
               
                if (!isNullOrEmpty(userRoleDetails)) {
                    var opportunity = await Promise.resolve(nextGenService.getOpportunityCommon(oppNumber, userRoleDetails, oppDealNumber));

                    if (opportunity.status === 200) {
                        if (pageMode !== PageMode.Create && JavaScriptExtensions.isNullOrUndfinedOrEmpty(oppDealNumber)) {
                            oppDealNumber = opportunity.data?.opportunityDeals[0]?.name || "";
                        }
                        sessionStorage.setItem(SessionStorageConsts.opportunityProductType, JSON.stringify(opportunity.data.productGroup));
                        opportunity.data.pageMode = pageMode || opportunity.data.pageMode;
                        opportunity.data.saveDraft = false;
                        const oppAccounts: IOpportunityAccount[] = opportunity?.data?.opportunityAccounts || [];
                        const isAdditionalOrFinalResellersExist: boolean = oppAccounts.filter(acc => acc.partnerType === PartnerType.AdditionalTierReseller || acc.partnerType === PartnerType.FinalTierReseller)?.length > 0;
                        if(isAdditionalOrFinalResellersExist) {
                            opportunity.data.isFinalOrAdditionalReseller = isAdditionalOrFinalResellersExist;
                        }
                        opportunity.data.pageSubMode = pageSubMode || opportunity.data.pageSubMode;
                        if (oppDealNumber && oppDealNumber.length > 0) {
                            opportunity.data?.opportunityDeals.forEach(t => {
                                if (t?.isRenewed && t?.statusCode === OpportunityDealStatusCodes.Draft) {
                                    t.statusCode = OpportunityDealStatusCodes.DraftRenew;
                                }
                            })
                            opportunity?.data?.opportunityDeals.forEach(t => t.savedDealVolume = t.dealVolume);
                            opportunity.data.selectedOptyDealCaseNumber = oppDealNumber;
                        }
                        
                        commonPPContextDispatch({ type: CaseActionTypes.draftCaseLoaded, case: { ...opportunity.data } });
                    }
                }
            }
        }
        if (!isNullOrEmpty(userRoleDetails)) {
            try {
                intializeSessionConstants().then(res1 => setIsSessionDataLoadComplete(true));
                if (oppNumber && oppNumber.length > 0) {
                    getOpportunity();
                }
            }
            catch (error: any) {
                if (error && error.response && error.response.status === 404) {
                    commonPPContextDispatch({ type: CaseActionTypes.errorOccured, inputNumber: ErrorCode.InvalidCase });
                }
            }
            finally {

            }
        }
        else {
            setIsSessionDataLoadComplete(true);
        }

    }, [])
    return (
        <>
            {!isSessionDataLoadComplete &&
                <Stack>
                    <Spinner label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LoadingMCL", UIControlsText.LoadingMCL)} 
                        styles={PageStyles.spinnerLoadingIcon}
                        />
                </Stack>
            }
            <CommonPPContext.Provider value={contextValue}>
                {isSessionDataLoadComplete && props.children}
            </CommonPPContext.Provider>
        </>
    );
}

/**
 * Common Pricing Programs Context custom hook.
 * @function
 * @returns {ICommonPPContext}
 */
export function useCommonPPContext(): ICommonPPContext {
    return useContext(CommonPPContext)
}