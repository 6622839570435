import { IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { AffiliationType } from "../models/AffiliationType";
import { ChannelStructure } from "../models/ChannelStructure";
import { DealGeoCountryType } from "../models/DealGeoCountryType";
import { AccountAllType } from "../models/AccountAllType";
import { PartnerType } from "../models/PartnerType";
import { DashboardViewTypes } from "../models/DashboardViewTypes";
import { IBreadcrumbItem } from "@fluentui/react";
import { GridType } from "../models/GridType";
import { CaseDecisionLetterType } from "../models/CaseDecisionLetterType";


export const CaseDecisionLetterTypeOptions: IDropdownOption[] =
    [
        { key: CaseDecisionLetterType.WithoutSKU, text: 'Without SKU details' },
        { key: CaseDecisionLetterType.WithSKU, text: 'With SKU details' },
    ];

export const SingleDealCoverageOption: IDropdownOption[] = 
[
    { key: DealGeoCountryType.SingleCountryDeal, text: 'Single Country Deal' },
];
    
export const DealGeoCoverageOptions: IDropdownOption[] =
    [
        { key: DealGeoCountryType.SingleCountryDeal, text: 'Single Country Deal' },
        { key: DealGeoCountryType.MultiNationalDeal, text: 'Multi-National Deal' },
    ];

export const AffiliationTypeOptions: IDropdownOption[] =
    [
        { key: AffiliationType.AffiliateCompanies, text: 'Affiliate Companies' },
        { key: AffiliationType.ConsortiumBuying, text: 'Consortium Buying' },
        { key: AffiliationType.FrameworkAgreement, text: 'Framework Agreement' },
        { key: AffiliationType.Others, text: 'Others' },
    ];

export const ChannelStructureOptions: IDropdownOption[] =
    [
        { key: ChannelStructure.Customer, text: 'Cascade CAS-ID to Reseller' },
        { key: ChannelStructure.Distributor, text: 'Cascade CAS-ID to ADD' },
        { key: ChannelStructure.Skip, text: 'Skip Channel' },
    ];

export const AccountTypeOptions: IDropdownOption[] =
    [
        { key: PartnerType.ADD, text: 'ADD' },
        { key: PartnerType.ADR, text: 'ADR' },
        { key: PartnerType.DMP, text: 'DMP' },
        { key: PartnerType.MsStore, text: 'MS Store' },
    ];

export const AccountAllOptions: IDropdownOption[] =
    [
        { key: AccountAllType.None, text: 'Selected Accounts' },
        { key: AccountAllType.All, text: 'All Accounts' },
        { key: AccountAllType.AllEuefta, text: 'All EUEFTA Accounts' },

    ];

export const partnerTypeValues = {
    [`${PartnerType.ADD}`]: 'ADD',
    [`${PartnerType.ADR}`]: 'ADR',
    [`${PartnerType.DMP}`]: 'DMP',
    [`${PartnerType.MsStore}`]: 'MS Store',
    [`${PartnerType.Reseller}`]: 'Reseller',
};

export const dropDownYesOrNoData: IDropdownOption[] = [
    { key: 1, text: 'Yes' },
    { key: 0, text: 'No' }
];

export const DashboardViewTypesValues: IDropdownOption[] = [
    {
        key: DashboardViewTypes.AllCases,
        text: "All Cases",
        title: "/dashboard/allcases",
    },
    {
        key: DashboardViewTypes.DealRegistrationCases,
        text: "Deal Registration Cases",
        title: "/dashboard/dealregistration"
    },
    {
        key: DashboardViewTypes.SpecialPricingCases,
        text: "Special Pricing Cases",
        title: "/dashboard/specialpricing"
    },
    {
        key: DashboardViewTypes.CustomPricingStrategicCases,
        text: "Custom Pricing Strategic Cases",
        title: "/dashboard/cpstrategic"
    },
    {
        key: DashboardViewTypes.ProductInformationForSpecialPricing,
        text: "Product Information for Special Pricing",
        title: "/dashboard/productviewsp"
    },
    {
        key: DashboardViewTypes.SpecialPricingLocalEmpowerment,
        text: "Special Pricing (Local Empowerment)",
        title: "/dashboard/splocalempowerment"
    },
    {
        key: DashboardViewTypes.OneTimeLocalImpowerement,
        text: "One Time Local Empowerment Cases",
        title: "/dashboard/otle"
    }
]

export const DashboardViewTypesValuesForMSSeller: IDropdownOption[] = [
    {
        key: DashboardViewTypes.AllCases,
        text: "All Cases",
        title: "/dashboard/allcases",
    },
    {
        key: DashboardViewTypes.CustomPricingStrategicCases,
        text: "Custom Pricing Strategic Cases",
        title: "/dashboard/cpstrategic"
    },
    {
        key: DashboardViewTypes.OneTimeLocalImpowerement,
        text: "One Time Local Empowerment Cases",
        title: "/dashboard/otle"
    }
]

export const DashboardGidNames = {
    [`${DashboardViewTypes.SpecialPricingCases}`]: 'Special Pricing',
    [`${DashboardViewTypes.AllCases}`]: 'AllCases',
    [`${DashboardViewTypes.DealRegistrationCases}`]: 'Deal Registration',
    [`${DashboardViewTypes.CustomPricingStrategicCases}`]: 'CP Strategic Case View',
    [`${DashboardViewTypes.ProductInformationForSpecialPricing}`]: 'Product Information for Special Pricing',
    [`${DashboardViewTypes.SpecialPricingLocalEmpowerment}`]: 'Special Pricing (Local Empowerment)',
    [`${DashboardViewTypes.OneTimeLocalImpowerement}`]: 'One Time Local Empowerment',
};

export const DiscountViewTypesValues: IDropdownOption[] = [
    {
        key: DashboardViewTypes.BidGridAndNonProfitEDUDiscounts,
        text: "Bid Grid & Non-Profit/EDU Discounts",
        isSelected: true
    }
]

export const BidGridBreadcrumbItems: IBreadcrumbItem[] = [
    {
        text: 'Discount', key: 'discount'
    },
    {
        text: 'Bid Grid & Non-Profit/EDU Discounts', key: ''
    },
]

export const DRAndSPStatusCodeFilterValues = [
    "Approved",
    "Expired",
    "Draft",
    "Decision Pending",
    "Insufficient Information",
    "Denied",
]

export const StatusCodeFilterValues = [
    {
        gridName: GridType.DealRegistration,
        statusValues: DRAndSPStatusCodeFilterValues
    },
    {
        gridName: GridType.SpecialPricing,
        statusValues: DRAndSPStatusCodeFilterValues
    },
    {
        gridName: GridType.AllCaseView,
        statusValues: DRAndSPStatusCodeFilterValues
    },
    {
        gridName: GridType.OneTimeLocalEmpowerment,
        statusValues: DRAndSPStatusCodeFilterValues
    },
]