/**
 * Actions for Cases.
 * @constant
 */
export const CaseActionTypes = {
  dataLoadingStart: "dataLoadingStart",
  dataLoadingEnd: "dataLoadingEnd",
  dealNameInputChanged: "dealNameInputChanged",
  dealOverviewInputChanged: "dealOverviewInputChanged",
  productGroupChanged: "productGroupChanged",
  dealGeoCoverageChanged: "dealGeoCoverageChanged",
  dealCountryChanged: "dealCountryChanged",
  dealAffiliationTypeChanged: "dealAffiliationTypeChanged",
  dealAffiliationTypeOtherChanged: "dealAffiliationTypeOtherChanged",
  casePivotChanged: "casePivotChanged",
  channelOverviewInputChanged: "channelOverviewInputChanged",
  channelDistributorItemsChanged: "channelDistributorItemsChanged",
  channelJustificationRequiredChanged: "channelJustificationRequiredChanged",
  channelJustificationInputChanged: "channelJustificationInputChanged",
  eueftaCountrySelected: "eueftaCountrySelected",
  dealVerticalChanged: "dealVerticalChanged",
  dealDetailsExpirationDateJustificationChanged: "dealDetailsExpirationDateJustificationChanged",
  dealDetailsRequestedExpirationDateChanged: "dealDetailsRequestedExpirationDateChanged",
  dealDetailsExceptionRequestChanged: "dealDetailsExceptionRequestChanged",
  dealDetailsContactChanged: "dealDetailsContactChanged",
  attachmentChanged: "dealAttachmentChanged",
  channelStructureChanged: "channelStructureChanged",
  channelAccountChanged: "channelAccountChanged",
  draftCaseLoaded: "draftCaseLoaded",
  skipChannelReasonChanged: "skipChannelReasonChanged",
  dealTypeUpdated: "dealTypeUpdated",
  saveDraft: "saveDraft",
  submitCase: "submitCase",
  submitCaseAsync: "submitCaseAsync",
  channelAttachmentChanged: "channelAttachmentChanged",
  selectedLegalEntityChanged: "selectedLegalEntityChanged",
  selectedProductFamilyChanged: "selectedProductFamilyChanged",
  missingFieldTab: "missingFieldTab",
  validationErrors: "validationErrors",
  errorOccured: "errorOccured",
  pageModeChanged: "pageModeChanged",
  totalunitsTransactedChanged: "totalunitsTransactedChanged",
  debugModeOn: "debugModeOn",
  ownerInfoChanged: "ownerInfoChanged",
  channelContactChanged: "channelContactChanged",
  channelAccountContactChanged: "channelAccountContactChanged",
  closureDecisionChanged: "closureDecisionChanged",
  dealStatusCodeChanged: "dealStatusCodeChanged",
  dealDetailsEmailCCNotificationRecipientsChanged: "dealDetailsEmailCCNotificationRecipientsChanged",
  dealFieldValueChanged: "dealFieldValueChanged",
  dealCustomerValueChanged: "dealCustomerValueChanged",
  dealCustomerDomainChanged: "dealCustomerDomainChanged",
  rfpRfqPublicTenderValueChanged: "rfpRfqPublicTenderValueChanged",
  rfpRfqPublicTenderJustificationValueChanged: "rfpRfqPublicTenderJustificationValueChanged",
  dealAddressValueChanged: "dealAddressValueChanged",
  pricingProgramSelectionChanged: "pricingProgramSelectionChanged",
  SelectedDealChanged: "SelectedDealChanged",
  partnerDetailsChanged: "partnerDetailsChanged",
  pivotTabData: "pivotTabData",
  isFinalOrAdditionalResellerSelected: "isFinalOrAdditionalResellerSelected",
  pricingProgramDealType: "pricingProgramDealType"
}