import React from 'react';
import { CoherencePivot, CoherencePivotTabProps, PivotContent, PivotContentItem } from '@cseo/controls';
import InternalPricingDealOverview from './internalpricing-deal-overview';
import { PageProps } from '../../../../content/styles/Page.types';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { AffiliationType } from '../../../../models/AffiliationType';
import { PageStyles } from '../../common/content/Page.Styles';
import CPStrategicChannel from './cp-strategic-channel';
import { UIControlsText } from '../../../../content/UIControlsText';
import { useInternalPricingContext } from '../context/internalpricing-context';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import InternalPricingCommands from './internalpricing-commands';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { useEffect, useState } from 'react';
import { IProductType } from '../../../../models/IProductType';
import { ICountry } from '../../../../models/ICountry';
import { IVertical } from '../../../../models/IVertical';
import { Breadcrumb, MessageBar, MessageBarType, PrimaryButton, Separator, Stack, nullRender } from '@fluentui/react';
import { ApplicationConstants } from '../../../../models/ApplicationConstants';
import { IFacetData } from '../../../../models/IFacetData';
import { getURLParamValue, getStatusNameByCode, isFieldVisible, isMSSeller, getLoggedinUserRoleDetails, getDistinctUserRole, downloadDatabyInputFormat, getUserRole, getUserSession, getRuleValidationMessage, getEntityDataFromSession, intializeSessionConstants, isNullOrEmpty } from '../../../../components/shared/common/util';
import { PageMode } from '../../../../models/PageMode';
import CPProductSearch from './internalpricing-product-search';
import ChannelContacts from '../../common/components/channel-contacts';
import { IUserRoleDetail } from '../../../../models/IUserRoleDetail';
import { nextGenService } from '../../../../services/NextGenService';
import { UserSubRole } from '../../../../models/UserSubRole';
import { ActionType } from '../../../../models/ActionType';
import { CssClassConstants } from '../../../../content/CssClassConstants';
import { DealType } from '../../../../models/DealType';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import InternalPricingLegalEntitiesSearchAndUpload from './internalpricing-legal-entities-merge';
import { useToHandleGlassFravcr as mdppAccessibilityOpenChannelTab } from '../../../../hooks/accessiblity-hooks';
import { OtleChannel } from './otle-channel';
import { ActionMenu } from '../../../../components/shared/action-menu/action-menu';
import { IActionMenuProps } from '../../../../components/shared/action-menu/IActionMenuProps';
import { ChannelSubType } from '../../../../models/ChannelSubType';
import { getSelectedDealDetails, showActionMenuHelper } from '../../PricingProgramForm/PricingProgramFormUtils';
import { enableActionMenu } from '../../../../components/shared/action-menu/actionMenuUtil';
import { OtleChannelView } from './otle-channel-view';
import { SuccessPage } from '../../PricingProgramForm/CreateForm/SuccessPage';
import { OtleCustomerView } from './otle-customer-view';
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { InternalPricingProductDetails } from './internalpricing-product-details';
import { BreadCrumbStyles, separatorStyles } from '../../../../content/styles/Page.styles';
import { useLocation } from 'react-router-dom';
import RouteLeavingGuard from '../../common/components/RouteLeavingGuard';
import { PageConstants } from '../../../pageconstants/Constants';

type PivotOption = "Deal Overview" | "Legal Entities" | "Channel" | "Deal Details";

/**
 * InternalPricing Case/Deal Page - For both CP Strategic and OTLE programs.
 * @function component
 */
intializeSessionConstants();

const InternalPricingCase: React.FunctionComponent<PageProps> = (props: PageProps) => {
    const location = useLocation();
    
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [verticals, setVerticals] = useState<IVertical[]>([]);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [productTypes, setProductTypes] = useState<IProductType[]>([]);
    const [userRoles] = useState<string>(getUserRole());
    const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);
    const [isSaveDraftButtonClicked, setIsSaveDraftButtonClicked] = useState(false);
    const [oppNumber, setOppNumber] = React.useState<string>("");
    const [dirty, setDirty] = useState<boolean>(false);
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext();
    const dealType = internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length && internalPricingContextState.opportunityDeals[0].dealType?.name?.toLocaleLowerCase() || "";

    const saveDraftClickHandler = (state: boolean) => {
        setIsSaveDraftButtonClicked(state)
    };

    const successPageHandler = (state: boolean) => {
        setShowSuccessPage(state)
        setDirty(false)
    };

    const setOpportunityNumber = (oppNumber: string) => {
        setOppNumber(oppNumber)
    };

    const getProductTypes = (): IProductType[] => {
        let productTypes: IProductType[] = [];
        let productTypesInSession = sessionStorage.getItem(SessionStorageConsts.productTypes);
        if (productTypesInSession !== null) {
            productTypes = JSON.parse(productTypesInSession);
        }

        return productTypes;
    }

    const getCountries = (): ICountry[] => {
        let countries: ICountry[] = [];
        let countriesInSession = sessionStorage.getItem(SessionStorageConsts.countries);
        if (countriesInSession !== null) {
            countries = JSON.parse(countriesInSession);
        }

        return countries;
    }

    const getVerticals = (): IVertical[] => {
        let verticals: IVertical[] = [];
        let verticalsInSession = sessionStorage.getItem(SessionStorageConsts.verticals);
        if (verticalsInSession !== null) {
            verticals = JSON.parse(verticalsInSession);
        }

        return verticals;
    }

    const [isFieldMissing, setIsFieldMissing] = useState(false);
    const [panes] = useState<Array<any>>([
        { size: "15%", collapsible: true, resizable: true, collapsed: true },
        {}
    ]);

    /**
    * callback handler for Pivot change action.
    * @function
    */
    const onPivotChange = (item: CoherencePivotTabProps) => {
        if (item.key) {
            internalPricingContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: item.key });
        }
    };

    /**
     * Using Derived state checks if Pivot buttons is diabled.
     * @function
     * @returns {boolen}
     */
    const areCommandButtonsDisabled = (): boolean | undefined => {
        let isCommandButtonDisabled : boolean | undefined;
        switch (dealType) {
            case "cp strategic":
                isCommandButtonDisabled = !internalPricingContextState
                    || !internalPricingContextState.opportunityDeals
                    || internalPricingContextState.opportunityDeals.length === 0
                    || JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.productGroup)
                    || JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.isMultiNational)
                    || !internalPricingContextState.opportunityDeals[0].geoCountries
                    || internalPricingContextState.opportunityDeals[0].geoCountries.length === 0
                    || (internalPricingContextState.isMultiNational === true && internalPricingContextState.opportunityDeals[0].geoCountries.length <= 1)
                    || JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].dealName)
                    || JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].dealOverview)
                    || JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0].expirationDate)
                    || JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].expirationDateJustification)
                    || JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0].affiliationType)
                    || internalPricingContextState.opportunityDeals[0].dealContacts && internalPricingContextState.opportunityDeals[0].dealContacts?.length > 0 && internalPricingContextState.opportunityDeals[0].dealContacts?.filter(con => con.action !== ActionType.Delete).filter(con => !con.isComplete).length > 0
                    || (internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.Others
                        && JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].affiliationTypeOthers));
                break;

            case "otle":
                isCommandButtonDisabled = !internalPricingContextState
                    || !internalPricingContextState.opportunityDeals
                    || internalPricingContextState.opportunityDeals.length === 0
                    || !internalPricingContextState.productGroup
                    || !internalPricingContextState.opportunityDeals[0].geoCountries
                    || internalPricingContextState.opportunityDeals[0].geoCountries.length === 0
                    || !internalPricingContextState.opportunityDeals[0].dealName
                    || !(internalPricingContextState.opportunityDeals[0].dealOverview)
                    || !(internalPricingContextState.opportunityDeals[0].dealVertical)
                    || (internalPricingContextState?.opportunityDeals[0]?.dealVolume == 0)
                    || Number(internalPricingContextState.opportunityDeals[0]?.dealVolume) < Number(sessionStorage.getItem(SessionStorageConsts.dealQuantityThresholdConfigs))
                    || !(internalPricingContextState.opportunityDeals[0].expirationDate)
                    || (internalPricingContextState.opportunityDeals[0]?.dealContacts != null) && internalPricingContextState.opportunityDeals[0].dealContacts && internalPricingContextState.opportunityDeals[0].dealContacts?.length > 0 && internalPricingContextState.opportunityDeals[0].dealContacts?.filter(con => con.action !== ActionType.Delete).filter(con => !con.isComplete).length > 0
                    || (!internalPricingContextState?.attachments || (internalPricingContextState?.attachments && (internalPricingContextState?.attachments?.length <= 0 || internalPricingContextState?.attachments?.filter(att => att?.source == UIControlsText.ProofOfPreference)?.length <= 0)))
                   ;
                break;
        }
        return isCommandButtonDisabled;
    }

    /**
     * Get tab style.
     * @function
     */
    const getTabStyle = (tabName: string) => {
        return areCommandButtonsDisabled() ? PageStyles.disabledTabs : (internalPricingContextState.missingTabs?.find(tab => tab == tabName) != null? PageStyles.missingFieldTabs : {});
    }

    useEffect(() => {
        if (internalPricingContextState.missingTabs && internalPricingContextState.missingTabs.length > 0) {
            setIsFieldMissing(true);
        }
        else {
            setIsFieldMissing(false);
        }
    }, [internalPricingContextState.missingTabs])

    useEffect(() => {
        if (location && isNullOrEmpty(location.search)) {
            setDirty(true)
        }
    }, [location])

    const facetList = (facetNames: string[]): string[] => {
        let facetCount = ApplicationConstants.FacetCount;
        let facetString: string[] = [];

        for (let i = 0; i < facetNames.length; i++) {
            facetString.push(facetNames[i] + ",count:" + facetCount);
        }

        return facetString;
    }

    const createFilterExpression = (facetNames: string[], facetName: string, selectedValue: any[], allSelectedFacets: any[]): string => {
        let filterStringExpression = "";
        for (var i = 0; i < facetNames.length; i++) {
            if (facetNames[i] !== facetName) {
                if (allSelectedFacets[i].length > 0) {
                    filterStringExpression = filterStringExpression + "(" + allSelectedFacets[i].map((item: IFacetData) => {
                        if (item.value.indexOf("'") > -1) {
                            return " " + facetNames[i] + " eq '" + item.value.replace(/'/g, "''") + "' "
                        }
                        else {
                            return " " + facetNames[i] + " eq '" + item.value + "' "
                        }
                    }).join(" or ") + ") and ";
                }
            }
            else {
                if (selectedValue.length > 0) {
                    filterStringExpression = filterStringExpression + "(" + selectedValue.map(item => {
                        if (item.value.indexOf("'") > -1) {
                            return " " + facetNames[i] + " eq '" + item.value.replace(/'/g, "''") + "' "
                        }
                        else {
                            return " " + facetName + " eq '" + item.value + "' "
                        }
                    }).join(" or ") + ") and ";
                }
            }
        }

        return filterStringExpression;

    }

    const createGlobalFilterExpression = (filterNames: string[], filterValues: any[], euFilter: boolean): string => {
        let filterStringExpression = "";
        for (let i = 0; i < filterValues.length; i++) {
            if (filterValues[i].length > 0) {
                if (euFilter && filterNames[i] === ApplicationConstants.CustomerSearchCountryGlobalIndex) {
                    filterStringExpression = filterStringExpression + "(" + ApplicationConstants.CustomerSearchCascadeCountryGlobalIndex + " eq 'EU' or " + filterValues[i].map((item: string) => " " + filterNames[i] + " eq '" + item + "' ").join(" or ") + ") and ";
                }
                else {
                    filterStringExpression = filterStringExpression + "(" + filterValues[i].map((item: string) => " " + filterNames[i] + " eq '" + item + "' ").join(" or ") + ") and ";
                }
            }
            else if (euFilter) {
                filterStringExpression = filterStringExpression + "(" + ApplicationConstants.CustomerSearchCascadeCountryGlobalIndex + " eq 'EU') and ";
            }

        }

        return filterStringExpression;
    }

    //Gets channel contacts tab visibility flag.
    const isChannelContactTabVisible = (dealType: string): boolean => {
        return (isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts), internalPricingContextState.pageMode, ApplicationConstants.CPstrategicPageName) || isMSSeller()) && dealType === DealType.CPStrategic.toLocaleLowerCase();
    }

    const [disableDownloadButton, setDisableDownloadButton] = useState(false)
    const [hideDownLoadButton, setHideDownLoadButton] = useState(true)
    const [hideChannel, setHideChannel] = useState(false)
    const [actionMenuConfig, setActionMenuConfig] = React.useState<IActionMenuProps>();

    useEffect(() => {
        const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
        var countries = userRoleDetails.filter(country => country.countryId && country.countryId !== null && country.countryId !== "").map(function (userRoleDetail) { return userRoleDetail.countryId }).join(',');
        var accounts = userRoleDetails.filter(accounts => accounts.accountId && accounts.accountId !== null && accounts.accountId !== "").map(function (userRoleDetail) { return userRoleDetail.accountId }).join(',');
        var userRole = getUserRole();
        var userSubRole = userRole.split(",");
        if (userSubRole.includes(UserSubRole.MSStoreUser)) {
            setHideDownLoadButton(false)
            setHideChannel(true)
        }
        else if ((userSubRole[0] === UserSubRole.ADD || userSubRole[0] === UserSubRole.ADR || userSubRole[0] === UserSubRole.DMP) && countries !== "" && accounts !== "") {
            setHideDownLoadButton(false)
        }
        else {
            setHideDownLoadButton(true)
        }
        setVerticals(getVerticals());
        setCountries(getCountries());
        setProductTypes(getProductTypes());
    }, [internalPricingContextState.id]);

    useEffect(() => {
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() || internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()) {
            if (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length > 0) {
                var oppDeal = internalPricingContextState.opportunityDeals[0];
                if (oppDeal) {
                    const oppDealdetails = showActionMenuHelper(internalPricingContextState, oppDeal);
                    const actionMenuConfig = enableActionMenu(oppDealdetails);
                    actionMenuConfig.showCPStrategicLetters = false // hiding manufacturers confirmation letter action when viewing DR/SP case
                    setActionMenuConfig({ actionConfig: actionMenuConfig, selectedData: getSelectedDealDetails(oppDealdetails,  oppDeal)});
                }
            }
        }
    }, [internalPricingContextState.id, internalPricingContextState.pageMode]);

    //Temporarily Commented to check performance issue 

    //accessibility - add for attribute to each label not associated with an input/text element.
    useEffect(() => {
        let comboBoxes = document.querySelectorAll("div[role='combobox']");
        comboBoxes.forEach(comboBox => {
            let label = document.querySelector("#" + comboBox.id + "-label");
            label && label.setAttribute("for", comboBox.id);
        });

        //accessibility - screen reader callout deal country as disabled/unavailable when all other fields are disabled
        var formElements = document.querySelectorAll('.ms-Stack ~ .ms-Stack .ms-Dropdown , .ms-Stack ~ .ms-Stack .ms-TextField');
        var dropDown = document.querySelector('#' + CssClassConstants.DealCountryDropdownId);
        var filteredFormElements = Array.from(formElements).filter(formElement => formElement.id !== CssClassConstants.DealCountryDropdownId);

        if (filteredFormElements.every(formElement => formElement.classList.contains("is-disabled"))) {
            dropDown?.setAttribute("aria-disabled", "true");
            dropDown?.removeAttribute("aria-required");
        }
    });

    //Temporarily Commented to check performance issue 
    useEffect(() => {
        //Fixing Accessibility for sortIcons
        var sortIcons = document.querySelectorAll("span[aria-label='Sortable']");
        sortIcons.forEach((element) => {
            element.setAttribute("role", "button");
        });

        //Fixing Accessibility for GridPagerBtns & gridFootercell
        var pagerBtns = document.getElementsByClassName("k-icon");
        for (let i = 0; i < pagerBtns.length; i++) {
            pagerBtns[i].setAttribute("aria-hidden", "true");
        }
        document.querySelector("[role='columnfooter']")?.setAttribute('role', 'gridcell');

        //Fixing Accessibility issue for grid filterTextbox
        /*var filterTextbox = document.querySelectorAll(".k-filtercell .k-filtercell-wrapper > .k-textbox");
        const currentColumns = props.kendoGridProps.columns.filter(col => col.isVisible === true);
        filterTextbox.forEach(c => {
            for (let i = 0; i < filterTextbox.length; i++) {
                if (currentColumns[i]) {
                    filterTextbox[i].setAttribute('aria-label', `${ currentColumns[i].columnTitle }`);
                }
            }
        });*/

        //Fixing Accessibility issue for grid checkbox
        var gridCheckbox = document.getElementsByClassName("k-checkbox");
        for (let i = 0; i < gridCheckbox.length; i++) {
            gridCheckbox[i].setAttribute('aria-label', 'checkbox');
        }

        //fixing Accessibility issue when no rows selected in grid
        var gridRows = document.getElementsByClassName("k-grid-norecords");
        for (let i = 0; i < gridRows.length; i++) {
            gridRows[i].removeAttribute('aria-rowindex');
            gridRows[i].setAttribute('role', 'alert');
        }

        //fixing Accessibility issue when no rows selected in grid
        var gridRow = document.querySelectorAll('[role="row"]');
        for (let i = 0; i < gridRow.length; i++) {
            gridRow[i].removeAttribute('aria-rowindex');
        }

        // Fix for accessibility issue - No label text found for input elements
        var filesTxt = document.querySelectorAll('input[type="file"]');
        for (let i = 0; i < filesTxt.length; i++) {
            filesTxt[i].setAttribute('aria-label', 'files');
        }

        // Accessibility fix for "nested-interactive controls"
        var selectFilesButtonContainers = document.querySelectorAll('div[aria-label="Select files..."]');
        selectFilesButtonContainers.forEach((node) => {
            if (node.getAttribute("role") === "button") {
                node.removeAttribute("role");
            }
        });

        var gridCellInputs = document.querySelectorAll('[role="gridcell"] > input');
        gridCellInputs.forEach(gridCellInput => {
            gridCellInput && (gridCellInput.className === 'k-textbox' || gridCellInput.className === 'k-input k-formatted-value') && gridCellInput.setAttribute('aria-label', "grid cell textbox");
        });

        var klinkSpans = document.querySelectorAll('span.k-link');
        klinkSpans.forEach(klinkSpan => {
            klinkSpan && klinkSpan.getAttribute('aria-label') && klinkSpan.removeAttribute('aria-label');
        });
    });

    var mddppAccessibilityPivot = mdppAccessibilityOpenChannelTab();
    var mdppAccessibilityPivotHandler = (): string => {
        if (mddppAccessibilityPivot) {
            return mddppAccessibilityPivot as PivotOption;
        }
        return internalPricingContextState.selectedPivot as PivotOption;
    }

    return (
        <>
        <RouteLeavingGuard when={dirty} pageMode={internalPricingContextState.pageMode?.toLocaleLowerCase() || ""} />
        { showSuccessPage && 
            <SuccessPage { ...{"id": "SuccessPage", "caseNumber": oppNumber, "isSavingDraft": isSaveDraftButtonClicked, "opportunityId": internalPricingContextState.id, "navigateTo": dealType === DealType.CPStrategic.toLocaleLowerCase() ? "/managecase/cpstrategic" : "/managecase/otle", "dealType": dealType} } /> }
        { !showSuccessPage && 
        <div className="headerStyle" id="parentDiv" data-nextgen-parent-group="nextgen-tabs" data-nextgen-parentid="nextgen-tabs">
            {
                (internalPricingContextState.pageMode?.toLocaleLowerCase() === PageMode.Edit.toLocaleLowerCase() || internalPricingContextState.pageMode?.toLocaleLowerCase() === PageMode.View.toLocaleLowerCase()) &&
                <ActionMenu {...actionMenuConfig}/>
            }
            {(internalPricingContextState.saveDraft || internalPricingContextState.submitCase || internalPricingContextState.submitCaseAsync) &&
                <div className="overlay">
                </div>
            }
            {isFieldMissing && (
                <Stack style={PageStyles.ErrorMessageStyles}>
                    {internalPricingContextState.pageMode?.toLowerCase() !== PageMode.View.toLowerCase() &&
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                            onDismiss={() => setIsFieldMissing(false)}
                            dismissButtonAriaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CloseText", UIControlsText.CloseText)}>
                            {internalPricingContextState.errors && internalPricingContextState.errors.length ?
                                internalPricingContextState.errors.join('\n')
                                : getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredFieldMissing", UIControlsText.RequiredFieldMissing)}
                        </MessageBar>
                    }
                </Stack>
            )}
            {(internalPricingContextState.pageMode?.toLowerCase() === PageMode.Create.toLowerCase()) &&
                <Stack>
                        <Breadcrumb id={"BreadcrumbNewCase"} styles={BreadCrumbStyles} 
                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseSubmissionText", UIControlsText.CaseSubmissionText)} 
                            items={[
                                { text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HomeText", UIControlsText.HomeText), key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HomeText", UIControlsText.HomeText), href: "/homepage" },
                                { text: dealType == DealType.OTLE.toLocaleLowerCase() ? UIControlsText.OTLENewCaseSubmission : UIControlsText.CPNewCaseSubmission, key: "NCS", href: (dealType == DealType.OTLE.toLocaleLowerCase() ? "/managecase/otle" :  "/managecase/cpstrategic")}
                            ]}>
                        </Breadcrumb>
                        <Separator id={"SeparatorId"} styles={separatorStyles}>  
                        </Separator>
                </Stack>
            }
            {(internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() || internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) &&
                <Stack>
                    <div>
                        <div className="caseIdStyle">
                            <h6>
                                {JavaScriptExtensions.stringFormat("{0}{1}{2}{3}{4}", getURLParamValue(ApplicationConstants.OpportunityNumberParamName), " | ", (internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase()? UIControlsText.ViewCaseText : UIControlsText.EditCaseText) , " | ", dealType === DealType.CPStrategic.toLocaleLowerCase()? ApplicationConstants.CPStrategic : ApplicationConstants.OTLECases)}
                            </h6>
                        </div>
                        <div className="caseStatusStyle">
                            <h6>
                                {JavaScriptExtensions.stringFormat("{0}{1}{2}", "Status", " - ", getStatusNameByCode(internalPricingContextState.opportunityDeals[0].statusCode))}
                            </h6>
                        </div>
                    </div>
                </Stack>
            }
            <CoherencePivot className="tabListStyle"
                onTabSelected={onPivotChange}
                useGrayPivot={true}
                selectedKey={mdppAccessibilityPivotHandler()}
                tabs={[
                    {
                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                        key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText),
                        headerButtonProps: { 
                            style: getTabStyle(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText)),
                            "data-nextgen-id": getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText) 
                        }
                    },
                    {
                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText),
                        key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText),
                        headerButtonProps: {
                            ...{
                                disabled: areCommandButtonsDisabled(),
                                style: getTabStyle(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText)),
                                "data-nextgen-id": getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText)
                            }
                        }
                    },
                    {
                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText),
                        key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText),
                        headerButtonProps: {
                            ...{
                                disabled: areCommandButtonsDisabled(),
                                style: getTabStyle(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)),
                                hidden: hideChannel,
                                "data-nextgen-id": getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)
                            }
                        }
                    },
                    {
                        name: internalPricingContextState.pageMode?.toLowerCase() === PageMode.View.toLowerCase() ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PricingAndDiscountsText", UIControlsText.PricingAndDiscountsText): getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection),
                        key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection),
                        headerButtonProps: {
                            ...{
                                disabled: areCommandButtonsDisabled(),
                                style: getTabStyle(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection)),
                                "data-nextgen-id": getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection)
                            }
                        }
                    },
                    {
                        name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts),
                        key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts),
                        headerButtonProps: {
                            ...{
                                disabled: areCommandButtonsDisabled(),
                                style: getTabStyle(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)),
                                hidden: !isChannelContactTabVisible(dealType),
                                "data-nextgen-id": getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)
                            }
                        }
                    },
                ]}
            />
            <PivotContent selectedKey={mdppAccessibilityPivotHandler()}>
                <PivotContentItem itemKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText)}>
                    <InternalPricingDealOverview {... { countries: countries, productTypes: productTypes, verticals: verticals, internalPricingDealType: dealType }
                    } />
                </PivotContentItem>
            </PivotContent>
            <PivotContent selectedKey={mdppAccessibilityPivotHandler()}>
                <PivotContentItem itemKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText)}>
                    {
                        (internalPricingContextState.pageMode === PageMode.View || internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Approved
                            || internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Incomplete) && dealType === DealType.OTLE.toLocaleLowerCase()
                        && <OtleCustomerView />
                    }
                    {
                        internalPricingContextState.pageMode !== PageMode.View && internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Approved 
                            && internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Incomplete && dealType === DealType.OTLE.toLocaleLowerCase()
                        && <InternalPricingLegalEntitiesSearchAndUpload
                        {
                        ...{
                            facetList,
                            createFilterExpression,
                            createGlobalFilterExpression,
                            splitterConfig: panes
                        }
                        }
                    />
                    }
                    {
                        dealType === DealType.CPStrategic.toLocaleLowerCase()
                        && <InternalPricingLegalEntitiesSearchAndUpload
                        {
                        ...{
                            facetList,
                            createFilterExpression,
                            createGlobalFilterExpression,
                            splitterConfig: panes
                        }
                        }
                    />
                    }                    
                </PivotContentItem>
            </PivotContent>
            <PivotContent selectedKey={mdppAccessibilityPivotHandler()}>
                <PivotContentItem itemKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)}>
                    {
                        internalPricingContextState.pageMode === PageMode.View && dealType === DealType.OTLE.toLocaleLowerCase()
                        && <OtleChannelView />
                    }
                    {
                        internalPricingContextState.pageMode !== PageMode.View && dealType === DealType.OTLE.toLocaleLowerCase()
                        && <OtleChannel {... { countries: countries, productTypes: productTypes }} />
                    }
                    {
                        dealType === DealType.CPStrategic.toLocaleLowerCase()
                        && <CPStrategicChannel {... { countries: countries, productTypes: productTypes }} />
                    }
                </PivotContentItem>
            </PivotContent>
            <PivotContent selectedKey={mdppAccessibilityPivotHandler()}>
                <PivotContentItem itemKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection)}>
                    {
                        internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft && internalPricingContextState.pageMode === PageMode.View ? <InternalPricingProductDetails /> : 
                        <CPProductSearch
                        {
                        ...{
                            verticals,
                            facetProps: {
                                facetList,
                                createFilterExpression,
                                createGlobalFilterExpression,
                                splitterConfig: panes
                            }
                        }
                        }
                        />
                    }                   
                </PivotContentItem>
            </PivotContent>
            {isChannelContactTabVisible(dealType) &&
                <PivotContent selectedKey={mdppAccessibilityPivotHandler()}>
                    <PivotContentItem itemKey={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)}>
                        <ChannelContacts {... { countries: getCountries(), productTypes: getProductTypes(), pageContext: internalPricingContextState, pageDispatch: internalPricingContextDispatch }} />
                    </PivotContentItem>
                </PivotContent>
            }
            <InternalPricingCommands {...{ areCommandButtonsDisabled, saveDraftClickHandler, successPageHandler, setOpportunityNumber, dealType }} />
            <div id="footerPadding"></div>
        </div> }
        </>

    );
}

export default InternalPricingCase;