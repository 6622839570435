import React, { useState, useEffect, useRef } from 'react';
import { UIControlsText, UIConfigIdentifierConstants } from '../../../../content/UIControlsText';
import { PageStyles, DealDetailsStyles } from '../../common/content/Page.Styles';
import { Dropdown, IDropdownOption, MessageBarType, IChoiceGroupOption, ActionButton, TextField, Stack } from '@fluentui/react';
import { PrimaryResellerGridColumnConfig, PrimaryResellerGridItemsType } from '../../../shared/ComponentTypes/DetailListEditableGridTypes';
import { defaultColumnProps, DefaultPageStyles } from '../../../../content/styles/Page.styles';
import { CaseActionTypes } from '../../common/reducer/CaseActionTypes';
import { isEditableRow, isValidEmailWithSeperator, getFieldConfigByState, getRequestorsChannelFunctionalities, isMsStoreUser, getParentAccountAction } from '../../../../components/shared/common/util';
import { stackItemStyles, channelConfig, smallAlignmentTokens, initialChannelDisplayOnEdit, selectCountryBasedOnRegion, replaceMEToUAEForDistributors } from '../../PricingProgramForm/PricingProgramFormUtils';
import { Guid } from '../../../../infrastructure/Guid';
import { IOpportunityAccount } from '../../../../models/IOpportunityAccount';
import { AccountAllType } from '../../../../models/AccountAllType';
import { IOpportunityChannelContact } from '../../../../models/IOpportunityChannelContact';
import { nextGenService } from '../../../../services/NextGenService';
import { JavaScriptExtensions } from '../../../../infrastructure/JavaScriptExtensions';
import { PartnerTypeLabel } from '../../../../models/PartnerTypeLabel';
import { ErrorMessages } from '../../../../content/ErrorMessages';
import { ChannelSubType } from '../../../../models/ChannelSubType';
import { StatePropertiesText } from '../../common/state/StatePropertiesText';
import { IEntityLookupColumnConfig } from '../../../../models/IEntityLookupColumnConfig';
import { IAccount } from '../../../../models/IAccount';
import { PageMode } from '../../../../models/PageMode';
import { partnerTypeValues } from '../../../../services/StaticData';
import { GridType } from '../../../../models/GridType';
import { CssClassConstants } from '../../../../content/CssClassConstants';
import _ from 'lodash';
import { ICountry } from '../../../../models/ICountry';
import { GridSelectionType } from '../../../../components/shared/grid/SelectionType';
import { PartnerType } from '../../../../models/PartnerType';
import { ActionType } from '../../../../models/ActionType';
import { IColumnConfig } from 'fluentui-editable-grid';
import { IColumn } from 'office-ui-fabric-react';
import { UserSubRole } from '../../../../models/UserSubRole';
import { FieldMapMode } from '../../../../models/FieldMapMode';
import { countriesFilter, getRuleValidationMessage, getEntityDataFromSession } from '../../../../components/shared/common/util';
import { UIControlType } from '../../../../models/UIControlType';
import { OpportunityDealStatusCodes } from '../../../../models/OpportunityDealStatusCodes';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { IDealInfoProps } from '../../PricingProgramForm/props/IDealInfoProps';
import CustomTileCardComponent from '../../../shared/ComponentTypes/CustomTileCardComponent'
import { useInternalPricingContext } from '../context/internalpricing-context';
import { isMSStoreDisable } from "../../../../components/shared/common/util";

export function OtleChannel(props: IDealInfoProps) {
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext();
    const currentDealStatusCode = internalPricingContextState.opportunityDeals.find(t => t.name === internalPricingContextState.selectedOptyDealCaseNumber)?.statusCode === OpportunityDealStatusCodes.Approved ? OpportunityDealStatusCodes.Approved : 0;
    const [endCustomerCountry, setEndCustomerCountry] = useState<ICountry>();
    const [primaryResellerGridItems, setPrimaryResellerGridItems] = useState<PrimaryResellerGridItemsType[]>([]);
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    //Show the content appropriate to the Channel Requestor Role and other combinations
    const [showChannelContentBasedOnRoleType] = useState(() => {
        return getChannelPersona();
    });
    const productGroup = internalPricingContextState?.productGroup || "";
    const [resellerRegionalCountries, setResellerRegionalCountries] = useState<ICountry[]>();
    const [resellers, setResellers] = useState<any>([]); // To update with resellers
    const [selectedResellerCountry, setSelectedResellerCountry] = useState<any>('');
    const [selectedResellerType, setSelectedResellerType] = useState<any>(PartnerType.ADR);
    const [initialResellerType] = useState<any>(PartnerType.ADR);
    const [selectedResellers, setSelectedResellers] = useState<any[]>([]);
    const [panelType, setPanelType] = useState<string>('');
    const [invalidResellers, setInvalidResellers] = useState<string>("");
    const [hideInvalidResellerDialog, setHideInvalidResellerDialog] = useState<boolean>(true);
    const pivotTab = internalPricingContextState.pivotTabs?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
    const [msStoreSelection, setMsStoreSelection] = useState(UIControlsText.NoText);
    const getMsStoreItem = (): string => {
        return internalPricingContextState?.opportunityAccounts?.find(opp => opp.partnerType === PartnerType.MsStore) ? UIControlsText.YesText : UIControlsText.NoText
    };

    const onPanelClick = (value: string) => {
        setPanelType(value);
    };

    const setSelectedResellersOrDistributors = () => {
        if (internalPricingContextState?.opportunityAccounts && internalPricingContextState?.opportunityAccounts.length > 0) {
            const initialSelectedResellers = internalPricingContextState?.opportunityAccounts?.filter(reseller => (reseller.partnerType === PartnerType.ADR || reseller.partnerType === PartnerType.DMP) && reseller.action !== ActionType.PanelAdd).map((x: any) => ({
                accountId: x.accounts.length > 0 && x.accounts[0].accountId,
                companyName: x.accountCompanyName,
                countryId: x.countryId,
                countryName: x.countryName,
                partnerType: x.partnerType,
                organizationId: x.accounts.length > 0 && x.accounts[0].organizationId,
                action: x.action
            }))
            setSelectedResellers(initialSelectedResellers || [])
        }
    }

    const onResellerTypeChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        if (item) {
            let filteredValues = resellerAccounts.current.filter((val: any) => val.partnerType === item.key);
            if (selectedResellerCountry) {
                filteredValues = filteredValues.filter((val: any) => val.countryId === selectedResellerCountry);
            }
            const updatedResellers = {
                current: filteredValues
            };
            setSelectedResellerType(item.key);
            setResellers(updatedResellers);
        }
    };

    const onCountryChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        const [list, setSelectedCountry, setSelectedList] =
            [resellerAccounts, setSelectedResellerCountry, setResellers];
        if (item) {
            let filteredValues = list.current.filter((val: any) => val.countryId === item.key);
            if (panelType === 'reseller' && selectedResellerType) {
                filteredValues = filteredValues.filter((val: any) => val.partnerType === selectedResellerType)
            }
            const updatedList = {
                current: filteredValues
            };
            setSelectedCountry(item.key);
            setSelectedList(updatedList);
        }
    }

    const clearErrorFromContext = (partnerTypes?: PartnerType[], optyAccounts?: IOpportunityAccount[], targetPropertyId?: string, errorMessage?: string) => {
        const pivotTabsData = internalPricingContextState.pivotTabs;
        let pivotTabChannel = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || {};
        const optyAccount = optyAccounts?.find(t => (t.channelSubType !== ChannelSubType.Requester) && (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(partnerTypes?.find(x => t.partnerType === x))));
        if (!JavaScriptExtensions.isNullOrUndfinedOrEmpty(optyAccount)) {
            pivotTabChannel?.errors?.delete(targetPropertyId || "");
        }
        else {
            pivotTabChannel?.errors?.set(targetPropertyId || "", errorMessage || "");
        }

        internalPricingContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
    }

    const resellerColumnDefinitions: IEntityLookupColumnConfig[] = [
        {
            apiField: 'name',
            key: 'name',
            columnTitle: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameText", UIControlsText.ResellerNameText),
            displayOrder: 2
        }, {
            apiField: 'organizationId',
            key: 'organizationId',
            columnTitle: 'MPN ID',
            displayOrder: 3,
        }];


    const resellerTypes = [PartnerType.ADR, PartnerType.DMP];

    const resellerTableConfigurations = (
        <div className='configContainer'>
            <Dropdown
                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText)}
                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText)}
                options={_.uniqBy(resellerTypes.map((item: any) => ({ key: item, text: partnerTypeValues[item] } as IDropdownOption)), 'key')}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerTypeText", UIControlsText.ResellerTypeText)}
                styles={PageStyles.PaneldropdownStyles}
                onChange={onResellerTypeChange}
                selectedKey={selectedResellerType}
                disabled={false}
                required
            />
            <Dropdown
                label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerCountryText", UIControlsText.ResellerCountryText)}
                ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerCountryText", UIControlsText.ResellerCountryText)}
                options={_.uniqBy(resellerRegionalCountries?.map((item: any) => ({ key: item.countryId, text: item.countryName } as IDropdownOption)), 'key')}
                placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "ResellerCountryPlaceholderText", UIControlsText.ResellerCountryPlaceholderText)}
                styles={PageStyles.PaneldropdownStyles}
                onChange={onCountryChange}
                defaultSelectedKey={endCustomerCountry?.countryId}
                selectedKey={selectedResellerCountry}
                disabled={!endCustomerCountry?.isEUEFTA}
                required
            />
        </div>);

    const onResellerChange = (selectedItems: any[]): void => {
        const [list, setSelectedList, countrySelection] = [resellerAccounts, setSelectedResellers, selectedResellerCountry];
        if (selectedItems) {
            const updatedValues = list.current.map((item: any) => {
                return {
                    ...item,
                    key: `${item.accountId}-${item.partnerType}`,
                };
            });
            const modifiedList = {
                current: updatedValues,
            };
            let updatedList: IAccount[] = [];
            if (selectedItems) {
                selectedItems.forEach(x => {
                    let value: any;
                    value = modifiedList.current?.find((y: any) => y.key === x.key);
                    if (value) {
                        value.action = JavaScriptExtensions.isNullOrUndfinedOrEmpty(x.action) ? ActionType.PanelAdd : x.action;
                        updatedList.push(value);
                    }
                });
            }
            setSelectedList(updatedList);
        }
    }

    const onClearAll = () => {
        if (panelType === 'reseller') {
            setSelectedResellersOrDistributors();
            const filteredResllers: any = resellerAccounts.current.filter((item: any) => item.countryId === endCustomerCountry?.countryId)
            setResellers({ current: filteredResllers })
            setSelectedResellerType(initialResellerType)
            setSelectedResellerCountry(endCustomerCountry?.countryId)
        }
    };

    const onAddButtonClicked = () => {
        onPrimaryResellerAdd([...selectedResellers]);
    }

    const [opportunityAccounts, setOpportunityAccounts] = useState<IOpportunityAccount[]>([]);
    const [opportunityChannelContacts, setOpportunityChannelContacts] = useState<IOpportunityChannelContact[]>([]);
    const [isResellerLoading, setIsResellerLoading] = useState<boolean>(false);
    const resellerAccounts = useRef<IAccount[]>([]);
    function getChannelPersona() {
        let initialChannelDisplay;
        if (isMsStoreUser()) {
            initialChannelDisplay = getRequestorsChannelFunctionalities(UserSubRole.MSStoreUser, endCustomerCountry?.countryName || "");
        }
        else if (internalPricingContextState.pageMode === PageMode.Create) {
            initialChannelDisplay = getRequestorsChannelFunctionalities(internalPricingContextState.partnerDetails?.userRole || "", endCustomerCountry?.countryName || "");
        }
        else {
            initialChannelDisplay = getRequestorsChannelFunctionalities(PartnerType[0] || "", endCustomerCountry?.countryName || "");
            initialChannelDisplayOnEdit(internalPricingContextState, initialChannelDisplay);
        }
        return initialChannelDisplay;
    }

    //Create a new IOpportunityAccount
    function getNewOpportunityAccount(type?: PartnerType, partnerAccount?: IAccount): IOpportunityAccount {
        return { id: Guid.newGuid(), accounts: partnerAccount ? [{ ...partnerAccount, action: ActionType.Add }] : undefined, partnerType: type, isAllSelected: AccountAllType.None, isComplete: true, countryId: selectCountryBasedOnRegion(endCustomerCountry?.countryId || "", partnerAccount?.countryId || "", props?.countries || [], type), action: ActionType.Add } as IOpportunityAccount;
    }

    //Create a new IOpportunityChannelContact
    function getNewOpportunityChannelContact(opportunityAccountId?: string, email?: string): IOpportunityChannelContact {
        return { id: Guid.newGuid(), opportunityAccountId: opportunityAccountId, channelContactEmails: email?.replace(',', ';'), source: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText), action: ActionType.Add, isComplete: email ? isValidEmailWithSeperator(email, ';') : false } as IOpportunityChannelContact;
    }

    async function getResellers(countries: ICountry[]) {
        setIsResellerLoading(true);

        //Remove resellers when the countries are removed.
        resellerAccounts.current = resellerAccounts.current.filter(r => r.productTypeId === productGroup).filter(r => countries.map(c => c.countryId).indexOf(r.countryId as string) >= 0);

        //Newly selected countries
        let newCountries = countries.filter(c => resellerAccounts.current.filter(r => r.productTypeId === productGroup).map(r => r.countryId).indexOf(c.countryId) === -1);
        let count = newCountries.length;

        if (newCountries.length === 0) {
            setIsResellerLoading(false);
        }

        //Get resellers for newly selected countries
        newCountries.forEach(async c => {
            let response = await nextGenService.getAllActiveResellersByProductAndCountry(productGroup, c.countryId);
            count--;
            if (count === 0) {
                setIsResellerLoading(false);
            }

            if (response.status === 200) {
                resellerAccounts.current = resellerAccounts.current.concat(response.data);
                resellerAccounts.current = _.uniqBy(resellerAccounts.current, v => [v.accountId, v.partnerType].join());
                setResellers({ current: resellerAccounts.current });
            }
        });
    }

    useEffect(() => {
        var regionalCountries: ICountry[] = [];
        if (internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries.length) {
            const countryId = internalPricingContextState?.opportunityDeals[0]?.geoCountries[0] && internalPricingContextState?.opportunityDeals[0]?.geoCountries[0].countryId;
            var endCustomerCountry = props.countries?.find(c => c.countryId === countryId);
            if (endCustomerCountry && endCustomerCountry.countryId) {
                setEndCustomerCountry(endCustomerCountry);
                setSelectedResellerCountry(endCustomerCountry.countryId);
                regionalCountries = countriesFilter(endCustomerCountry.countryId)
                setResellerRegionalCountries(regionalCountries);
                internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [] });
                setMsStoreSelection(UIControlsText.NoText);
            }

            if (regionalCountries.length > 0 && JavaScriptExtensions.isDefined(productGroup)) {
                getResellers(regionalCountries || []);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.geoCountries]);

    useEffect(() => {
        if (resellerRegionalCountries && resellerRegionalCountries.length > 0 && JavaScriptExtensions.isDefined(productGroup)) {
            getResellers(resellerRegionalCountries || []);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalPricingContextState?.productGroup]);

    useEffect(() => {
        if (internalPricingContextState.opportunityAccounts) {

            //Validate if selected account is still valid when editing Draft case
            if (internalPricingContextState.pageMode === PageMode.Edit
                && internalPricingContextState.opportunityDeals.length
                && internalPricingContextState.opportunityDeals[0]?.statusCode === OpportunityDealStatusCodes.Draft) {
                //Reseller account validation
                const invalidResellers = internalPricingContextState.opportunityAccounts.filter(a => (a.partnerType === PartnerType.ADR || a.partnerType === PartnerType.DMP) && a.accounts?.some(x => JavaScriptExtensions.isDefined(x.isActive) && !x.isActive));

                let updatedAccounts = [...internalPricingContextState.opportunityAccounts];
                let invalidPartners: string = "";
                if (invalidResellers && invalidResellers.length > 0) {
                    //Remove the invalid reseller & Show message
                    updatedAccounts = updatedAccounts.filter(a => !invalidResellers.map(inv => inv?.id).includes(a.id));
                    invalidPartners = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailablePartner", ErrorMessages.UnavailablePartner) + invalidResellers.map(r => r?.accounts?.map(a => a.companyName)).join(', ');
                }

                if (invalidPartners != "") {
                    setInvalidResellers(invalidPartners);
                    setHideInvalidResellerDialog(false);
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: updatedAccounts });
                }
            }

            //Set IsComplete Flag on initialization
            let accounts = [...internalPricingContextState.opportunityAccounts];
            if (internalPricingContextState.opportunityAccounts.length > 0 && internalPricingContextState.opportunityAccounts.filter(acc => !JavaScriptExtensions.isDefined(acc.isComplete)).length > 0) {
                accounts.filter(acc => !JavaScriptExtensions.isDefined(acc.isComplete)).map((acc) => acc.isComplete = true);
                internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: accounts });
            }
            setOpportunityAccounts(accounts);

            let resellerItems = internalPricingContextState.opportunityAccounts.filter(reseller => reseller.partnerType === PartnerType.ADR || reseller.partnerType === PartnerType.DMP)
                .map((acc) => {
                    const contact = internalPricingContextState.opportunityChannelContacts?.find((c) => c.opportunityAccountId === acc.id);
                    return {
                        id: acc.id,
                        resellerCountry: acc.accounts && acc.accounts[0].countryName,
                        resellerType: acc.partnerType ? PartnerTypeLabel.get(acc.partnerType) : "",
                        resellerNames: acc.accounts && acc.accounts[0].companyName,
                        resellerEmailContacts: contact?.channelContactEmails,
                        deleteAction: acc.id,
                        action: acc.accounts && acc.accounts[0]?.action
                    } as PrimaryResellerGridItemsType
                });
            setPrimaryResellerGridItems(resellerItems);
            setSelectedResellersOrDistributors();
            setMsStoreSelection(getMsStoreItem());
        }
    }, [internalPricingContextState.opportunityAccounts]);

    useEffect(() => {
        if (internalPricingContextState.opportunityChannelContacts) {
            //Set IsComplete Flag on initialization
            let contacts = [...internalPricingContextState.opportunityChannelContacts]
            contacts.filter(c => !JavaScriptExtensions.isDefined(c.isComplete)).map(
                c => c.isComplete = validateContactErrorMessage(c.channelContactEmails || '', StatePropertiesText.ValidateEmailText + c.opportunityAccountId) === '');

            setOpportunityChannelContacts(contacts);
        }
    }, [internalPricingContextState.opportunityChannelContacts]);

    useEffect(() => {
        if (endCustomerCountry) {
            setSelectedResellerType(PartnerType.ADR);
        }
    }, [endCustomerCountry]);

    //Common helper function --> to handle render Action Button Delete or Edit type
    function renderActionButton(rowId: string, buttonId: string, iconName: string, isFieldDisabled: boolean | undefined, onRenderClickFunction: any
        , gridInitialItems: PrimaryResellerGridItemsType[]): JSX.Element {
        let isDisabledForExistingItems = getFieldStatusBasedOnExistingItemsActionFlag(rowId, gridInitialItems);
        let isActionButtonDisabled = isDisabledForExistingItems || ((typeof (isFieldDisabled) == 'undefined') ? false : isFieldDisabled);

        return <ActionButton
            id={buttonId}
            iconProps={{
                iconName: iconName, ariaLabel: iconName,
                styles: isActionButtonDisabled ? DefaultPageStyles.grayColor : DefaultPageStyles.redColor
            }}
            disabled={isActionButtonDisabled}
            onClick={() => { onRenderClickFunction(); console.log(); }}>
        </ActionButton>
    }

    // Attach Primary Reseller grid callbacks
    const attachPrimaryResellerCallbacks = (columnConfig: IColumnConfig[]): IColumnConfig[] => {
        const isFieldDisabled = getFieldConfigByState(internalPricingContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        columnConfig.filter((item) => item.key === 'resellerEmailContacts').map((item) => item.onRender = onRenderResellerEmail);
        columnConfig.filter((item) => item.key === 'deleteAction').map((item) => item.onRender = onRenderResellerDelete);
        if (isFieldDisabled) {
            columnConfig.forEach((item, index) => {
                if (item.key === 'deleteAction') {
                    columnConfig.splice(index, 1);
                }
            });
        }
        return columnConfig;
    };

    // Overridden OnRender event for Email textbox with validations
    function onRenderResellerEmail(item?: any, index?: number, column?: IColumn): JSX.Element {
        const fieldContent = item[column?.fieldName || ''] as string;
        const isDisabled = getFieldConfigByState(internalPricingContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        let gridInitialItems: PrimaryResellerGridItemsType[] = primaryResellerGridItems.filter((acc) => acc && acc.id === item?.id && acc.action === ActionType.Created);

        return renderTextFieldElement_HelperFunction(fieldContent, isDisabled, gridInitialItems, item, column);
    };

    //Common Helper function to render Multiline TextField Email 
    function renderTextFieldElement_HelperFunction(fieldContent: string, isDisabled: boolean | undefined, gridInitialItems: PrimaryResellerGridItemsType[], item?: any, column?: IColumn): JSX.Element {
        let isDisabledForExistingItems = getFieldStatusBasedOnExistingItemsActionFlag(item?.id, gridInitialItems);
        let isActionButtonDisabled = isDisabledForExistingItems || ((typeof (isDisabled) == 'undefined') ? false : isDisabled);

        return <TextField
            id={item[column?.key || '']}
            ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts)}
            placeholder={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "EmailCCRecipientsPlaceholderTextExample", UIControlsText.EmailCCRecipientsPlaceholderTextExample)}
            validateOnFocusOut
            required={true}
            multiline
            maxLength={1000}
            validateOnLoad={false}
            onGetErrorMessage={getEmailContactErrorMessage(StatePropertiesText.ValidateEmailText + item?.id)}
            onChange={onEmailContactsChange(item?.id)}
            disabled={isActionButtonDisabled}
            value={internalPricingContextState?.opportunityChannelContacts?.find(t => t.opportunityAccountId === item.id && t.source === "Channel")?.channelContactEmails}
            errorMessage={pivotTab?.errors?.get(StatePropertiesText.ValidateEmailText + item?.id)}
        />
    }

    // Reseller contact email change event handler
    const onEmailContactsChange = (accountId: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let contacts = [...opportunityChannelContacts];
        updateChannelContact(contacts, accountId, newValue);
        internalPricingContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: contacts });
    }

    // Overridden OnRender event for Primary Reseller Delete button
    function onRenderResellerDelete(item?: any, index?: number, column?: IColumn): JSX.Element {
        const accountId = item[column?.fieldName || ''] as string;
        const isFieldDisabled = getFieldConfigByState(internalPricingContextState, "PrimaryResellerContainer", FieldMapMode.Disable);
        const iconName = CssClassConstants.DeleteIconName;
        let gridInitialItems: PrimaryResellerGridItemsType[] = primaryResellerGridItems.filter((acc) => acc && acc.id === accountId && acc.action === ActionType.Created);

        return renderActionButton(accountId, 'primaryResellerDeleteButton', iconName, isFieldDisabled, () => onResellerDelete(accountId), gridInitialItems);
    }

    // Primary Reseller Delete button event handler
    function onResellerDelete(accountId: string) {
        if (internalPricingContextState.opportunityAccounts && accountId) {
            //Update Panel related variables
            let accounts = [...internalPricingContextState.opportunityAccounts].find((acc) => acc.id === accountId)?.accounts || [];
            let updatedAccounts = selectedResellers.filter((account) => account.accountId !== accounts[0].accountId);
            setSelectedResellers(updatedAccounts);

            //Update context
            let oppAccounts = [...internalPricingContextState.opportunityAccounts].filter((acc) => acc.id !== accountId);
            let contacts = [...internalPricingContextState.opportunityChannelContacts || []].filter((contact) => contact.opportunityAccountId !== accountId);
            internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: oppAccounts });
            internalPricingContextDispatch({ type: CaseActionTypes.channelContactChanged, channelContacts: contacts });
            clearErrorFromContext([PartnerType.ADR], oppAccounts, StatePropertiesText.ValidatePrimaryResellers, getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidatePrimaryResellersOnly", ErrorMessages.ValidatePrimaryResellersOnly));
        }
    }

    //Common Helper function to check if the existing row's delete button needs to be disabled.
    function getFieldStatusBasedOnExistingItemsActionFlag(rowAccountId: string, gridInitialItems: PrimaryResellerGridItemsType[]) {
        let isDisabled: boolean = false;

        if (gridInitialItems?.length > 0) {
            isDisabled = true;
        }
        return isDisabled;
    }

    //Add new IOpportunityAccount
    const addAccounts = (selectedAccounts: IAccount[]) => {
        //Remove uncheched accounts & its associated contacts
        let updatedAccounts = opportunityAccounts.filter(acc => selectedAccounts.some(s => acc.accounts && acc.accounts[0]?.accountId === s.accountId));
        let updatedContacts = opportunityChannelContacts.filter(c => c.source !== getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText) || (c.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText) && updatedAccounts.some(a => a.id === c.opportunityAccountId)));

        let newAccounts = selectedAccounts.filter(ac => !opportunityAccounts.find((opp) => opp.accounts && opp.accounts[0]?.accountId === ac?.accountId))
            .map((acc) => getNewOpportunityAccount(acc.partnerType, acc));
        let newContacts = newAccounts.map((acc) => getNewOpportunityChannelContact(acc?.id));

        internalPricingContextDispatch(
            {
                type: CaseActionTypes.channelAccountContactChanged,
                opportunityChannel:
                {
                    opportunityAccounts: [...updatedAccounts, ...newAccounts, ...opportunityAccounts.filter(a => a.partnerType === PartnerType.MsStore)],
                    channelContacts: [...updatedContacts, ...newContacts]
                }
            });

        if (opportunityAccounts.find(t => (t.partnerType === PartnerType.ADR || t.partnerType === PartnerType.DMP)) !== null) {
            const pivotTabsData = internalPricingContextState.pivotTabs;
            let pivotTabChannel = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)) || {};
            pivotTabChannel?.errors?.delete(StatePropertiesText.ValidatePrimaryResellers);
            internalPricingContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
        }
    }

    //callback method to add new ADDs from Panel
    const onPrimaryResellerAdd = (selectedItems: any[]): void => {
        if (selectedItems) {
            const selectedData = [...selectedItems];
            selectedData.forEach(t => t.action = (t.action === ActionType.PanelAdd) ? ActionType.Add : t.action)
            setSelectedResellers(selectedData);
            addAccounts([...selectedData]);
        }
    }

    // Get Email Contacts TextField ErrorMessage.
    const getEmailContactErrorMessage = (targetPropertyId?: string) => (value: string): string => {
        return validateContactErrorMessage(value, targetPropertyId)
    };

    const validateContactErrorMessage = (value: string, targetPropertyId?: string): string => {
        let message = '';
        if (!value) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "RequiredResellerEmail", ErrorMessages.RequiredResellerEmail);
        }
        else if (!isValidEmailWithSeperator(value, ";")) {
            message = getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidResellerEmail", ErrorMessages.InvalidResellerEmail);
        }

        if (!JavaScriptExtensions.isEmptyOrNullOrUndefined(targetPropertyId)) {
            setErrorsDataIntoContext(message, targetPropertyId || "");
        }
        return message;
    }

    const setErrorsDataIntoContext = (message: string, targetPropertyId: string) => {
        const pivotTabsData = internalPricingContextState.pivotTabs;
        var pivotTab = pivotTabsData?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(message)) {
            pivotTab?.errors?.delete(targetPropertyId);
        }
        else {
            pivotTab?.errors?.set(targetPropertyId, message);
        }
        internalPricingContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData })
    }

    //Update IOpportunityChannelContact
    function updateChannelContact(contacts: IOpportunityChannelContact[], id?: string, email?: string, isMSStore?: boolean) {
        let updatedContact = contacts.find(c => c.opportunityAccountId === id);
        email = email?.replace(',', ';');
        if (updatedContact) {
            updatedContact.channelContactEmails = email?.replace(',', ';');
            updatedContact.isComplete = validateContactErrorMessage(email || '', StatePropertiesText.ValidateEmailText + id) === '';
        }
    }

    // MsStore ChoiceGroup change event handler
    const onMSStoreChannelChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option && option.key) {
            setMsStoreSelection(option.key);
            let msStoreAccount = opportunityAccounts && opportunityAccounts.find(a => a.partnerType === PartnerType.MsStore) as IOpportunityAccount;

            if (option.key === UIControlsText.YesText) {
                if (!msStoreAccount && endCustomerCountry) {
                    msStoreAccount =
                    {
                        id: Guid.newGuid(),
                        countryId: endCustomerCountry.countryId,
                        partnerType: PartnerType.MsStore,
                        isAllSelected: AccountAllType.None,
                        isComplete: true,
                        action: getParentAccountAction(endCustomerCountry?.countryId, PartnerType.MsStore)
                    };

                    let accounts = internalPricingContextState.opportunityAccounts?.filter(acc => acc.partnerType !== PartnerType.MsStore) || [];
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: [...accounts, msStoreAccount] });
                }
            } else {
                if (msStoreAccount) {
                    let accounts = internalPricingContextState.opportunityAccounts?.filter(acc => acc.id !== msStoreAccount.id) || [];
                    internalPricingContextDispatch({ type: CaseActionTypes.channelAccountChanged, opportunityAccounts: accounts });
                }
            }
        }
    }

    // Function to evaluate if OTLE MSStore Option is enabled/disabled.
    const isOtleMSStoreDisabled = (): boolean => {
        var isMSStoreDisabled = true;
        if (endCustomerCountry && ((internalPricingContextState.pageMode === PageMode.Edit
            && internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft)
            || internalPricingContextState.pageMode === PageMode.Create)) {
            isMSStoreDisabled = !endCustomerCountry.isMsStore;
        }
        else if (endCustomerCountry && internalPricingContextState.pageMode === PageMode.Edit
            && internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length
            && (internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Approved
                || internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Incomplete)) {
            if (internalPricingContextState.opportunityAccounts.filter(x => x.partnerType === PartnerType.MsStore && x.action !== ActionType.Add).length > 0) {
                isMSStoreDisabled = true;
            }
            else {
                isMSStoreDisabled = !endCustomerCountry.isMsStore;

            }
        }

        return isMSStoreDisabled;
    }

    const channelData = {
        id: 'DocCardContainerForReseller',
        baseState: internalPricingContextState,
        name: "Reseller Details",
        iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellersIcon", UIControlsText.ResellersIcon),
        visible: !(showChannelContentBasedOnRoleType?.MSStore) && !isResellerLoading && showChannelContentBasedOnRoleType?.PrimaryResellersOnly,
        className: "otle-reseller",
        components: [
            {
                type: 'DialogComponent',
                data: {
                    id: 'invalidResellerDialog',
                    hidden: hideInvalidResellerDialog,
                    isBlocking: true,
                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "UnavailableChannelPartner", ErrorMessages.UnavailableChannelPartner),
                    subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidResellerAccounts", ErrorMessages.InvalidResellerAccounts),
                    DialogContent: invalidResellers,
                    onDismiss: () => {
                        setHideInvalidResellerDialog(true);
                        setInvalidResellers("");
                    },
                }
            },
            {
                type: 'Stack',
                data: {
                    id: 'StackReseller',
                    visible: true,
                    styles: stackItemStyles,
                    align: "auto",
                    tokens: smallAlignmentTokens,
                    wrap: true,
                    components: [
                        {
                            type: 'Stack',
                            data: {
                                id: 'StackItemForAddChannelRoleBasedContentForReseller',
                                vertical: true,
                                wrap: true,
                                visible: true,
                                components: [
                                    {
                                        type: "StackItem",
                                        data: {
                                            id: UIConfigIdentifierConstants.PrimaryResellerContainerId,
                                            visible: true,
                                            components: [
                                                {
                                                    type: "CustomViewModeComponent",
                                                    data: {
                                                        id: "LabelResellerInformation",
                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerInformation", UIControlsText.ResellerInformation),
                                                        styles: PageStyles.sectionHeading,
                                                    }
                                                },
                                                {
                                                    type: "GuidanceTextInformation",
                                                    data: {
                                                        id: UIConfigIdentifierConstants.GuidanceTextForResellerId,
                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "GuidanceTextForReseller", UIControlsText.GuidanceTextForReseller),
                                                        styles: PageStyles.textFieldStylesWidth146,
                                                        visible: true
                                                    }
                                                },
                                                {
                                                    type: "ChoiceGroup",
                                                    data: {
                                                        id: "MSStoreChannel",
                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MsStoreLabeltext", UIControlsText.MsStoreLabeltext),
                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MsStoreLabeltext", UIControlsText.MsStoreLabeltext),
                                                        styles: DealDetailsStyles.horizontalChoiceGroup,
                                                        options: [{ key: UIControlsText.YesText, text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText) }, { key: UIControlsText.NoText, text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NoText", UIControlsText.NoText), styles: { field: DealDetailsStyles.marginLeft10 } }],
                                                        required: true,
                                                        defaultSelectedKey: msStoreSelection,
                                                        selectedKey: msStoreSelection,
                                                        onChange: onMSStoreChannelChange,
                                                        disabled: isOtleMSStoreDisabled()
                                                    }
                                                },
                                                {
                                                    type: "PanelTableContainer",
                                                    data: {
                                                        invokePanelProps: {
                                                            iconProps: {
                                                                iconName: CssClassConstants.AddItemIconName,
                                                                ariaLabel: CssClassConstants.AddIconName
                                                            },
                                                            buttonStyles: PageStyles.buttonStylesLong,
                                                            buttonText: showChannelContentBasedOnRoleType?.PrimaryResellersOnly ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddResellerText", UIControlsText.AddResellerText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddPrimaryResellerText", UIControlsText.AddPrimaryResellerText),
                                                            onButtonClick: () => onPanelClick('reseller'),
                                                            disabled: getFieldConfigByState(internalPricingContextState, "PrimaryResellerContainer", FieldMapMode.Disable)
                                                        },
                                                        messageBar: {
                                                            messageBarType: MessageBarType.error,
                                                            isMultiline: false,
                                                            dismissButtonAriaLabel: "Close"
                                                        },
                                                        panelType: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Reseller", UIControlsText.Reseller),
                                                        errorMessage: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ResellerNameErrorMessage", UIControlsText.ResellerNameErrorMessage) || '',
                                                        headerText: GridType.ResellersList,
                                                        sortingDataType: 'number',
                                                        propertyName: 'organizationId',
                                                        onItemSelection: onResellerChange,
                                                        onClearAll: onClearAll,
                                                        tableConfigurationFields: resellerTableConfigurations,
                                                        selectionType: GridSelectionType.Mulitple,
                                                        columnDefinitions: resellerColumnDefinitions,
                                                        tableContent: resellers && resellers.current && resellers.current.length > 0 && resellers.current.map((pt: any) => ({
                                                            key: `${pt.accountId}-${pt.partnerType}`, id: pt.accountId, name: pt.companyName, data: partnerTypeValues[`${pt.partnerType}`], partnerType: pt.partnerType, country: pt.countryName, countryId: pt.countryId, organizationId: pt.organizationId,
                                                            editMode: false
                                                        })) || [],
                                                        countrySelected: selectedResellerCountry,
                                                        partnerTypeSelected: selectedResellerType,
                                                        selectedItems: selectedResellers && selectedResellers.map((x: any) => ({
                                                            key: `${x.accountId}-${x.partnerType}`, id: x.accountId, name: x.companyName, data: partnerTypeValues[`${x.partnerType}`], partnerType: x.partnerType, country: x.countryName, countryId: x.countryId, organizationId: x.organizationId, action: x.action,
                                                            editMode: isEditableRow(selectedResellerCountry, internalPricingContextState?.pageMode, currentDealStatusCode, x, x.partnerType)
                                                        } as any)) || [],
                                                        disableSelectAllSelection: false,
                                                        primaryButtonProps: {
                                                            ariaLabel: 'AddReseller',
                                                            text: showChannelContentBasedOnRoleType?.PrimaryResellersOnly ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddResellerText", UIControlsText.AddResellerText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddPrimaryResellerText", UIControlsText.AddPrimaryResellerText),
                                                            onClick: () => onAddButtonClicked(),
                                                            style: PageStyles.panelButtonStyle,
                                                        },
                                                        onDefaultButtonClick: onClearAll,
                                                        footerTextField: `${selectedResellers && selectedResellers.length} Resellers selected`,
                                                    }
                                                },
                                                {
                                                    type: 'StackItem',
                                                    visible: !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.ValidatePrimaryResellers)),
                                                    data: {
                                                        align: "auto",
                                                        components: [
                                                            {
                                                                type: "InnerHTMLContainer",
                                                                data: {
                                                                    contolType: UIControlType.Label,
                                                                    id: "ValidatePrimaryResellerError",
                                                                    value: pivotTab?.errors?.get(StatePropertiesText.ValidatePrimaryResellers),
                                                                    className: 'ms-LabelValueInvalid',
                                                                    styles: DefaultPageStyles.customWidthStyleAuto
                                                                }
                                                            },
                                                        ]
                                                    }
                                                },
                                                {
                                                    type: "DetailListEditableGrid",
                                                    data: {
                                                        id: "DetailsListGridForPrimaryReseller",
                                                        key: "DetailsListGridForPrimaryReseller",
                                                        styles: { root: { height: "100%" } },
                                                        items: primaryResellerGridItems,
                                                        columns: attachPrimaryResellerCallbacks(PrimaryResellerGridColumnConfig),
                                                        gridConfigOptions: {
                                                            enableCellEdit: internalPricingContextState?.pageMode == PageMode.Create,
                                                        },
                                                        enableDefaultEditMode: internalPricingContextState?.pageMode == PageMode.Create,
                                                        height: "200px",
                                                        width: "100%",
                                                        visible: primaryResellerGridItems?.length !== 0,
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    };

    return (
        <div
            data-nextgen-parent-group={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)}
            data-nextgen-parentid={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)}
            className="otle-channel">
            <Stack horizontal {...defaultColumnProps} styles={PageStyles.labelStyles}>
                <CustomTileCardComponent {...channelData} />
            </Stack>
        </div>
    );
}