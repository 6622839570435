import { IOpportunityDealDetails } from "../../../models/IOpportunityDealDetails";
import { IUserRoleDetail } from "../../../models/IUserRoleDetail";
import { getDealDurationConfig, getDealVolumes, getDistinctUserRole, getLoggedinUserRoleDetails, getUserRole, isNullOrEmpty } from "../common/util";
import { IActionButtonConfig } from "./IActionButtonConfig";
import ActionMenuConfig from "./action-menu-config.json";
import { IActionMenuConfig } from "../../../models/IActionMenuConfig";
import { IKendoGridProps } from "../grid/IKendoGridProps";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { IActionMenuPermissions } from "../../../models/IActionMenuPermissons";
import { CaseActions } from "./case-actions";
import { DealType } from "../../../models/DealType";
import { OpportunityDealPortalEditStatusNames } from "../../../models/OpportunityDealPortalEditStatusNames";
import { ApplicationConstants } from "../../../models/ApplicationConstants";

export function enableActionMenu(opportunityDeal: IOpportunityDealDetails) {
    const config: IActionMenuConfig | undefined = readActionMenuConfig(opportunityDeal.dealType.name || "");
    const userRoles = getUserRole();
    const userRoleDetails: IUserRoleDetail[] = getLoggedinUserRoleDetails();
    const actionsConfig: IActionButtonConfig =
    {
        enableEdit: false,
        enableExpire: false,
        enableExtend: false,
        enableRenew: false,
        enableCopy: false,
        enableDelete: false,
        enableCPStrategicLetters: false,
        enableCPCaseDecisionLetter: false,
        enableRevert: false,
        showCPCaseDecisionLetter: true,
        showCPStrategicLetters: true
    };

    const distinctUserRole = getDistinctUserRole(userRoleDetails);
    opportunityDeal.userType = JavaScriptExtensions.isNullOrUndfinedOrEmpty(opportunityDeal.userType) ? [] : opportunityDeal.userType;
    actionsConfig.enableEdit = enableAction(CaseActions.Edit, config?.allowCaseEdit, opportunityDeal, userRoles);
    actionsConfig.enableExpire = enableAction(CaseActions.Expire, config?.allowCaseExpire, opportunityDeal, userRoles);
    actionsConfig.enableExtend = enableAction(CaseActions.Extend, config?.allowCaseExtend, opportunityDeal, userRoles);
    actionsConfig.enableRenew = enableAction(CaseActions.Renew, config?.allowCaseRenew, opportunityDeal, userRoles);
    actionsConfig.enableCopy = enableAction(CaseActions.Copy, config?.allowCaseCopy, opportunityDeal, userRoles);
    actionsConfig.enableDelete = enableAction(CaseActions.Delete, config?.allowCaseDelete, opportunityDeal, userRoles);

    actionsConfig.enableCPStrategicLetters = enableCPStrategicLettersAction(config, opportunityDeal, userRoles);
    actionsConfig.enableCPCaseDecisionLetter = enableCPCaseDecisionLetter(config, opportunityDeal, userRoles);
    actionsConfig.enableRevert = enableAction(CaseActions.Revert, config?.allowCaseRevert, opportunityDeal, userRoles);

    return actionsConfig;
}

export function readActionMenuConfig(dealType: string): IActionMenuConfig | undefined {
    const gridConfigs = ActionMenuConfig.map((item: IActionMenuConfig) => {
        return {
            dealType: item.dealType,
            allowCaseView: item.allowCaseView,
            allowAddCaseToDashboard: item.allowAddCaseToDashboard,
            allowCaseEdit: item.allowCaseEdit,
            allowCaseRevert: item.allowCaseRevert,
            allowCaseDelete: item.allowCaseDelete,
            allowCaseExpire: item.allowCaseExpire,
            allowCaseLetters: item.allowCaseLetters,
            allowCaseDecisionLetter: item.allowCaseDecisionLetter,
            allowCaseCopy: item.allowCaseCopy,
            allowCaseExtend: item.allowCaseExtend,
            allowCaseRenew: item.allowCaseRenew,
        } as IActionMenuConfig
    });

    return gridConfigs.find((item: IActionMenuConfig) => item.dealType.toLowerCase() === dealType.toLowerCase());
}

function enableAction(caseAction: CaseActions, config: IActionMenuPermissions | undefined, opportunityDeal: IOpportunityDealDetails, userRoles: string): boolean {

    if (config?.userRoles?.some((r: any) => userRoles.includes(r)) &&
        config?.statusValues?.indexOf(opportunityDeal.statusCodeName) !== -1 &&
        config?.userType?.some((u: any) => opportunityDeal.userType?.includes(u))) {
            let enable = true
            if (caseAction === CaseActions.Renew) {
                return enable && validateRenew(opportunityDeal)
            }   

            if (caseAction === CaseActions.Revert) {
                return enable && opportunityDeal.isRevertEligible
            }

            if (caseAction === CaseActions.Edit) {
                return enable && validateEdit(opportunityDeal)
            }

            if (caseAction === CaseActions.Copy) {
                let cutoffDate = new Date();
                cutoffDate.setHours(0, 0, 0, 0);
                cutoffDate.setMonth(cutoffDate.getMonth() - ApplicationConstants.CaseCopyCutOffInMonths);

                if (opportunityDeal?.expirationDate !== undefined && opportunityDeal?.expirationDate < cutoffDate) {
                    return false;
                }
            }

        return true;
    }

    return false;
}

function validateRenew(opportunityDeal: IOpportunityDealDetails): boolean {
    if (opportunityDeal == undefined) {
        return false
    }

    if (!opportunityDeal.isRenewalEligible) {
        return false
    }
    
    let currentDate = new Date()
    let dealUnitsTransacted = opportunityDeal.unitsTransactedHardware
    let dealUnitsCommitted = opportunityDeal.dealVolume

    let caseStartDate = new Date()
    if (opportunityDeal?.caseStartDate !== undefined) {
        caseStartDate = new Date(opportunityDeal?.caseStartDate)
    }

    let dealExpirationDate = opportunityDeal.expirationDate
    if (dealExpirationDate === undefined) {
        return false
    }

    let dealDurations = getDealDurationConfig(opportunityDeal?.dealType?.name || "", opportunityDeal?.customerCountry)
    if (isNullOrEmpty(dealDurations)) {
        return false
    }

    let renewalEligibleWindow = dealDurations?.renewalEligibleWindowInDays || 0
    let maxCaseDuration = dealDurations?.maximumDurationInMonths || 0;

    let maxExpirationDate = new Date(caseStartDate)
    maxExpirationDate.setMonth(caseStartDate.getMonth() + maxCaseDuration);

    let diffInMs = Math.abs(maxExpirationDate.valueOf() - currentDate.valueOf())
    diffInMs = diffInMs / (1000 * 60 * 60 * 24)
    if (diffInMs > renewalEligibleWindow) {
        return false
    }

    let dealVolumes = getDealVolumes(opportunityDeal?.dealType?.name || "", opportunityDeal?.productType, opportunityDeal?.dealVertical, opportunityDeal?.customerCountry)
    if (isNullOrEmpty(dealVolumes)) {
        return false
    }

    let unitsTransactedThreshold = dealVolumes?.renewalMinimumUnitsTransacted || 0
    if (opportunityDeal.dealType?.name === DealType.DealRegistration) {
        if (dealUnitsTransacted && dealUnitsTransacted < unitsTransactedThreshold) {
            return false
        }
    }

    if (opportunityDeal.dealType.name === DealType.SpecialPricing) {
        if (dealUnitsTransacted && dealUnitsTransacted < (unitsTransactedThreshold * dealUnitsCommitted / 100)) {
            return false
        }
    }
    
    return true
}

function validateEdit(opportunityDeal: IOpportunityDealDetails): boolean {
    if (opportunityDeal === undefined || (opportunityDeal.closureDecisionString === OpportunityDealPortalEditStatusNames.EditPendingReview)) {
            return false;        
    }
    
    return true;
}

function enableCPStrategicLettersAction(config: IActionMenuConfig | undefined, opportunityDeal: IOpportunityDealDetails, userRoles: string): boolean {

    if (config?.allowCaseLetters?.userRoles?.some((r: any) => userRoles.includes(r)) &&
        config?.allowCaseLetters?.statusValues?.indexOf(opportunityDeal.statusCodeName) !== -1) {
        return true;
    }

    return false;
}

function enableCPCaseDecisionLetter(config: IActionMenuConfig | undefined, opportunityDeal: IOpportunityDealDetails, userRoles: string): boolean {

    if (config?.allowCaseDecisionLetter?.userRoles?.some((r: any) => userRoles.includes(r)) &&
        (config?.allowCaseDecisionLetter?.statusValues?.indexOf(opportunityDeal.statusCodeName) !== -1)) {
        return true;
    }

    return false;
}

export const manufactureConfirmatonLetterVisible = (config: IKendoGridProps | undefined, userRoleDetails: IUserRoleDetail[]) => {
    return JavaScriptExtensions.isNullOrUndfinedOrEmpty(config?.actionTypes?.showManufactureConfirmationLetter) ? false : config?.actionTypes?.showManufactureConfirmationLetter
}