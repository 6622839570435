import { AppConfigurationClient, AppConfigurationClientOptions, featureFlagPrefix, GetConfigurationSettingResponse, isFeatureFlag, parseFeatureFlag } from "@azure/app-configuration";
import { isNumber } from "lodash";
import { FeatureFlagsFilters } from "./models/FeatureFlagsFilters";
import { IConfigurationSettingOptions } from "./models/IConfigurationSettingOptions";

/**
 * AzureAppConfiguration.
 * @class
 */
export class AzureAppConfiguration {
    /**
    * AppConfigurationClient
    * @type {AppConfigurationClient}
   */
    private appConfigurationClient: AppConfigurationClient

    /**
     * Initializes a new instance of the NextGenAppConfiguration class.
     * @param connectionString - Connection string needed for a client to connect to Azure.
     * @param options - Options for the AppConfigurationClient.
     */
    constructor(connectionString: string, options?: AppConfigurationClientOptions) {
        this.appConfigurationClient = new AppConfigurationClient(connectionString, options);
    }

    /**
     * Gets a setting from the Azure App Configuration service.
     *
     * Example code:
     * ```ts
     * const setting = await client.getConfigurationSetting(["MyKey"]);
     * ```
     * @param keys - The list of keys of the configuration setting to get.
     * @param options - Optional parameters for the request.
     */
    public async getConfigurationSetting(keys: string[], options?: IConfigurationSettingOptions): Promise<GetConfigurationSettingResponse[]> {
        if (keys && keys.length > 0) {
            return await Promise.all(
                keys.map((key: string) =>
                    this.appConfigurationClient.getConfigurationSetting({
                        key: key
                    }, options).catch(x => Promise.resolve({} as GetConfigurationSettingResponse))
                )
            );
        }
        else {
            return Promise.reject("Please provide at least one key.")
        }
    }

    /**
     * Gets the feature flag from  the Azure App Configuration service.
     *
     * Example code:
     * ```ts
     * const setting = await client.getFeatureFlags(["MyKey"]);
     * ```
     * @param keys - The list of keys of the configuration setting to get.
     * @param options - Optional parameters for the request.
     */
    public async getFeatureFlags(keys: string[], options?: IConfigurationSettingOptions): Promise<{ [key: string]: Boolean }> {
        var featureFlags: { [key: string]: Boolean } = {};
        if (keys && keys.length > 0) {

            if (!options) {
                options = { onlyIfChanged: true }
            }

            // Fetch all configs for all keys in parallel
            let response = await Promise.all(
                keys.map((key: string) =>
                    this.appConfigurationClient.getConfigurationSetting({
                        key: featureFlagPrefix + key
                    }, options).catch(x => Promise.resolve({} as GetConfigurationSettingResponse)) 
                )
            );

            // parse all the responses and map results to respective key.
            response.forEach(setting => {
                if (isFeatureFlag(setting)) {
                    const parsedFeatureFlag = parseFeatureFlag(setting);
                    let key = parsedFeatureFlag && parsedFeatureFlag.value && keys.find(x => x === parsedFeatureFlag.value.id) || "";

                    // if feature is enabled
                    if (key && parsedFeatureFlag.value.enabled) {
                        let clientFilters = parsedFeatureFlag.value.conditions.clientFilters;
                        let percentageFilter = clientFilters && clientFilters.find(x => x.name === FeatureFlagsFilters.Percentage);
                        if (percentageFilter && percentageFilter.parameters
                            && isNumber(percentageFilter.parameters["Value"])
                            && this.isTargeted(percentageFilter.parameters["Value"])) {
                            featureFlags[key] = true;
                            return;
                        }

                        let targetingFilter = clientFilters && clientFilters.find(x => x.name === FeatureFlagsFilters.Targeting);
                        if (targetingFilter && this.isTargetingClientFilter(targetingFilter) && options?.targetingContext) {
                            let targetingContext = options?.targetingContext;
                            // Check if the user is being targeted directly
                            let users = targetingFilter.parameters["Audience"]["Users"];
                            if (targetingContext.userEmailId && users?.length
                                && users.some(x => x.toLocaleLowerCase() === targetingContext.userEmailId?.toLocaleLowerCase())) {
                                featureFlags[key] = true;
                                return;
                            }

                            // Check if the user is in a group that is being targeted
                            let groups = targetingFilter.parameters["Audience"]["Groups"];
                            if (targetingContext.groups?.length && groups?.length) {
                                targetingContext.groups.forEach(group => {
                                    let groupRollout = groups.find(x => x.Name.toLocaleLowerCase() === group.toLocaleLowerCase());
                                    if (groupRollout && this.isTargeted(groupRollout.RolloutPercentage)) {
                                        featureFlags[key] = true;
                                        return;
                                    }
                                });
                                return;
                            }

                            // Check if the user is being targeted by a default rollout percentage
                            if (this.isTargeted(targetingFilter.parameters["Audience"]["DefaultRolloutPercentage"])) {
                                featureFlags[key] = true;
                                return;
                            }
                        }
                    }
                }
            }
            );
        }

        return featureFlags;
    }

    /**
   * Performs a percentage based evaluation
   */
    public isTargeted(percentage: number): boolean {
        return percentage && percentage > 0 && Math.floor(Math.random() * 100) < percentage || false;
    }

    /**
   * typeguard - for targeting client filter
   */
    public isTargetingClientFilter(clientFilter: any): clientFilter is {
        parameters: {
            Audience: {
                Groups: Array<{ Name: string; RolloutPercentage: number }>;
                Users: Array<string>;
                DefaultRolloutPercentage: number;
            };
        };
    } {
        return (
            clientFilter.name === FeatureFlagsFilters.Targeting &&
            clientFilter.parameters &&
            clientFilter.parameters["Audience"] &&
            Array.isArray(clientFilter.parameters["Audience"]["Groups"]) &&
            Array.isArray(clientFilter.parameters["Audience"]["Users"]) &&
            typeof clientFilter.parameters["Audience"]["DefaultRolloutPercentage"] === "number"
        );
    }
}