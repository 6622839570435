/**
 * Enum for SaveOpportunityStatusCodes.
 * @readonly
 * @enum {number}
 */
export enum OpportunityDealStatusCodes {

    /**
     * Draft value.
     */
    Draft = 441820000,

    /**
     * Cancelled value.
     */
    Cancelled = 100000012,

    /**
     * Forced Expire value.
     */
    ForcedExpire = 441820002,

    /**
    * Approved value.
    */
    Approved = 100000011,

    /**
    * DealPricingApprovedPendingChannelStatus value.
    */
    DealPricingApprovedPendingChannelStatus = 441820001,

    /**
    * Denied value.
    */
    Denied = 441820004,

    /**
    * In Progress value.
    */
    InProgress  = 100000000,

    /**
    * Unassigned value.
    */
    Unassigned  = 100000008,

    /**
    * Expired value.
    */
    Expired  = 100000013,

    /**
    * Need More Info.
    */    
    NeedMoreInfo = 100000005,

    /**
    * InComplete.
    */    
    Incomplete = 552590000,  
    
    /**
    * DraftRenew.
    */    
    DraftRenew = 100,

    /**
    * InsufficientInformation.
    */   
    InsufficientInformation = 100000009,
}