/**
 * PropertyText for State.
 * @constant
 */
export const StatePropertiesText = {
  DealNameText: "dealName",
  DealOverviewText: "dealOverview",
  DealVerticalText: "dealVertical",
  PreSalesActivitiesText: "preSalesActivity",
  DealProductGroupText: "productGroup",
  DeviceShipToAddressText: "address1",
  DeviceShipToCityText: "city",
  DeviceShipToStateProvinceText: "stateOrProvince",
  DeviceShipToPostalCodeText: "postalCode",
  DeviceShipToCountryText: "country",
  JustificationText: "expirationDateJustification",
  EmailCCNotificationRecipients: "emailCCNotificationRecipients",
  DealVolumeText: "dealVolume",
  UnitsTransactedText: "unitsTransacted",
  CustomerDomain: "dealCustomerDomain",
  PartnerAddress: "address",
  PartnerCity: "city",
  PartnerState: "stateProvince",
  PartnerSellerName: "accountCompanyName",
  PartnerSellerEmail: "email",
  RfpJustification : "rfpJustification",
  MSStoreContactEmail:  "msStoreContactEmail",
  ValidatePrimaryResellers: "ValidatePrimaryResellers",
  ValidateMultipleDistributors: "ValidateMultipleDistributors",
  ValidateCustomer: "ValidateCustomer",
  ValidateProduct: "ValidateProduct",
  ValidateEmailText: "Email_",
  ValidateFinalOrAdditionalReseller: "ValidateFinalOrAdditionalReseller",
  SavedDealVolume: "savedDealVolume",
  DealStatusCode: "statusCode"
}
