/* eslint-disable react-hooks/exhaustive-deps */
import { CoherenceDialog, CoherenceDialogFooter } from "@cseo/controls";
import { DefaultButton, DialogType, PrimaryButton, Stack, StackItem } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UIControlsText } from "../../../../content/UIControlsText";
import { useInternalPricingContext } from "../context/internalpricing-context";
import { PageStyles } from "../../common/content/Page.Styles";
import { IInternalPricingProgramCommandsProps } from "../props/IInternalPricingProgramCommandsProps";
import { CaseActionTypes } from "../../common/reducer/CaseActionTypes";
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { nextGenService } from "../../../../services/NextGenService";
import { AffiliationType } from "../../../../models/AffiliationType";
import { ChannelStructure } from "../../../../models/ChannelStructure";
import { ErrorCode } from "../../../../models/ErrorCode";
import { isFieldDisabled, isNullOrEmpty, isFieldVisible, getsavedContext, getUserSession, getRuleValidationMessage, getEntityDataFromSession, isDateNotWithinThreshold, getDealType } from "../../../../components/shared/common/util";
import { PageMode } from "../../../../models/PageMode";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { ILegalEntity } from "../../../../models/ILegalEntity";
import { CustomerType } from "../../../../models/CustomerType";
import { ErrorMessages } from "../../../../content/ErrorMessages";
import { ActionType } from "../../../../models/ActionType";
import { IOpportunityAccount } from "../../../../models/IOpportunityAccount";
import { OpportunityDealClosureDecisionType } from "../../../../models/OpportunityDealClosureDecisionType";
import { PartnerType } from "../../../../models/PartnerType";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { Guid } from "../../../../infrastructure/Guid";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { DealType } from "../../../../models/DealType";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import { PageConstants } from "../../../pageconstants/Constants";
import { IDealType } from "../../../../models/IDealType";

/**
 * CP Strategic Deal Command Component.
 * @function component
 */
const InternalPricingCommands: React.FunctionComponent<IInternalPricingProgramCommandsProps> = (props: IInternalPricingProgramCommandsProps) => {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [hideCancelDialog, setHideCancelDialog] = useState<boolean>(true);
    const { internalPricingContextState, internalPricingContextDispatch } = useInternalPricingContext();
    const history = useHistory();
    const [isDraftSaving, setIsDraftSaving] = useState(false);
    const [isCaseSaving, setIsCaseSaving] = useState(false);
    const [hideErrorDialog, setHideErrorDialog] = useState<boolean>(true);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string>("");
    const [saveDraftHidden, setSaveDraftHidden] = useState<boolean>(false);
    const [hideOwnerDialog, setHideOwnerDialog] = useState<boolean>(true);
    const dealType = (history && history?.location && history?.location?.pathname.indexOf(PageConstants.OTLEDealtype) > 0 ? PageConstants.OTLEDealtype : PageConstants.CPStrategicDealtype) || "";

    /**
     * Save Draft button click event handler.
     * @method
     */
    const saveDraft = (): void => {
        props.saveDraftClickHandler(true)
        internalPricingContextDispatch({ type: CaseActionTypes.saveDraft, boolValue: true });
    }

    /**
     * Submit button click event handler.
     * @method
     */
    const submitCase = (): void => {
        props.saveDraftClickHandler(false)

        if (isCaseComplete()) {
            internalPricingContextDispatch({ type: CaseActionTypes.submitCase, boolValue: true });
        }
    }

    /**
     * Submit Async button click event handler.
     * @method
     */
    const submitCaseAsync = (): void => {
        if (isCaseComplete()) {
            dispatchBDReviewNeeded();
            internalPricingContextDispatch({ type: CaseActionTypes.submitCaseAsync, boolValue: true });
        }
    }

    /**
     * Using Derived state checks if Submit button is diabled.
     * @function
     * @returns {boolen}
     */
    const isCaseComplete = (): boolean | undefined => {
        let missingTabs: string[] = new Array<string>();
        let validationErrors: string[] = [];
        if (!internalPricingContextState || !internalPricingContextState.opportunityDeals
            || internalPricingContextState.opportunityDeals.length === 0) {
            return false;
        }
        if (isDealOverviewTabIncomplete(validationErrors)) {
            missingTabs.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealOverviewText", UIControlsText.DealOverviewText));
        }
        else {
            if (isLegalEntitiesTabIncomplete(validationErrors)) {
                missingTabs.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText));
            }
            if (isChannelTabIncomplete(validationErrors)) {
                missingTabs.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
            }
            if (isProductSearchTabIncomplete(validationErrors)) {
                missingTabs.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSelection", UIControlsText.ProductSelection));
            }
            if (isChannelContactTabIncomplete(validationErrors)) {
                let isCpStrategicDeal = internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length
                    && internalPricingContextState.opportunityDeals.some(x => x.dealType && x.dealType.name && x.dealType.name.toLocaleLowerCase().trim() === DealType.CPStrategic.toLocaleLowerCase().trim());
                if (isCpStrategicDeal) {
                    missingTabs.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelContacts", UIControlsText.ChannelContacts))
                }
                else {
                    missingTabs.push(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText));
                }
            }
        }

        if (missingTabs.length > 0) {
            internalPricingContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: missingTabs[0] });
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: missingTabs });
            internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: validationErrors });
            return false;
        }
        else {
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: missingTabs });
            internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [] });
            return true;
        }
    }

    /**
     * Check if all required fields in Deal Overview tab are complete.
     * @function
     * @returns {boolen}
     */
    const isDealOverviewTabIncomplete = (errors: string[]): boolean | undefined => {
        let isIncomplete = false;
        let dealCustomers = internalPricingContextState.opportunityDeals[0].dealCustomers?.filter(c => c.action?.toLowerCase() !== ActionType.Delete.toLowerCase());
        let isCpStrategicDeal = internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length
            && internalPricingContextState.opportunityDeals.some(x => x.dealType && x.dealType.name && x.dealType.name.toLocaleLowerCase().trim() === DealType.CPStrategic.toLocaleLowerCase().trim());

        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.productGroup)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredProductGroup);
        }

        if (JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.isMultiNational)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealGeoCoverage);
        }
        if (!internalPricingContextState.opportunityDeals[0].geoCountries
            || internalPricingContextState.opportunityDeals[0].geoCountries.length === 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealCountries);
        }
        if (isCpStrategicDeal && internalPricingContextState.isMultiNational && internalPricingContextState.isMultiNational === true &&
            internalPricingContextState.opportunityDeals[0].geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries.filter(c => c.action !== ActionType.Delete).length === 1) {
            isIncomplete = true;
            errors.push(ErrorMessages.MultipleDealCountriesError);
        }
        if (isCpStrategicDeal && JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0].affiliationType)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredAffiliationType);
        }
        if (isCpStrategicDeal && internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.Others
            && JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].affiliationTypeOthers)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredAffiliationTypeOthers);
        }

        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].dealName)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealName);
        }

        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].dealOverview)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealOverview);
        }

        if (JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0].expirationDate)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredExpirationDate);
        }

        if (!isCpStrategicDeal && !JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0]?.expirationDate)) {
            let expirationDate = internalPricingContextState.opportunityDeals[0].expirationDate;
            let internalPricingDealType: IDealType = getDealType(PageConstants.OTLEDealtype);
            let error: string | undefined = isDateNotWithinThreshold(expirationDate?.toString(), internalPricingDealType) || "";
            if (JavaScriptExtensions.isDefined(error) && !isNullOrEmpty(error)) {
                isIncomplete = true;
                errors.push(error);
            }
        }

        if (isCpStrategicDeal && JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].expirationDateJustification)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredExpirationDateJustification);
        }

        if (dealCustomers?.filter(primary => primary.customerType === CustomerType.Primary).length === 1 && (JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].dealVertical) || internalPricingContextState.opportunityDeals[0].dealVertical === Guid.emptyGuid())) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealVertical);
        }

        if (isCpStrategicDeal && internalPricingContextState.opportunityDeals[0].affiliationType !== AffiliationType.AffiliateCompanies &&
            internalPricingContextState.opportunityDeals[0].dealVolume === 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.TotalDealVolume);
        }
        if (internalPricingContextState.opportunityDeals[0].dealContacts && internalPricingContextState.opportunityDeals[0].dealContacts?.length > 0 && internalPricingContextState.opportunityDeals[0].dealContacts?.filter(con => con.action !== ActionType.Delete).filter(con => !con.isComplete).length > 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealContacts);
        }
        if (!isCpStrategicDeal && (!JavaScriptExtensions.isDefined(internalPricingContextState?.attachments) || (internalPricingContextState?.attachments && (internalPricingContextState?.attachments?.length < 0 || internalPricingContextState?.attachments?.filter(att => att?.source !== "Proof of End Customer's Preference").length < 0)))) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredProofOfAttachementException);
        }
        else if (!isCpStrategicDeal
            && (internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Approved || internalPricingContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Incomplete)
            && internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length
            && internalPricingContextState.opportunityAccounts.filter(x => x.action === ActionType.Add).length > 0
            && (internalPricingContextState.attachments && internalPricingContextState.attachments.filter(att => att?.action === ActionType.Add).length === 0
               || internalPricingContextState.attachments && internalPricingContextState.attachments.filter(att => att?.action === ActionType.Add && att?.source === "Proof of End Customer's Preference").length === 0)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredProofOfAttachementException);
        }
        if (!isCpStrategicDeal && (JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0]?.dealVertical))) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredDealVertical);
        }
        if (!isCpStrategicDeal && (JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0]?.dealVolume) || internalPricingContextState.opportunityDeals[0]?.dealVolume == 0)) {
            isIncomplete = true;
            errors.push(ErrorMessages.TotalDealVolume);
        }
    
        if (!isCpStrategicDeal && (JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0]?.dealVolume) || Number(internalPricingContextState.opportunityDeals[0]?.dealVolume) < Number(sessionStorage.getItem(SessionStorageConsts.dealQuantityThresholdConfigs)))) {
            isIncomplete = true;
            errors.push(ErrorMessages.DealVolumeThresholdWarningForOTLE);
        }

        if (!isCpStrategicDeal && (JavaScriptExtensions.isDefined(internalPricingContextState.opportunityChannelContacts))) {
            var dealDetailContacts = internalPricingContextState.opportunityChannelContacts?.filter(p => p.source === "Deal Details" && p.action?.toLowerCase() !== ActionType.Delete.toLowerCase());
            if (dealDetailContacts && dealDetailContacts.some(cc => !cc.isComplete)) {
                errors.push(ErrorMessages.EmailShouldBeMicrosoftDomain)
                isIncomplete = true;
            }
        }
        return isIncomplete;
    }

    /**
     * Check if all required fields in Legal Entities tab are complete.
     * @function
     * @returns {boolen}
     */
    const isLegalEntitiesTabIncomplete = (errors: string[]): boolean | ILegalEntity[] => {
        let isIncomplete = false;
        let isCpStrategicDeal = internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length
            && internalPricingContextState.opportunityDeals.some(x => x.dealType && x.dealType.name && x.dealType.name.toLocaleLowerCase().trim() === DealType.CPStrategic.toLocaleLowerCase().trim());

        if (!internalPricingContextState.opportunityDeals[0].dealCustomers
            || internalPricingContextState.opportunityDeals[0].dealCustomers.length === 0) {
            isIncomplete = true;
            if (internalPricingContextState.opportunityDeals[0].dealType?.name === DealType.OTLE.toString()) {
                errors.push(ErrorMessages.SingleRequiredLegalEntity);
            }
            else {
                errors.push(ErrorMessages.RequiredLegalEntity);
            }
            return isIncomplete;
        }
        var dealCustomers = internalPricingContextState.opportunityDeals[0].dealCustomers.filter(c => c.action?.toLowerCase() !== ActionType.Delete.toLowerCase());
        let distinctGeoCountryCount = [... new Set(internalPricingContextState.opportunityDeals[0]?.geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries.filter(c => c.action !== ActionType.Delete).map(c => c.countryName))].length;
        let distinctLegalEntityCountryCount = [... new Set(dealCustomers.map(c => c.country))].length;

        // Check at least one Legal Entity per each selected Geo Country
        if (distinctGeoCountryCount != distinctLegalEntityCountryCount) {
            isIncomplete = true;
            if (internalPricingContextState.opportunityDeals[0].dealType?.name === DealType.OTLE.toString()) {
                errors.push(ErrorMessages.SingleRequiredLegalEntity);
            }
            else {
                errors.push(ErrorMessages.RequiredLegalEntity);
            }
        }

        if (dealCustomers?.filter(name => JavaScriptExtensions.isEmptyOrNullOrUndefined(name.legalEntityName)).length > 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.ValidLegalEntityName);
        }

        if (dealCustomers?.filter(primary => primary.customerType === CustomerType.Primary).length === 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.PrimaryLegalEntityCustomer);
        }

        if (internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies
            && dealCustomers?.filter(volume => volume.unitsCommitted === null).length > 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.LegalEntityDealVolume);
        }
        if (internalPricingContextState.opportunityDeals[0].affiliationType === AffiliationType.AffiliateCompanies &&
            internalPricingContextState.opportunityDeals[0].dealVolume === 0) {
            isIncomplete = true;
            errors.push(ErrorMessages.TotalDealVolume);
        }
        //customer domain
        if (!isCpStrategicDeal && (!(JavaScriptExtensions.isDefined(internalPricingContextState.opportunityDeals)) ||
            (internalPricingContextState?.opportunityDeals && internalPricingContextState?.opportunityDeals[0]?.dealCustomers && isNullOrEmpty(internalPricingContextState?.opportunityDeals[0]?.dealCustomers[0]?.dealCustomerDomain)))) {
            isIncomplete = true;
            errors.push(ErrorMessages.DealInfoCustomerDomainRequired);
        }
        return isIncomplete;
    }

    const validatePartnerTypeSelection = (opportunityAccounts: any) => {
        const accounts = opportunityAccounts.map((item: any) => ({ countryId: item.countryId, partnerType: item.partnerType }));
        if (accounts && accounts.Length <= 1) {
            return false;
        }
        return !!accounts && accounts.map((item: any) => item.countryId + item.partnerType)
            .some((value: any, index: number, account: any) => account.indexOf(value) !== account.lastIndexOf(value));
    };

    /**
     * Check if all required fields in Channel tab are complete.
     * @function
     * @returns {boolen}
     */
    const isChannelTabIncomplete = (errors: string[]): boolean | undefined => {
        let isIncomplete = false;
        let isCpStrategicDeal = internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals.length
            && internalPricingContextState.opportunityDeals.some(x => x.dealType && x.dealType.name && x.dealType.name.toLocaleLowerCase().trim() === DealType.CPStrategic.toLocaleLowerCase().trim());
        if (isCpStrategicDeal) {
            if (internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length > 1 && validatePartnerTypeSelection(internalPricingContextState.opportunityAccounts)) {
                isIncomplete = true;
                errors.push(ErrorMessages.DuplicateADRErrorText);
            }
            if (JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].channelOverview)) {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredChannelOverview);
            }

            if (JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.opportunityDeals[0].channelStructure)) {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredChannelStructure);
            }

            if (internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Skip && JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].skipChannelReason)) {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredChannelSkip);
            }

            if (internalPricingContextState.opportunityDeals[0].isChannelJustificationRequired === true && JavaScriptExtensions.isEmptyOrNullOrUndefined(internalPricingContextState.opportunityDeals[0].channelJustification)) {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredChannelJustification);
            }

            if ((JavaScriptExtensions.isNullOrUndfinedOrEmpty(internalPricingContextState.attachments)
                || !internalPricingContextState.attachments?.some(t => t?.source === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ChannelText", UIControlsText.ChannelText)))
                && internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer) {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredAttachementException);
            }

            if ((internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Customer || internalPricingContextState.opportunityDeals[0].channelStructure === ChannelStructure.Distributor)
                && internalPricingContextState.opportunityAccounts && internalPricingContextState.opportunityAccounts.length > 0 && internalPricingContextState.opportunityAccounts?.filter(acc => !acc.isComplete).length > 0) {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredChannelPartner);
            }
        }
        else {
            if (internalPricingContextState.opportunityAccounts) {
                if (!internalPricingContextState.opportunityAccounts.some(
                    x => x.partnerType === PartnerType.ADR || x.partnerType === PartnerType.DMP || x.partnerType === PartnerType.MsStore)) {
                    isIncomplete = true;
                    errors.push(ErrorMessages.RequiredResellerPartner);
                }

                if (internalPricingContextState.opportunityAccounts.some(
                    x => (x.partnerType === PartnerType.ADR || x.partnerType === PartnerType.DMP))) {
                    let adrDmpAccounts = internalPricingContextState.opportunityAccounts.filter(
                        x => (x.partnerType === PartnerType.ADR || x.partnerType === PartnerType.DMP));
                    if (adrDmpAccounts && adrDmpAccounts.length
                        && adrDmpAccounts.filter(x => !x.accounts || x.accounts.length === 0).length > 0) {
                        isIncomplete = true;
                        errors.push(ErrorMessages.RequiredResellerPartner);
                    }
                }
            }
            else {
                isIncomplete = true;
                errors.push(ErrorMessages.RequiredResellerPartner);
            }
        }

        return isIncomplete;
    }

    /**
     * Check if all required fields in Product Search tab are complete.
     * @function
     * @returns {boolen}
     */
    const isProductSearchTabIncomplete = (errors: string[]): boolean | undefined => {
        let isIncomplete = false;
        var products = internalPricingContextState.opportunityDeals[0].products?.filter(p => p.action?.toLowerCase() !== ActionType.Delete.toLowerCase());

        if ((!products) || (products && products.length === 0)) {
            isIncomplete = true;
            errors.push(ErrorMessages.RequiredProduct);
        }

        if (products && internalPricingContextState.opportunityDeals[0].dealType?.name?.toLowerCase() === PageConstants.OTLEDealtype) {
            for (let item of products) {
                if (item.quantity == 0) {
                    isIncomplete = true;
                    errors.push(ErrorMessages.RequiredProductQuantity);
                    break;
                }
            }
        }

        let distinctGeoCountryCount = [... new Set(internalPricingContextState.opportunityDeals[0]?.geoCountries && internalPricingContextState?.opportunityDeals[0]?.geoCountries.filter(c => c.action !== ActionType.Delete).map(c => c.countryId))].length;
        let distinctProductCountryCount = [... new Set(products && products.map(p => p.countryId))].length;

        // Check atleast one product for per selected Geo Country
        if (distinctGeoCountryCount != distinctProductCountryCount) {
            isIncomplete = true;
            if (internalPricingContextState.opportunityDeals[0].dealType?.name === DealType.OTLE.toString()) {
                errors.push(ErrorMessages.SingleRequiredProductGeoCountry);
            }
            else {
                errors.push(ErrorMessages.RequiredProductGeoCountry);
            }
        }
        return isIncomplete;
    }

    const isChannelContactTabIncomplete = (errors: string[]): boolean | undefined => {
        let isIncomplete = false;
        var channelContacts = internalPricingContextState.opportunityChannelContacts?.filter(p => p.action?.toLowerCase() !== ActionType.Delete.toLowerCase());

        if (channelContacts && channelContacts.some(cc => !cc.isComplete)) {
            errors.push(ErrorMessages.RequiredChannelContactError)
            isIncomplete = true;
        }
        return isIncomplete;
    }

    useEffect(() => {
        setSaveDraftHidden(internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].statusCode !== OpportunityDealStatusCodes.Draft)
    }, [internalPricingContextState && internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].statusCode])

    /**
    * Check if a deal needs a review by BD when MS Seller edited a approved deal.
    */
    const dispatchBDReviewNeeded = (): void => {
        if (internalPricingContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && (internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Draft && internalPricingContextState.opportunityDeals[0]?.statusCode !== OpportunityDealStatusCodes.Incomplete)) {
            var closureDecision = OpportunityDealClosureDecisionType.Approved;

            var savedData = getsavedContext();
            //validate deal customer changed/added on edit to set review needed flag
            var newCustomers = internalPricingContextState.opportunityDeals[0].dealCustomers?.
                filter(c => c.action?.toLowerCase() === ActionType.Add.toLowerCase() || c.action?.toLowerCase() === ActionType.Update.toLowerCase()).length || 0;

            //validate products added on edit to set review needed flag
            var newProducts = internalPricingContextState.opportunityDeals[0].products?.filter(p => p.action?.toLowerCase() === ActionType.Add.toLowerCase()).length || 0;

            //validate products updated on edit to set review needed flag
            var approvedProductsQuantityChanged = false;
            if (internalPricingContextState.opportunityDeals && internalPricingContextState.opportunityDeals[0].dealType?.name?.toLowerCase() === PageConstants.OTLEDealtype) {
                var updatedProducts = internalPricingContextState.opportunityDeals[0].products?.filter(p => p.action?.toLowerCase() === ActionType.Update.toLowerCase()) || [];

                let savedProducts = getsavedContext().opportunityDeals[0].products || [];

                if (savedProducts && updatedProducts) {
                    let savedProductsQuantity = 0;
                    let updatedProductsQuantity = 0;

                    savedProducts.forEach(item => {
                        if (item.quantity) {
                            savedProductsQuantity = savedProductsQuantity + item.quantity;
                        }
                    });

                    updatedProducts.forEach(item => {
                        if (item.quantity) {
                            updatedProductsQuantity = updatedProductsQuantity + item.quantity;
                        }
                    });

                    if (updatedProductsQuantity > 0 && savedProductsQuantity !== updatedProductsQuantity) {
                        approvedProductsQuantityChanged = true;
                    }
                }

            }

            //validate accounts added on edit to set review needed flag
            var newAccounts = internalPricingContextState.opportunityAccounts?.map((item: IOpportunityAccount) => {
                var addedItems = item.accounts?.filter(a => a.action?.toLowerCase() === ActionType.Add.toLowerCase()) || [];
                if (addedItems && addedItems?.length > 0) {
                    return addedItems;
                }
            }).filter(acc => acc?.length).length || 0;

            //validate new ADDs as part of DMP
            var addedDMPs = 0;
            var newAdds = internalPricingContextState.opportunityAccounts?.filter(acc => acc.partnerType === PartnerType.DMP).filter(pt => pt.accounts)
            newAdds?.map((dmps) => {
                var accounts = dmps?.accounts;
                if (accounts && accounts?.length > 0) {
                    accounts.map((item) => {
                        if (dmps.partnerAccounts && dmps.partnerAccounts[item.accountId]) {
                            var addedLength = dmps.partnerAccounts[item.accountId].filter(pa => pa.action === ActionType.Add).length;
                            if (addedLength > 0) {
                                addedDMPs = addedLength;
                            }
                        }
                    })
                }
            });

            //validate new MsStore added 
            var newMSStore = internalPricingContextState.opportunityAccounts?.filter(acc => acc.partnerType === PartnerType.MsStore && acc.action === ActionType.Add).length || 0;

            //validate attachments on edit to set review needed flag
            var attachements = internalPricingContextState.attachments?.filter(item => item && item.action?.toLowerCase() === ActionType.Add.toLowerCase()).length || 0;

            //validate dealOverview on edit to set review needed flag
            var dealOverviewChanged = internalPricingContextState.opportunityDeals[0].dealOverview !== savedData.opportunityDeals[0].dealOverview;

            //validate expirationDate on edit to set review needed flag
            var expirationDateChanged = internalPricingContextState.opportunityDeals[0].expirationDate !== savedData.opportunityDeals[0].expirationDate;

            //validate channelJustification on edit to set review needed flag
            var channelJustificationChanged = internalPricingContextState.opportunityDeals[0].channelJustification !== savedData.opportunityDeals[0].channelJustification;

            //validate exceptionRequest on edit to set review needed flag
            var exceptionRequestChanged = internalPricingContextState.opportunityDeals[0].exceptionRequest !== savedData.opportunityDeals[0].exceptionRequest;

            //validate channelStructure on edit to set review needed flag
            var channelStructureChanged = internalPricingContextState.opportunityDeals[0].channelStructure !== savedData.opportunityDeals[0].channelStructure;

            //validate channelOverview on edit to set review needed flag
            var channelOverviewChanged = internalPricingContextState.opportunityDeals[0].channelOverview !== savedData.opportunityDeals[0].channelOverview;

            //validate expirationDateJustification on edit to set review needed flag
            var expirationDateJustificationChanged = internalPricingContextState.opportunityDeals[0].expirationDateJustification !== savedData.opportunityDeals[0].expirationDateJustification;

            //validate dealVolume on edit to set review needed flag
            var dealVolumeChanged = internalPricingContextState.opportunityDeals[0].dealVolume !== savedData.opportunityDeals[0].dealVolume;

            if (newCustomers > 0 || newProducts > 0 || newAccounts > 0 || addedDMPs > 0 || newMSStore > 0 || attachements > 0 || dealOverviewChanged || expirationDateChanged || expirationDateJustificationChanged || dealVolumeChanged || channelJustificationChanged || exceptionRequestChanged || channelOverviewChanged || channelStructureChanged || approvedProductsQuantityChanged) {
                closureDecision = OpportunityDealClosureDecisionType.EditPendingReview;
            }
            internalPricingContextDispatch({ type: CaseActionTypes.closureDecisionChanged, closureDecision: { key: internalPricingContextState.opportunityDeals[0].id, value: closureDecision } });
        }
    }

    useEffect(() => {
        (async () => {
            if (internalPricingContextState.submitCaseAsync) {
                // don't save again while we are saving
                if (isCaseSaving) {
                    return
                }

                try {
                    setIsCaseSaving(true);
                    var isSaved = await nextGenService.submitOpportunityAsync(internalPricingContextState);
                    if (isSaved.status === 200) {
                        props.setOpportunityNumber(isSaved.data);
                        props.successPageHandler(true)
                    }
                }
                catch (error: any) {
                    if (error && error.response.status === 422) {
                        resetLegalEntities(error.response);
                    }
                    else {
                        alert('Error' + error);
                    }
                }
                finally {
                    setIsCaseSaving(false)
                    internalPricingContextDispatch({ type: CaseActionTypes.submitCaseAsync, boolValue: false });
                }
            }
        })();

    }, [internalPricingContextState.submitCaseAsync])

    useEffect(() => {
        (async () => {
            if (internalPricingContextState.submitCase) {
                // don't save again while we are saving
                if (isCaseSaving) {
                    return
                }

                try {
                    setIsCaseSaving(true);
                    var isSaved = await nextGenService.submitOpportunity(internalPricingContextState);
                    if (isSaved.status === 200) {
                        props.setOpportunityNumber(isSaved.data);
                        props.successPageHandler(true)
                    }
                }
                catch (error: any) {
                    if (error && error.response.status === 422) {
                        resetLegalEntities(error.response);
                    }
                    else {
                        alert('Error' + error);
                    }
                }
                finally {
                    setIsCaseSaving(false)
                    internalPricingContextDispatch({ type: CaseActionTypes.submitCase, boolValue: false });
                }
            }
        })();

    }, [internalPricingContextState.submitCase])

    useEffect(() => {
        (async () => {
            if (internalPricingContextState.saveDraft) {
                // don't save again while we are saving
                if (isDraftSaving) {
                    return
                }

                try {
                    setIsDraftSaving(true);
                    var isSaved = await nextGenService.saveDraftOpportunity(internalPricingContextState);
                    if (isSaved.status === 200) {
                        props.setOpportunityNumber(isSaved.data);
                        props.successPageHandler(true)
                    }
                }
                catch (error: any) {
                    if (error && error.response.status === 422) {
                        resetLegalEntities(error.response);
                    }
                    else {
                        alert(error);
                    }
                }
                finally {
                    setIsDraftSaving(false)
                    internalPricingContextDispatch({ type: CaseActionTypes.saveDraft, boolValue: false });
                }
            }
        })();

    }, [internalPricingContextState.saveDraft])

    useEffect(() => {
        switch (internalPricingContextState.errorCode) {
            case ErrorCode.InvalidCase:
                setErrorDialogMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InvalidCaseMessage", UIControlsText.InvalidCaseMessage));
                setHideErrorDialog(false);
                break;

            default:
                break;
        }
        if (internalPricingContextState.isNotCaseOwner) {
            setHideOwnerDialog(false);
        }

    }, [internalPricingContextState.errorCode, internalPricingContextState.isNotCaseOwner])

    //resetLegalEntities - Used to reset/refresh the legal entities grid based if API response returns errors.
    const resetLegalEntities = (response: any): void => {
        if (!isNullOrEmpty(response.data.opportunityDeals[0].dealCustomers) && response.data.opportunityDeals[0].dealCustomers.length > 0) {
            let dealCustomers = response.data.opportunityDeals[0].dealCustomers;
            dealCustomers.map((item: ILegalEntity) => { item.unManaged = !item.isManaged });
            internalPricingContextDispatch({ type: CaseActionTypes.selectedLegalEntityChanged, dealCustomers: { key: response.data.opportunityDeals[0].id, value: dealCustomers } });
            internalPricingContextDispatch({ type: CaseActionTypes.missingFieldTab, inputArray: [getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText)] });
            internalPricingContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "LegalEntitiesText", UIControlsText.LegalEntitiesText) });
            if (dealCustomers.some((c: ILegalEntity) => c.errors && c.errors?.indexOf(ErrorMessages.PrimaryLegalEntityCustomer) >= 0)) {
                internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [ErrorMessages.PrimaryLegalEntityCustomer] });
            }
            else {
                internalPricingContextDispatch({ type: CaseActionTypes.validationErrors, inputArray: [ErrorMessages.LegalEntityValidationError] });
            }
        }
    }

    return (
        <Stack>
            <Stack
                horizontal
                styles={PageStyles.commandButtonStyles}
                className="case-command-buttons"
                data-nextgen-parent-group="case-command-buttons"
                data-nextgen-parentid="case-command-buttons">
                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText), internalPricingContextState.pageMode, dealType) &&
                    <StackItem>
                        <PrimaryButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText)}
                            styles={PageStyles.commandButtonPadding}
                            aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText)}
                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitOppButtonTitle", UIControlsText.SubmitOppButtonTitle)}
                            disabled={isCaseSaving ||
                                isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, internalPricingContextState.asyncProcessStatus, dealType)}
                            onClick={submitCaseAsync}
                            allowDisabledFocus={false} />
                    </StackItem>
                }
                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitText", UIControlsText.SubmitText), internalPricingContextState.pageMode, dealType) &&
                    <StackItem>
                        <PrimaryButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitSync", UIControlsText.SubmitSync)}
                            styles={PageStyles.commandButtonPadding}
                            aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitSync", UIControlsText.SubmitSync)}
                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SubmitSyncOppButtonTitle", UIControlsText.SubmitSyncOppButtonTitle)}
                            disabled={isCaseSaving}
                            onClick={submitCase}
                            hidden={!internalPricingContextState.debugMode}
                            allowDisabledFocus={false} />
                    </StackItem>
                }
                {isFieldVisible(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText), internalPricingContextState.pageMode, dealType) &&
                    <StackItem>
                        <DefaultButton
                            text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText)}
                            styles={PageStyles.commandButtonPadding}
                            aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText)}
                            title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveOppDraftButtonTitle", UIControlsText.SaveOppDraftButtonTitle)}
                            disabled={props.areCommandButtonsDisabled() || isDraftSaving ||
                                isFieldDisabled(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SaveDraftText", UIControlsText.SaveDraftText), internalPricingContextState.pageMode, internalPricingContextState.opportunityDeals[0].statusCode, undefined, internalPricingContextState.asyncProcessStatus, dealType)}
                            onClick={saveDraft}
                            hidden={saveDraftHidden}
                            allowDisabledFocus={false} />
                    </StackItem>
                }
                <StackItem>
                    {
                        internalPricingContextState.pageMode?.toLowerCase() !== PageMode.View.toLowerCase() ?
                            <DefaultButton
                                text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                                styles={PageStyles.commandButtonPadding}
                                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                                title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelAndNavigateDashboardTitle", UIControlsText.CancelAndNavigateDashboardTitle)}
                                onClick={() => {
                                    if (!internalPricingContextState.productGroup || !JavaScriptExtensions.isDefined(internalPricingContextState.isMultiNational)
                                        || !JavaScriptExtensions.isDefined(internalPricingContextState.opportunityDeals)) {
                                        history.push("/homePage");
                                    }
                                    else {
                                        setHideCancelDialog(false);
                                    }
                                }} />
                            : <PrimaryButton
                                text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                                styles={PageStyles.commandButtonPadding}
                                aria-label={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                                onClick={() => {
                                    history.push("/homePage");
                                }
                                } />
                    }
                </StackItem>
            </Stack>
            <CoherenceDialog
                hidden={hideCancelDialog}
                onDismiss={() => {
                    setHideCancelDialog(true);
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ConfirmTitle", UIControlsText.ConfirmTitle),
                    subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.PopupMessageText, "ConfirmCancelMessage", UIControlsText.ConfirmCancelMessage)
                }}
            >
                <CoherenceDialogFooter>
                    <PrimaryButton
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                        onClick={() => {
                            setHideCancelDialog(true);
                            history.push("/dashboard");
                        }}
                    />
                    <DefaultButton
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText)}
                        onClick={() => {
                            setHideCancelDialog(true);
                        }}
                    />
                </CoherenceDialogFooter>
            </CoherenceDialog>

            <CoherenceDialog
                hidden={hideErrorDialog}
                onDismiss={() => {
                    setHideErrorDialog(true);
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ErrorText", UIControlsText.ErrorText),
                    subText: errorDialogMessage
                }}
            >
                <CoherenceDialogFooter>
                    <PrimaryButton
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                        title={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NavigateDashboardTitle", UIControlsText.NavigateDashboardTitle)}
                        onClick={() => {
                            setHideErrorDialog(true);
                            history.push("/dashboard");
                        }}
                    />
                </CoherenceDialogFooter>
            </CoherenceDialog>

            <CoherenceDialog
                hidden={hideOwnerDialog}
                onDismiss={() => {
                    setHideOwnerDialog(true);
                    history.push("/dashboard");
                }}
                isBlocking={true}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NotCaseOwnerDialogTitle", UIControlsText.NotCaseOwnerDialogTitle),
                    subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NotCaseOwnerDialogMessage", UIControlsText.NotCaseOwnerDialogMessage)
                }}
            >
                <CoherenceDialogFooter>
                    <PrimaryButton
                        text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                        ariaLabel={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "OkText", UIControlsText.OkText)}
                        onClick={() => {
                            setHideOwnerDialog(true);
                            history.push("/dashboard");
                        }}
                    />
                </CoherenceDialogFooter>
            </CoherenceDialog>
        </Stack >
    );
}
export default InternalPricingCommands;