import {useState, useCallback, useEffect} from 'react';
import { GridColumnMenuProps } from "@progress/kendo-react-grid";
import { useLocation } from 'react-router';
import { Link } from '@fluentui/react';

//constants used in accessibility fixes
export const PageNames={
    Dashboard:"Dashboard",
    NewCaseSubmission:"New Case Submission",
    Discount:"Discount",
}

// Custom Hook Intended to Facilitate Column Filter Menu Close and Focus Handling
export function useFilterMenuEscCloseAndFocus(props?: GridColumnMenuProps){
    let filterMenuContainerSelector = ".k-grid-columnmenu-popup";

    const escapeFilterMenuHandler = useCallback((event) => {
        if (event.key === 'Escape') {
            if (props?.onCloseMenu) {
                props.onCloseMenu();
            }
        } else if(event.key === 'Tab'){
            let filterMenuContainer = document.querySelector(filterMenuContainerSelector);
            let primaryFilterButtonDisabledArray = Array.from(document.querySelectorAll("button.k-button[disabled]"));
            let primaryFilterButtonDisabled = primaryFilterButtonDisabledArray ? primaryFilterButtonDisabledArray[0] as HTMLButtonElement : undefined;
            let filterMenuButtons = Array.from(document.querySelectorAll("button.k-button"));
            let hasFilterMenuButtons = filterMenuButtons?.length === 2 && filterMenuButtons[0] && filterMenuButtons[1];
            if(hasFilterMenuButtons && (primaryFilterButtonDisabled && event.target === filterMenuButtons[0] || !primaryFilterButtonDisabled && event.target === filterMenuButtons[1])){
                (filterMenuContainer as HTMLDivElement).focus();
            }
        }
    }, [props]);

    useEffect(() => {
        if(props){
            document.querySelector(filterMenuContainerSelector)?.addEventListener('keydown', escapeFilterMenuHandler);
            
            return () => {
                document.querySelector(filterMenuContainerSelector)?.removeEventListener('keydown', escapeFilterMenuHandler);
            };
        }
    }, [props, escapeFilterMenuHandler]);
}

export function useWhereToFocus(){
    const [columnFilterIcon, setColumnFilterIcon] = useState<HTMLElement>();
    const [iconClickedCount, setIconClickedCount] = useState(0);

    const updateState = useCallback((event: MouseEvent) => {
        let clickedElement = event.target as HTMLElement;
        let clickCountString = clickedElement.getAttribute('data-clicked');
        let clickCount = clickCountString ? parseInt(clickCountString) + 1 : 1;
        setIconClickedCount(clickCount);
        clickedElement.setAttribute('data-clicked', clickCount.toString());
        setColumnFilterIcon(clickedElement);
    }, []);

    useEffect(() => {
        let gridColumnFilters = document.querySelectorAll('.k-icon.k-i-more-vertical');

        gridColumnFilters.forEach((gridColumnFilter, key, parent) => {
            let gridColumnFilterParent = gridColumnFilter.parentElement;
            
            if (gridColumnFilterParent){
                gridColumnFilterParent.id = 'filterMenuId-' + key
                !gridColumnFilterParent.hasAttribute('tabindex') && gridColumnFilter.parentElement?.setAttribute('tabindex', '0');
                gridColumnFilterParent.addEventListener('click', updateState);
            }
              
        });

        return () => {
            gridColumnFilters.forEach((gridColumnFilter) => {
                gridColumnFilter.parentElement?.removeEventListener("click", updateState);
            });
        };
    }, [updateState]);

    return [columnFilterIcon, iconClickedCount];
}

// Did the focus change when the user clicked the filter menu icon
export function useDidFocusChange(element?: HTMLElement) {
    var props = { whereToFocus: element };
    const [clickedElementId, setClickedElementId] = useState<string>();
    const [isClickedElementChanged, setIsClickedElementChanged] = useState(false);

    const renderToggle = useCallback(() => {
        if (props.whereToFocus?.parentElement?.id === clickedElementId) {
            setIsClickedElementChanged(false);
        } else {
            setClickedElementId(props.whereToFocus?.parentElement?.id);
            setIsClickedElementChanged(true);
        }
    }, [props, clickedElementId]);

    useEffect(() => {
        isClickedElementChanged && renderToggle();
    }, [isClickedElementChanged]);

    return isClickedElementChanged;
}

export function useHandleFocusOnMutate(){
    const [clickedElementId, setClickedElementId] = useState<string>();
    
    var [icon, count] = useWhereToFocus();
    var whereToFocus = icon as HTMLElement;
    var focusCount = count as number;
    const updateId = useCallback(() => {
        setClickedElementId(whereToFocus?.parentElement?.id);
    }, [whereToFocus, focusCount]);

    var config = { attributes: false, childList: true, subtree: false };
    var observer = new MutationObserver((mutations, cb_observer) => {
        let filterMenuParentContainer = document.querySelector('.k-animation-container');
        
        updateId();
        
        let clickedElement = document.querySelector('#'+ clickedElementId);
        !filterMenuParentContainer && (clickedElement as HTMLElement)?.focus();
        
        cb_observer.disconnect();
    });

    useEffect(() => {
        var bodyElement = document.querySelector('body');
        bodyElement && observer.observe(bodyElement, config);
    });
}

// useToHandleStateAndSetFocusBetweenChannelGridLinksAndViewReseller
export function useToHandleGlassFravcr(){
    let location = useLocation<any>();
    let whereTo = location?.state?.mdppAccessibilityNavigation;

    let pivot = undefined;
        if(whereTo === "back to channel tab" && location.pathname.includes("/managecase/cpstrategic")){
            pivot = "Channel";
        }

    return pivot;
}

export function useToHandleSettingViewResellerNavState() : string | undefined{
    let location = useLocation<any>();
    let currentPath = location.pathname;
    
    
    if(currentPath.includes("/managecase/cpstrategic")){
        return "from channel tab grid link";
    }

    return undefined;
}

export function useToSetFocusOnResellerNameLinkFocus() {
    let currentLocation = useLocation<any>();
    let currentLocationStateLinkId = "#" + currentLocation.state?.mdppAccessibilityLinkId;

    useEffect(() => {
        if(currentLocationStateLinkId){
            (document.querySelector(currentLocationStateLinkId) as HTMLElement)?.focus();

            let locationState = currentLocation.state;
            let locationNav = locationState?.mdppAccessibilityNavigation;

            if(locationNav && locationNav === "back to channel tab"){
                currentLocation.state.mdppAccessibilityNavigation = "on channel tab";
            }
        }
    }, []);
}

export function MdppAccessibilityLink({...rest}: {} & React.ComponentPropsWithoutRef<typeof Link>){
    useToSetFocusOnResellerNameLinkFocus();
    return (<Link {...rest}></Link>);
}

//Accessibility - set Focus to Header in View Resellers component
export function useToSetFocusToHeader(){
    useEffect(() => {
        var heading = document.querySelector('h1');
        heading?.focus();
    });
}

//Accessibility - enable validation message to be announced by screen readers
export function useToAddRoleAlert(){
    useEffect(() => {
        var messageBox = document.querySelector('.ms-MessageBar-text');
        messageBox?.setAttribute('role','alert');
    });
}

//Accessibility - enable Loading message to be announced by screen readers
export function useToSetRoleToLoadingMessage(){
    useEffect(() => {
        var loadingMessageSpinner = document.querySelector('.ms-Spinner-label');
        loadingMessageSpinner?.setAttribute('role','alert');
    });
}

//Function to modify page title based on the active Page
function setTitle(currentTitle:string){
    var setDocumentTitle= 'NextGen Pricing Portal '+currentTitle;
    var currentDocumentTitle = document.querySelector('head title');
    if(currentDocumentTitle !=null){
        currentDocumentTitle.innerHTML = setDocumentTitle;
    }
}

//Accessibility Fix for screen readers to announce dashboard and new case submissionpage title correctly
export function useToSetPageTitle(page: string){
        useEffect(() => {
            var currentPage = document.querySelectorAll('.ms-Link.ms-Breadcrumb-itemLink div div');
            if(page=='Discount'){
                var currentPageTitle= currentPage[0]?.innerHTML;
            }
            else{
                var currentPageTitle= currentPage[1]?.innerHTML;
            }
            setTitle(currentPageTitle);       
        });
      
}

//Accessibility Fix for screen readers to announce Health and Support Page title correctly
export function useToSetSupportPageTitle(){
    useEffect(() => {
        var currentPage = document.querySelectorAll('.DRStyleExpand-197 div div');
        var currentPageTitle= currentPage[0]?.innerHTML;
        setTitle(currentPageTitle);
    });
}