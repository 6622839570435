import { AzSearchStore } from "azsearchstore";
import { useEffect, useState,useCallback } from "react";
import { isAnyEUCountrySelected, isMsStoreUser, readGridConfig, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { UIControlsText } from "../../../../content/UIControlsText";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { ICountry } from "../../../../models/ICountry";
import { getter } from "@progress/kendo-data-query";
import { GridType } from "../../../../models/GridType";
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { DefaultPageStyles, _Styles } from "../../../../content/styles/Page.styles";
import { setErrorsDataIntoContext, stackItemCustomerStyles, stackItemStyles } from "../PricingProgramFormUtils";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import _, { groupBy } from "lodash";
import { Guid } from "../../../../infrastructure/Guid";
import { IProductFamily } from "../../../../models/IProductFamily";
import { ActionType } from "../../../../models/ActionType";
import { IDealInfoProps } from "../props/IDealInfoProps";
import { UIControlType } from "../../../../models/UIControlType";
import { formatProductSearchAPIData, setProductSearchStore } from "../../../../components/shared/productDatabyProductview";
import { useCommonPPContext } from "../../common/context/common-pp-context";
import { CaseActionTypes } from "../../common/reducer/CaseActionTypes";
import { DealType } from "../../../../models/DealType";
import { PageMode } from "../../../../models/PageMode";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { IFacetData } from "../../../../models/IFacetData";
import { CssClassConstants } from "../../../../content/CssClassConstants";
import { StatePropertiesText } from "../../common/state/StatePropertiesText";
import { JavaScriptExtensions } from "../../../../infrastructure/JavaScriptExtensions";
import { ErrorMessages } from "../../../../content/ErrorMessages";
import { IOpportunityDealProductSku } from "../../../../models/IOpportunityDealProductSku";
import { ChannelType } from "../../../../models/ChannelType";
import { nextGenService } from "../../../../services/NextGenService";
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { MessageBarType} from "@fluentui/react";


export function ProductSearch(props: IDealInfoProps) {
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [globalSearchFilterExpression, setGlobalSearchFilterExpression] = useState<string>("");
    const [searchFilterButtonText, setSearchFilterButtonText] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter));
    const [businessUnitGridConfig, setBusinessUnitGridConfig] = useState<IGridProps>(readGridConfig(GridType.BusinessUnitProductSearch));
    const [businessUnitSelectionGridConfig, setBusinessUnitSelectionGridConfig] = useState<IGridProps>(readGridConfig(GridType.SelectedBusinessUnit));
    const [productSearchGridConfig, setProductSearchGridConfig] = useState<IGridProps>(readGridConfig(GridType.PFAMProductSearch));
    const [productSelectionGridConfig, setProductSelectionGridConfig] = useState<IGridProps>(readGridConfig(GridType.SelectedPFAM));
    const [isSpecialPricingDeal, setIsSpecialPricingDeal] = useState<boolean>(false);
    const [isDealRegistraionDeal, setIsDealRegistraionDeal] = useState<boolean>(false);
    const [dealRegistraionDealId, setDealRegistraionDealId] = useState<string>("");
    const [specialPricingDealId, setSpecialPricingDealId] = useState<string>("");
    const idBUGetter = getter(businessUnitGridConfig.kendoGridProps.dataItemKey);
    const idPFAMGetter = getter(productSearchGridConfig.kendoGridProps.dataItemKey);
    const [selectionState, setSelectionState] = useState<any>({});
    const [productType, setProductType] = useState<string>("");
    const [searchBarString, setSearchBarString] = useState<string>("");
    const [showDR, setShowDR] = useState<boolean>(false);
    const [showSP, setShowSP] = useState<boolean>(false);
    const [hideCasecadeMessageNotes, setHideCasecadeMessageNotes] = useState<boolean>(true);
    const [hideCasecadeMessageDialog, setHideCasecadeMessageDialog] = useState<boolean>(true);
    const allCountries: ICountry[] = JSON.parse(sessionStorage.getItem(SessionStorageConsts.countries) || '{}');
    const isRenewalCascadeDisabled = commonPPContextState.opportunityDeals.filter(t => t.isRenewed === true)?.length > 0;
    /**
    Facet Constants
    */
    const [pfamName, setPfamName] = useState<IFacetData[]>([]);
    const [selectedPfamNameValues, setSelectedPfamNameValues] = useState<IFacetData[]>([]);
    const [businessUnit, setBusinessUnit] = useState<IFacetData[]>([]);
    const [selectedBusinessUnitValues, setSelectedBusinessUnitValues] = useState<IFacetData[]>([]);
    const [skuName, setSkuName] = useState<IFacetData[]>([]);
    const [selectedSkuNameValues, setSelectedSkuNameValues] = useState<IFacetData[]>([]);
    const [skuPartNumber, setSkuPartNumber] = useState<IFacetData[]>([]);
    const [selectedSkuPartNumberValues, setSelectedSkuPartNumberValues] = useState<IFacetData[]>([]);
    const [showProductSearchFilters, setShowProductSearchFilters] = useState<boolean>(false)
    const [showProductDialog, setShowProductDialog] = useState(false);
    const [inactivePFAMs, setInactivePFAMs] = useState<IOpportunityDealProductSku[]>([]);
    const [inactiveBUs, setInactiveBUs] = useState<IOpportunityDealProductSku[]>([]);
    const [customerCountryDealTypes, setCustomerCountryDealTypes] = useState<string>("");
    const [invalidPfams,setInvalidPfams]=useState<string[]>([]);
    const [showMessageBar, setShowMessageBar] = useState<boolean>(false);
    const handleDismiss = useCallback(() => 
        setShowMessageBar(false)
    , []);

    let allFacets = [selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues];
    let allFacetNames = ApplicationConstants.ProductSearchFacetNames;
    const pivotTab = commonPPContextState.pivotTabs?.find(t => t.tabName === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText));
    let customerCountry = "";
    let buSearchstore = new AzSearchStore();
    let defaultPFAMSearchstore = new AzSearchStore();
    let pfamSearchstore = new AzSearchStore();
    let isCascadePopUpViewed = true;
    useEffect(() => {
        if (commonPPContextState.opportunityDeals.length > 0 && Object.keys(commonPPContextState.opportunityDeals[0]).length > 0) {
            var customers = commonPPContextState?.opportunityDeals[0].dealCustomers;
            let temporaryCustomerCountryDealTypes = "";
            if (customers && customers.length > 0) {
                customerCountry = customers[0].country || "";
                let selectedCountry = allCountries.find(x => x.countryName === customerCountry);
                if (selectedCountry?.isEUEFTA) {
                    temporaryCustomerCountryDealTypes = ApplicationConstants.Eu;
                }
                else if (selectedCountry?.region?.toLowerCase() === ApplicationConstants.MiddleEastRegion.toLowerCase()) {
                    temporaryCustomerCountryDealTypes = ApplicationConstants.MiddleEastRegion;
                }
                else {
                    temporaryCustomerCountryDealTypes = customerCountry;
                }
            }
            setIsSpecialPricingDeal(false);
            setIsDealRegistraionDeal(false);

            setProductType(commonPPContextState.partnerDetails?.productGroupName || "");
            let specialPricingDeal: boolean = false;
            let dealRegistrationDeal: boolean = false;
            let showDRCase: boolean = false;
            let showSPCase: boolean = false;
            setShowDR(false);
            setShowSP(false);
            for (let i = 0; i < commonPPContextState.opportunityDeals.length; i++) {
                let deal = commonPPContextState.opportunityDeals[i]?.dealType?.name || "";
                var products = commonPPContextState.opportunityDeals[i].products;
                let updatedproductFamily = products?.map((item: IProductFamily) => ({ ...item, searchId: item.id }));
                var savedproductFamily: IProductFamily[] = getProductFamilyModel(updatedproductFamily || [], false);
                var oppDeal = commonPPContextState.opportunityDeals[i];
                if (deal === DealType.SpecialPricing) {
                    setIsSpecialPricingDeal(true);
                    //to show or hide deal based on selected deal
                    if (oppDeal.name === commonPPContextState.selectedOptyDealCaseNumber ||
                        (commonPPContextState.pageMode === PageMode.Edit && oppDeal.statusCode === OpportunityDealStatusCodes.Draft)) {
                        setShowSP(true);
                        showSPCase = true;
                    }
                    specialPricingDeal = true;
                    if(!isRenewalCascadeDisabled || oppDeal.name === commonPPContextState.selectedOptyDealCaseNumber) {
                        setSpecialPricingDealId((commonPPContextState.opportunityDeals[i]?.id) || "");
                    }
                    temporaryCustomerCountryDealTypes = temporaryCustomerCountryDealTypes + deal;
                    //to show products on page load of edit
                    if ((commonPPContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) && products) {
                        let groupedbyPFAM = [
                            ...new Map(
                                savedproductFamily?.map((item: IProductFamily) => [item.productFamilyId, item])
                            ).values(),
                        ];
                        updateProductFamilyAndDispatch(groupedbyPFAM, true);
                    }
                    //validate product inactive status 
                    if (oppDeal.statusCode === OpportunityDealStatusCodes.Draft && commonPPContextState.pageMode === PageMode.Edit) {
                        var inactiveProducts = oppDeal.inActiveProducts;
                        if (inactiveProducts && inactiveProducts?.length > 0) {
                            let groupedData = [
                                ...new Map(
                                    inactiveProducts?.map((item: any) => [item.name, item])
                                ).values(),
                            ];
                            setInactivePFAMs(groupedData);
                        }
                    }
                }
                else if (deal === DealType.DealRegistration) {
                    setIsDealRegistraionDeal(true);
                    //to show or hide deal based on selected deal
                    if (oppDeal.name === commonPPContextState.selectedOptyDealCaseNumber ||
                        (commonPPContextState.pageMode === PageMode.Edit && oppDeal.statusCode === OpportunityDealStatusCodes.Draft)) {
                        setShowDR(true);
                        showDRCase = true;
                    }
                    dealRegistrationDeal = true;
                    if(!isRenewalCascadeDisabled || oppDeal.name === commonPPContextState.selectedOptyDealCaseNumber) {
                        setDealRegistraionDealId((commonPPContextState.opportunityDeals[i]?.id) || "");
                    }
                    
                    temporaryCustomerCountryDealTypes = temporaryCustomerCountryDealTypes + deal;
                    //to show products on page load of edit
                    if ((commonPPContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase()) && products) {
                        let groupedbyBU = [
                            ...new Map(
                                savedproductFamily?.map((item: IProductFamily) => [item.businessUnitName, item])
                            ).values(),
                        ];
                        updateProductFamilyAndDispatch(groupedbyBU.filter(b => b.businessUnitName !== ""), false);
                    }
                    //validate product inactive status 
                    if (oppDeal.statusCode === OpportunityDealStatusCodes.Draft && commonPPContextState.pageMode === PageMode.Edit) {
                        var inactiveProducts = oppDeal.inActiveProducts;
                        if (inactiveProducts && inactiveProducts?.length > 0) {
                            let groupedData = [
                                ...new Map(
                                    inactiveProducts?.map((item: any) => [item.name, item])
                                ).values(),
                            ];
                            setInactiveBUs(groupedData);
                        }
                    }
                }
            }
            if (showDRCase && inactiveBUs.length > 0) {
                setShowProductDialog(true);
            }
            else if (showSPCase && inactivePFAMs.length > 0) {
                setShowProductDialog(true);
            }
            if (temporaryCustomerCountryDealTypes !== customerCountryDealTypes) {
                resetSelectionGrid();
            }
            setCustomerCountryDealTypes(temporaryCustomerCountryDealTypes);
            if (commonPPContextState.partnerDetails) {
                defaultSearch(specialPricingDeal, dealRegistrationDeal);
            }
            //to show casecade message to user when adding new bu and multi deal
            if (commonPPContextState.pageMode === PageMode.Edit) {
                var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === commonPPContextState.selectedOptyDealCaseNumber)[0];
                if (oppDeal) {
                    if (commonPPContextState.opportunityDeals.length > 1 && showSP && oppDeal.statusCode !== OpportunityDealStatusCodes.Draft) {
                        setHideCasecadeMessageNotes(false);
                    }
                }
            }
        }
    }, [commonPPContextState.applicableDealTypes, commonPPContextState?.opportunityDeals[0] && commonPPContextState?.opportunityDeals[0].dealCustomers, commonPPContextState.partnerDetails, commonPPContextState.selectedOptyDealCaseNumber]);

    buSearchstore = setProductSearchStore(ApplicationConstants.BusinessUnitProductSearchIndex, ApplicationConstants.BusinessUnitProductSearchSuggesterName, ApplicationConstants.BusinessUnitSearchSelectColumn, globalSearchFilterExpression);
    defaultPFAMSearchstore = setProductSearchStore(ApplicationConstants.DistinctProductSearchIndex, ApplicationConstants.DistinctProductSearchSuggesterName, ApplicationConstants.ProductSearchSelectColumn, globalSearchFilterExpression);
    pfamSearchstore = setProductSearchStore(ApplicationConstants.ProductSearchIndex, ApplicationConstants.ProductSearchSuggesterName, ApplicationConstants.ProductSearchSelectColumn, globalSearchFilterExpression);

    const getProductSearchData = async (facetName: string, selectedValue: any[], skip: number, searchBarValue: string, searchFilterExpression: string, specialPricingDeal: boolean): Promise<void> => {
        try {
            let searchStore = new AzSearchStore();
            let indexURL: string = "";
            let facetNames: any[] = [];

            if (specialPricingDeal) {
                if (searchBarValue.length > 0) {
                    searchStore = pfamSearchstore;
                    indexURL = process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.ProductSearchIndexApi_Url || "";
                    facetNames = ApplicationConstants.ProductSearchFacetNames;
                }
                else {
                    searchStore = defaultPFAMSearchstore;
                    indexURL = process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.DistinctProductSearchIndexApiUrl || "";
                    facetNames = ApplicationConstants.DistinctProductSearchFacetNames;
                }
            }
            else {
                searchStore = buSearchstore;
                indexURL = process.env.REACT_APP_SEARCH_API_URL_ENV + ApplicationConstants.BuProductSearchApiUrl || "";
                facetNames = [];
            }

            let facetString = props?.facetProps?.facetList(facetNames);

            searchStore.updateSearchParameters({ count: true, skip: skip, searchMode: "all", });
            searchStore.setInput(searchBarValue);
            let apiReturnedData = await Promise.resolve(nextGenService.getProductSearchResults(indexURL, searchFilterExpression, searchBarValue, skip, facetString || [], ApplicationConstants.CustomerSearchTopThreshold));
            if (apiReturnedData.status === 200) {
                let productsCount = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                if (productsCount === 0) {
                    searchBarValue = searchBarValue.slice(0, -1); // if first call results 0  then second  time search the selected value with  exact options by removing "*"
                    apiReturnedData = await Promise.resolve(nextGenService.getProductSearchResults(indexURL, searchFilterExpression, searchBarValue, skip, facetString || [], ApplicationConstants.CustomerSearchTopThreshold));
                    productsCount = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                }
                let productsSearchResultsData = [...(apiReturnedData.data as { [key: string]: any })["value"] as string];
                if (productsCount > productsSearchResultsData.length) {
                    for (
                        let i = 1;
                        i < Math.ceil(productsCount / ApplicationConstants.CustomerSearchTopThreshold);
                        i++
                    ) {
                        apiReturnedData = await Promise.resolve(nextGenService.getProductSearchResults(indexURL, searchFilterExpression, searchBarValue, i * ApplicationConstants.CustomerSearchTopThreshold, facetString || [], ApplicationConstants.CustomerSearchTopThreshold));
                        productsSearchResultsData.push(...(apiReturnedData.data as { [key: string]: any })["value"] as string);
                    }
                }
                let array: any[] = [];

                let customerCountry = "";
                if (commonPPContextState?.selectedOptyDealCaseNumber) {
                    var tempDeal = commonPPContextState.opportunityDeals.filter(x => x.name === commonPPContextState.selectedOptyDealCaseNumber);
                    if(tempDeal && tempDeal.length > 0 && tempDeal[0].dealCustomers && tempDeal[0].dealCustomers.length > 0){
                        customerCountry = tempDeal[0].dealCustomers[0].country  || "";
                    }
                }
                else{
                    if(commonPPContextState?.opportunityDeals[0].dealCustomers && commonPPContextState?.opportunityDeals[0].dealCustomers.length > 0){
                        customerCountry = commonPPContextState?.opportunityDeals[0].dealCustomers[0].country || "";
                    }
                }
                let selectedGeoCountries = allCountries.filter(country => country.countryName?.toLowerCase() === customerCountry.toLowerCase());
                array = formatProductSearchAPIData(productsSearchResultsData, selectedGeoCountries, specialPricingDeal);
                
                if (specialPricingDeal) {
                    let productSearchConfig = { ...productSearchGridConfig };
                    let searchData = _.uniqBy(array, (item) =>
                        [item?.CountryId, item?.PfamId].join()
                    );
                    let groupedData = groupProductSearchData(searchData);
                    productSearchConfig.kendoGridProps.data = groupedData;
                    productSearchConfig.kendoGridProps.masterData = searchData;
                    productSearchConfig.kendoGridProps.totalRecords = groupedData.length;
                    setProductSearchGridConfig({ ...productSearchConfig });

                    if (facetName.length == 0 && selectedValue.length == 0) {
                        for (var i = 0; i < allFacetNames.length; i++) {
                            setComboBoxDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as IFacetData[], allFacetNames[i]);
                        }
                    }
                    else {
                        for (var i = 0; i < allFacetNames.length; i++) {
                            if (allFacetNames[i] != facetName) {
                                setComboBoxDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as IFacetData[], allFacetNames[i]);
                                setSelectedValuesDatasource(allFacets[i], allFacetNames[i]);
                            } else {
                                setSelectedValuesDatasource(selectedValue, facetName);
                            }
                        }
                    }
                }
                else {
                    let searchConfig = { ...businessUnitGridConfig };
                    let searchData = _.uniqBy(array, (item) =>
                        [item?.BusinessUnitId].join()
                    );
                    let groupedData = groupProductSearchData(searchData);
                    searchConfig.kendoGridProps.data = groupedData;
                    searchConfig.kendoGridProps.totalRecords = groupedData.length;
                    setBusinessUnitGridConfig({ ...searchConfig });
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const groupProductSearchData = (searchData: any[]) => {
        let groupedData = [
            ...new Map(
                searchData?.map((item: any) => [item.BusinessUnit, item])
            ).values(),
        ];

        return groupedData;
    }
   
    const onSubmit = (selectedValue: string): void => {
        let selectedProductSearchConfig = { ...productSelectionGridConfig };
        if (selectedProductSearchConfig.kendoGridProps.data === undefined) {
            selectedProductSearchConfig.kendoGridProps.data = [];
        }
        selectedProductSearchConfig = { ...businessUnitSelectionGridConfig };
        if (selectedProductSearchConfig.kendoGridProps.data === undefined) {
            selectedProductSearchConfig.kendoGridProps.data = [];
        }
        let filterStringExpression = createFilterString([], "", isSpecialPricingDeal, isDealRegistraionDeal, true);
        selectedValue = selectedValue.concat("*");
        setSearchBarString(selectedValue);
        getProductSearchData("", [], 0, selectedValue, filterStringExpression, isSpecialPricingDeal);
    };

    const onClear = (): void => {
        defaultSearch(isSpecialPricingDeal, isDealRegistraionDeal);
    };

    const resetSelectionGrid = (): void => {
        let selectedProductSearchConfig = { ...businessUnitSelectionGridConfig };
        selectedProductSearchConfig.kendoGridProps.data = [];
        selectedProductSearchConfig.kendoGridProps.totalRecords = 0;
        setBusinessUnitSelectionGridConfig({ ...selectedProductSearchConfig });
        selectedProductSearchConfig = { ...productSelectionGridConfig };
        selectedProductSearchConfig.kendoGridProps.data = [];
        selectedProductSearchConfig.kendoGridProps.totalRecords = 0;
        setProductSelectionGridConfig({ ...selectedProductSearchConfig });
    };

    const defaultSearch = (specialPricingDeal: boolean, dealRegistrationDeal: boolean): void => {
        for (let i = 0; i < allFacetNames.length; i++) {
            setComboBoxDatasource([], allFacetNames[i]);
            setSelectedValuesDatasource([], allFacetNames[i]);
        }

        if (specialPricingDeal) {
            if (searchBarString.length > 0) {
                allFacetNames = ApplicationConstants.ProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues];
            }
            else {
                allFacetNames = ApplicationConstants.DistinctProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues];
            }
        }
        else {
            allFacetNames = [];
            allFacets = [];
        }
        let filterStringExpression = createFilterString([], "", specialPricingDeal, dealRegistrationDeal, true);
        setSearchBarString("");
        getProductSearchData("", [], 0, "", filterStringExpression, specialPricingDeal);
    };

    //hide or show facets on click of button
    const onShowFacets = () => {
        if (!showProductSearchFilters) {
            setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HideSearchFilter", UIControlsText.HideSearchFilter));
            setShowProductSearchFilters(true);
        }
        else {
            setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter));
            setShowProductSearchFilters(false);
        }
    }

    //Message prompted on selection of Pfams not applicable for SP 
   const getMessage = (inavalidPfamList:string[]):string =>{
    let message :string = "";
    if(inavalidPfamList.length>1){
        message = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MultipleInvalidPfamError", UIControlsText.MultipleInvalidPfamError);
    } else{
        message = JavaScriptExtensions.stringFormat(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InvalidPfamError", UIControlsText.InvalidPfamError),inavalidPfamList[0]);
    }
    return message;
   }

    //callback function on checkbox selection of header and/or row(s)
    const onRowSelectionCallBack = (selectedState: any) => {
        let selectedProducts: IProductFamily[] = [];
        let searchGridDatawithSelection: any[] = [];
        var dealType = DealType.SpecialPricing;

        if (isSpecialPricingDeal) {
            searchGridDatawithSelection = productSearchGridConfig.kendoGridProps.masterData.map((item: any) => ({ ...item, ['selected']: selectedState[idPFAMGetter(item)] }));
        }
        else {
            searchGridDatawithSelection = businessUnitGridConfig.kendoGridProps.data.map((item: any) => ({ ...item, ['selected']: selectedState[idBUGetter(item)] }));
            dealType = DealType.DealRegistration;
        }

        searchGridDatawithSelection.map((item: any) => {
            if (item.selected === true) {
                selectedProducts.push(Object.assign({ inEdit: undefined }, item));
            }
        });
        addSelectedProductFamily(selectedProducts, dealType);
        setSelectionState(selectedState);
    }

    const onDetailRowSelectionCallBack = (selectedState: any) => {
        onRowSelectionCallBack(selectedState);
    }
    
    //on click checkbox add checked rows to selection grid at right
    const addSelectedProductFamily = (selectedData: IProductFamily[], dealType: DealType): void => {
        let selectedproductFamily: IProductFamily[] = getProductFamilyModel(selectedData, true).map((item: IProductFamily) => ({ ...item, action: ActionType.Add }));
        let finalSelection: IProductFamily[] = [];
        let invalidPfamsForSpecialPricing:string[]=[];
        let applicableForSpecialPricing:string = ApplicationConstants.SpecialPricingApplicableDeal.toString();
        var pagemode = commonPPContextState.pageMode;

        let allowUpdateProductFamily = false;
        if (showSP || commonPPContextState.opportunityDeals.filter(x => x.name === commonPPContextState.selectedOptyDealCaseNumber)[0].isRenewed) {
            allowUpdateProductFamily = true;
            
        }
        
        if (isSpecialPricingDeal || dealType === DealType.SpecialPricing) {
            finalSelection = productSelectionGridConfig.kendoGridProps.data;

            selectedproductFamily.map(item => {
                let findItem = finalSelection.find(x => x.productFamilyCode === item.productFamilyCode && x.country === item.country);
                let applicableDealsArray = item.applicableDeals.split(";");
                if (findItem === undefined && applicableDealsArray?.length > 0 && applicableDealsArray.includes(applicableForSpecialPricing)) {
                    finalSelection.push(item);
                }
                else if(applicableDealsArray?.length>0 && !applicableDealsArray.includes(applicableForSpecialPricing)){
                    setShowMessageBar(true);
                    invalidPfamsForSpecialPricing.push(item.name);    
                }
                if(invalidPfamsForSpecialPricing.length>0){
                    setInvalidPfams(invalidPfamsForSpecialPricing);
                } 
            });
           
            if (pagemode === PageMode.Create || (pagemode === PageMode.Edit && allowUpdateProductFamily)) {
               updateProductFamilyAndDispatch(finalSelection, true);
            }
        }

        if (isDealRegistraionDeal  || dealType === DealType.DealRegistration) {
            finalSelection = businessUnitSelectionGridConfig.kendoGridProps.data;
            selectedproductFamily.map(item => {
                let findItem = finalSelection.find(x => x.businessUnitName === item.businessUnitName);
                if (findItem === undefined) {
                    finalSelection.push(item);
                    //to show casecade message to user when adding new bu and multi deal
                    if (commonPPContextState.pageMode === PageMode.Edit && isCascadePopUpViewed) {
                        var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === commonPPContextState.selectedOptyDealCaseNumber)[0];
                        if (oppDeal) {
                            if (commonPPContextState.opportunityDeals.length > 1 && showSP && oppDeal.statusCode !== OpportunityDealStatusCodes.Draft) {
                                setHideCasecadeMessageDialog(false);
                                isCascadePopUpViewed=false;
                            }
                        }
                    }
                }
            });
            updateProductFamilyAndDispatch(finalSelection, false);
       }
    }

    //construct pfam model from selection grid
    const getProductFamilyModel = (productFamily: IProductFamily[], isAddition: boolean): IProductFamily[] => {
        let selectedProductFamily: IProductFamily[] = [];
        selectedProductFamily = productFamily.map((item: any) => {
            return {
                id: isAddition ? Guid.newGuid() : item.id,
                countryId: isAddition ? item.CountryId : item.countryId,
                productFamilyId: isAddition ? item.PfamId : item.productFamilyId,
                productFamilyCode: isAddition ? item.ProductFamilyCode : item.productFamilyCode,
                country: isAddition ? item.Country : item.country,
                name: isAddition ? item.PfamName : item.name,
                skuId: isAddition ? item.SKUId : item.skuId,
                businessUnitId: isAddition ? item.BusinessUnitId : item.businessUnitId,
                businessUnit: isAddition ? item.BusinessUnit : item.businessUnit,
                action: item.action,
                searchId: isAddition ? item.id : item.searchId,
                businessUnitName: isAddition ? item.BusinessUnit : item.businessUnitName,
                businessunit: isAddition ? item.BusinessUnit : item.businessUnitName,
                editMode: item.editMode,
                applicableDeals:isAddition?item.ApplicableDeals:item.applicableDeals,
            } as IProductFamily
        });
        return selectedProductFamily;
    }

    //dispatch products
    const updateProductFamilyAndDispatch = (productFamily: IProductFamily[], isSelectedProductFamilyConfig: boolean): void => {
        let selectedProductFamilyConfig;
        var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.dealType?.name === DealType.DealRegistration)[0];

        if (isSelectedProductFamilyConfig) {
            selectedProductFamilyConfig = { ...productSelectionGridConfig };
            oppDeal = commonPPContextState.opportunityDeals.filter(d => d.dealType?.name === DealType.SpecialPricing)[0];
        }
        else {
            selectedProductFamilyConfig = { ...businessUnitSelectionGridConfig };
        }

        selectedProductFamilyConfig.kendoGridProps.data = productFamily.filter(item => item.action !== ActionType.Delete);
        selectedProductFamilyConfig.kendoGridProps.editMode = false;
        selectedProductFamilyConfig.kendoGridProps.totalRecords = productFamily.filter(item => item.action !== ActionType.Delete).length;

        if (commonPPContextState.pageMode?.toLowerCase() === PageMode.Edit.toLowerCase() && oppDeal) {
            if (oppDeal.statusCode !== OpportunityDealStatusCodes.Draft && selectedProductFamilyConfig.kendoGridProps.actionTypes?.showClearAllGridAction) {
                selectedProductFamilyConfig.kendoGridProps.actionTypes.showClearAllGridAction = false;
            }
        }
        if (isSelectedProductFamilyConfig) {
            setProductSelectionGridConfig({ ...selectedProductFamilyConfig });
            commonPPContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: specialPricingDealId, value: productFamily } });
        }
        else {
            setBusinessUnitSelectionGridConfig({ ...selectedProductFamilyConfig });
            commonPPContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: dealRegistraionDealId, value: productFamily } });
        }

        checkProductsSelected(productFamily);
    }

    const checkProductsSelected = (products: IProductFamily[]) => {
        let value = getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "YesText", UIControlsText.YesText);
        if (JavaScriptExtensions.isNullOrUndfinedOrEmpty(products) || products?.length === 0) {
            value = '';
        }

        const pivotTabsData = [...commonPPContextState?.pivotTabs || []];
        setErrorsDataIntoContext(pivotTabsData, value, getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ValidateProduct", ErrorMessages.ValidateProduct), StatePropertiesText.ValidateProduct, getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductsText", UIControlsText.ProductsText));
        commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabsData });
    }

    //function to remove selected row from grid and update state
    const onDeleteCallBack = (deletedRowData: any, refreshedGridData: any) => {
        let deletedDataFromDeleteAll: IProductFamily[] = [];
        let deletedData: any[] = [];
        let selectedPFAMConfig = { ...productSelectionGridConfig };
        let selectedBUConfig = { ...businessUnitSelectionGridConfig };
        let selectedPFAM = selectedPFAMConfig.kendoGridProps.data;
        let selectedBU = selectedBUConfig.kendoGridProps.data;

        if (refreshedGridData.length === 0) {
            deletedDataFromDeleteAll = deleteRowFromSelectionGrid([], isSpecialPricingDeal);
            if (isSpecialPricingDeal) {
                commonPPContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: specialPricingDealId, value: [] } });
            }
            if (isDealRegistraionDeal) {
                if(commonPPContextState.pageMode === PageMode.Create || commonPPContextState?.opportunityDeals?.find(t => t.name === commonPPContextState.selectedOptyDealCaseNumber)?.isRenewed === false) {
                    commonPPContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: dealRegistraionDealId, value: [] } });
                }
            }
            resetSelectionGrid();
        }
        else if (isSpecialPricingDeal && isDealRegistraionDeal) {
            //Check if delete clicked on PFAM Selection Grid
            if ((refreshedGridData.length + 1) === selectedPFAMConfig.kendoGridProps.totalRecords) {
                let rowsSelectedInSPGrid = selectedPFAM.filter((x: { businessUnitName: any; }) => x.businessUnitName === deletedRowData.businessUnitName);

                if (rowsSelectedInSPGrid.length > 1) {
                    // One Row deleted from Special Pricing grid but not Deal Registration grid
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, true); // delete row from Special Pricing grid
                }
                else {
                    // One Row deleted from both grids
                    let refreshedBUGridData = selectedBU.filter((x: { businessUnitName: any; }) => x.businessUnitName !== deletedRowData.businessUnitName);
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedBUGridData, false); // delete row from Deal Registration grid
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, true); // delete row from Special Pricing grid
                }
            }
            else {
                let rowsSelectedInDRGrid = selectedPFAM.filter((x: { businessUnitName: any; }) => x.businessUnitName === deletedRowData.businessUnitName);

                if (rowsSelectedInDRGrid.length > 1) {
                    // One Row deleted from Deal Registration grid but multiple from Special Pricing grid
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, false); // delete row from Deal Registration grid
                    refreshedGridData = selectedPFAM.filter((x: { businessUnitName: any; }) => x.businessUnitName !== deletedRowData.businessUnitName);
                    deletedRowData = selectedPFAM.filter((x: { businessUnitName: any; }) => x.businessUnitName === deletedRowData.businessUnitName);
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, true); // delete rows from Special Pricing grid
                }
                else {
                    // One row deleted from both grids
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, true); // delete row from Special Pricing grid
                    deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, false); // delete row from Deal Registration grid
                }
            }
        }
        else if (isSpecialPricingDeal) {
            deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, true); // delete row from Special Pricing grid
        }
        else if (isDealRegistraionDeal) {
            deletedDataFromDeleteAll = deleteRowFromSelectionGrid(refreshedGridData, false); // delete row from Deal Registration grid
        }

        if (deletedRowData.searchId) {
            deletedData.push(deletedRowData);
        }
        else {
            deletedData = deletedRowData;
        }
        resetSearchgrid(deletedData, refreshedGridData, deletedDataFromDeleteAll);
    }

    const deleteRowFromSelectionGrid = (refreshedGridData: any, isSelectedProductFamilyConfig: boolean) => {
        let deletedSelectedProductConfig;
        let deletedDataFromDeleteAll: IProductFamily[] = [];
        let selectedProductFamily: any[] = [];

        if (isSelectedProductFamilyConfig) {
            deletedSelectedProductConfig = { ...productSelectionGridConfig };
        }
        else {
            deletedSelectedProductConfig = { ...businessUnitSelectionGridConfig };
        }

        if (refreshedGridData.length === 0) {
            deletedDataFromDeleteAll = deletedSelectedProductConfig.kendoGridProps.data
        }
        deletedSelectedProductConfig.kendoGridProps.data = refreshedGridData;
        deletedSelectedProductConfig.kendoGridProps.totalRecords = refreshedGridData.length;
        selectedProductFamily = getProductFamilyModel(refreshedGridData, false);

        if (isSelectedProductFamilyConfig) {
            if (commonPPContextState.pageMode === PageMode.Create || (commonPPContextState.pageMode === PageMode.Edit && showSP)) {
                setProductSelectionGridConfig(deletedSelectedProductConfig);
                commonPPContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: specialPricingDealId, value: selectedProductFamily } });
            }
        }
        else {
            //if (commonPPContextState.pageMode === PageMode.Create || commonPPContextState?.opportunityDeals?.find(t => t.name === commonPPContextState.selectedOptyDealCaseNumber)?.isRenewed === false) {
                setBusinessUnitSelectionGridConfig(deletedSelectedProductConfig);
                commonPPContextDispatch({ type: CaseActionTypes.selectedProductFamilyChanged, products: { key: dealRegistraionDealId, value: selectedProductFamily } });
            //}
        }

        checkProductsSelected(selectedProductFamily);
        return deletedDataFromDeleteAll;
    }

    //reset product search grid selection after adding
    const resetSearchgrid = (deletedItem: IProductFamily[], gridData: IProductFamily[], DeletedDataFromDeleteAll: IProductFamily[]): void => {
        let productSearchConfig;

        if (isSpecialPricingDeal) {
            productSearchConfig = { ...productSearchGridConfig };
        }
        else {
            productSearchConfig = { ...businessUnitGridConfig };
        }

        if (gridData.length > 0 && deletedItem[0].searchId) {
            deletedItem.map((item: IProductFamily) => {
                if (item.searchId) {
                    selectionState[item.searchId] = false;
                }
            });
            productSearchConfig.kendoGridProps.defaultDetailGridSelectionstate = selectionState;
        }
        else {
            DeletedDataFromDeleteAll.map((item: IProductFamily) => {
                if (item.searchId) {
                    selectionState[item.searchId] = false;
                }
            });
            productSearchConfig.kendoGridProps.defaultDetailGridSelectionstate = selectionState;
        }

        if (isSpecialPricingDeal) {
            setProductSearchGridConfig({ ...productSearchConfig });
        }
        else {
            setBusinessUnitGridConfig({ ...productSearchConfig });
        }
    }

    const createFilterString = (selectedValue: any[], facetName: string, specialPricingDeal: boolean, dealRegistrationDeal: boolean, isDefaultSearch: boolean): string => {
        let filterStringExpression = "";
        let selectedCountry = "";
        if (commonPPContextState?.selectedOptyDealCaseNumber) {
            var tempDeal = commonPPContextState.opportunityDeals.filter(x => x.name === commonPPContextState.selectedOptyDealCaseNumber);
            if(tempDeal && tempDeal.length > 0 && tempDeal[0].dealCustomers && tempDeal[0].dealCustomers.length > 0){
                selectedCountry = tempDeal[0].dealCustomers[0].country  || "";
            }
        }
        else{
            if(commonPPContextState?.opportunityDeals[0].dealCustomers && commonPPContextState?.opportunityDeals[0].dealCustomers.length > 0){
                selectedCountry = commonPPContextState?.opportunityDeals[0].dealCustomers[0].country || "";
            }
        }

        let selectedGeoCountries = allCountries.filter(country => country.countryName?.toLowerCase() === selectedCountry.toLowerCase());

        let nonEUCounties: ICountry[] = [];
        selectedGeoCountries.forEach(function (country) {
            let selectedGeoCountry = allCountries.find(allCountry => allCountry.countryName === country.applicablePriceListGeo);
            if (selectedGeoCountry != null) {
                nonEUCounties.push(selectedGeoCountry);
            }
        });
        nonEUCounties = _.uniqBy(nonEUCounties, "countryId");

        if (specialPricingDeal) {
            if (searchBarString.length > 0) {
                allFacetNames = ApplicationConstants.ProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues, selectedSkuNameValues, selectedSkuPartNumberValues];
            }
            else {
                allFacetNames = ApplicationConstants.DistinctProductSearchFacetNames;
                allFacets = [selectedPfamNameValues, selectedBusinessUnitValues];
            }
        }
        else {
            allFacetNames = [];
            allFacets = [];
        }
        filterStringExpression = props?.facetProps?.createGlobalFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex, ApplicationConstants.ProductSearchProductType], [[nonEUCounties.map(item => item.countryName)], [commonPPContextState.partnerDetails?.productGroupName || ""]], isAnyEUCountrySelected(selectedGeoCountries)) || "";
        if (!isDefaultSearch) {
            filterStringExpression = filterStringExpression + props?.facetProps?.createFilterExpression(allFacetNames, facetName, selectedValue, allFacets);
        }
        filterStringExpression = createDealTypeFilterString(filterStringExpression, specialPricingDeal, dealRegistrationDeal);
        filterStringExpression = removeTrailingCharactersFromFilterString(filterStringExpression);
        filterStringExpression += " " + ApplicationConstants.AndOperator + " (" + ApplicationConstants.ExternalDealVisibility + " " + ApplicationConstants.Ne + " " + false + " )";

        return filterStringExpression;
    }

    const removeTrailingCharactersFromFilterString = (filterStringExpression: string): string => {
        filterStringExpression = filterStringExpression.trim();
        if (filterStringExpression.substring(filterStringExpression.length - ApplicationConstants.AndOperator.length) == ApplicationConstants.AndOperator) {
            filterStringExpression = filterStringExpression.slice(0, -ApplicationConstants.AndOperator.length);
        }
        return filterStringExpression;
    }

    const createDealTypeFilterString = (filterStringExpression: string, specialPricingDeal: boolean, dealRegistraionDeal: boolean): string => {
        let channelType = isMsStoreUser() ? ChannelType.Direct : ChannelType.Indirect;
        if (specialPricingDeal && dealRegistraionDeal) {
            filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.SpecialPricingApplicableDeal + " " + ApplicationConstants.OrOperator + " d eq " + ApplicationConstants.DealRegistrationApplicableDeal + "))";
        }
        else if (specialPricingDeal) {
            filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.SpecialPricingApplicableDeal + "))";
        }
        else if (dealRegistraionDeal) {
            filterStringExpression += ("(") + ApplicationConstants.ProductSearchApplicableDeals + "/any(d: d eq " + ApplicationConstants.DealRegistrationApplicableDeal + "))";
        }
        filterStringExpression += " " + ApplicationConstants.AndOperator + (" (") + ApplicationConstants.ProductSearchChannelType + " " + ApplicationConstants.Eq + " " + channelType + ")";

        return filterStringExpression;
    }

    // Method called on onChange event of any child combobox (facet)
    const selectedValues = (selectedValue: any[], facetName: string): void => {
        // create filter expression based on selected facet data
        let filterStringExpression = createFilterString(selectedValue, facetName, isSpecialPricingDeal, isDealRegistraionDeal, false);
        getProductSearchData(facetName, selectedValue, 0, searchBarString, filterStringExpression, isSpecialPricingDeal);
    }

    //Set data source of facets
    const setComboBoxDatasource = (values: IFacetData[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.ProductFamilyNameFacetName:
                setPfamName(values);
                break;

            case ApplicationConstants.BusinessUnitFacetName:
                setBusinessUnit(values);
                break;

            case ApplicationConstants.SkuNameFacetName:
                setSkuName(values);
                break;

            case ApplicationConstants.SkuPartNumberFacetName:
                setSkuPartNumber(values);
                break;

            default:
                console.log("Default setSelectedValuesDatasource");
        }
    }

    //Set selected values of facets
    const setSelectedValuesDatasource = (selectedValue: any[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.ProductFamilyNameFacetName:
                setSelectedPfamNameValues(selectedValue);
                break;

            case ApplicationConstants.BusinessUnitFacetName:
                setSelectedBusinessUnitValues(selectedValue);
                break;

            case ApplicationConstants.SkuNameFacetName:
                setSelectedSkuNameValues(selectedValue);
                break;

            case ApplicationConstants.SkuPartNumberFacetName:
                setSelectedSkuPartNumberValues(selectedValue);
                break;

            default:
                console.log("Default setSelectedValuesDatasource");
        }
    }
    const onSuggest = (selectedValue: string): string => {
        let selectedProductSearchConfig = { ...productSelectionGridConfig };
        if (selectedProductSearchConfig.kendoGridProps.data === undefined) {
            selectedProductSearchConfig.kendoGridProps.data = [];
        }
        selectedProductSearchConfig = { ...businessUnitSelectionGridConfig };
        if (selectedProductSearchConfig.kendoGridProps.data === undefined) {
            selectedProductSearchConfig.kendoGridProps.data = [];
        }
        return createFilterString([], "", isSpecialPricingDeal, isDealRegistraionDeal, true);

    }
    const ProductSearchTemplate = [
        {
            type: 'Stack',
            data: {
                id: 'StackValidateProductSubmitError',
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: 'StackItem',
                        visible: !JavaScriptExtensions.isEmptyOrNullOrUndefined(pivotTab?.errors?.get(StatePropertiesText.ValidateProduct)),
                        data: {
                            align: "auto",
                            components: [
                                {
                                    type: "InnerHTMLContainer",
                                    data: {
                                        contolType: UIControlType.Label,
                                        id: "ValidateProductSubmitError",
                                        value: pivotTab?.errors?.get(StatePropertiesText.ValidateProduct),
                                        className: 'ms-LabelValueInvalid',
                                        styles: DefaultPageStyles.customWidthStyleAuto
                                    }
                                },
                            ]
                        }
                    },
                ]
            },
        },
        {
            type: "CustomTileCardComponent",
            data: {
                id: "ViewCardForProductSearch",
                baseState: commonPPContextState,
                name: "Product Search",
                iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductSearchIcon", UIControlsText.ProductSearchIcon),
                components: [
                    {
                        type: 'Stack',
                        data: {
                            id: 'StackProductGroupTextField',
                            horizontal: "true",
                            disableshrink: 'true',
                            style: DefaultPageStyles.paddingBottom16,
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        horizontal: "true",
                                        components: [
                                            {
                                                type: "InnerHTMLContainer",

                                                data: {
                                                    contolType: UIControlType.Label,
                                                    id: "ProductGroupTextField",
                                                    value: "Product Group:",
                                                    styles: DefaultPageStyles.ProductSearchWidthStyles,
                                                }
                                            },
                                            {
                                                type: "InnerHTMLContainer",
                                                styles: DefaultPageStyles.ProductSearchWidthStyles,
                                                data: {
                                                    contolType: UIControlType.Label,
                                                    id: "ProductGroupValueField",
                                                    value: productType,
                                                    styles: DefaultPageStyles.ProductSearchWidthStyles,
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'StackItem',
                                    data: {
                                        align: "auto",
                                        components: [
                                            {
                                                type: "SearchBar",
                                                data: {
                                                    ...{
                                                        store: isSpecialPricingDeal ? pfamSearchstore : buSearchstore, onSubmit, placeHolderText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search), onClear, onShowFacets, onSuggest,
                                                        searchID: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerSearchID", UIControlsText.CustomerSearchID),
                                                        showFacetsText: searchFilterButtonText,
                                                        hideFacetsButton: isSpecialPricingDeal ? false : true,
                                                        showDistinctSuggestions: true,
                                                        hideSlashFormatter: true
                                                    }
                                                }
                                            },
                                            {
                                                type: "InnerHTMLContainer",
                                                data: {
                                                    contolType: UIControlType.MessageWithDialog,
                                                    id: "InactiveProductMessage",
                                                    listdata: showDR ? inactiveBUs : inactivePFAMs,
                                                    message: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "InActiveProductErrorMessage", ErrorMessages.InActiveProductErrorMessage),
                                                    hidden: showProductDialog,
                                                    isBlocking: true,
                                                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InactiveProducts", UIControlsText.InactiveProducts),
                                                    subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InactiveProductsSubTitle", UIControlsText.InactiveProductsSubTitle),
                                                    productType: showDR ? "Business Unit" : "Product Family",
                                                }
                                            },
                                            {
                                                type: 'DialogComponent',
                                                data: {
                                                    id: 'productcasecadeDialog',
                                                    hidden: hideCasecadeMessageDialog,
                                                    isBlocking: true,
                                                    title: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "CaseCadeProductTitle", ErrorMessages.ProductsCascadeMessageHeaderText),
                                                    DialogContent: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ProductsCascadeMessage", ErrorMessages.ProductsCascadeMessage),
                                                    onDismiss: () => {
                                                       setHideCasecadeMessageDialog(true);
                                                    },
                                                }
                                            }
                                        ]
                                    }
                                },
                            ]
                        },
                    }
                ],
            },
        },
        {
            type: 'Stack',
            data: {
                horizontal: true,
                wrap: true,
                components: [
                    {
                        type: 'StackItem',
                        data: {
                            align: "auto",
                            components: [
                                {
                                    type: "GuidanceTextInformation",
                                    visible: !hideCasecadeMessageNotes,
                                    data: {
                                        id: "ProductsCascadeMessage",
                                        messageHeader: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ProductsCascadeMessageHeaderText", ErrorMessages.ProductsCascadeMessageHeaderText),
                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "ProductsCascadeMessage", ErrorMessages.ProductsCascadeMessage),
                                    }
                                }

                            ]
                        }
                    },
                ]
            },
        },

        {
            type: 'Row',
            data: {
                horizontal: true,
                disableShrink: true,
                components: [
                    {
                        type: 'Stack',
                        id: 'facetFilters',
                        visible: showProductSearchFilters,
                        data: {
                            horizontal: "true",
                            disableshrink: 'true',
                            components: [
                                {
                                    type: 'StackItem',
                                    data: {
                                        className: CssClassConstants.customerFilter,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "SearchableComboBoxComponent",
                                                data: {
                                                    ...{
                                                        facetProps: {
                                                            facetName: ApplicationConstants.ProductFamilyNameFacetName,
                                                            facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductFamilyNameFacetText", UIControlsText.ProductFamilyNameFacetText),
                                                            facetData: pfamName,
                                                            selectedData: selectedPfamNameValues,
                                                            showFacetCount: false
                                                        },
                                                        selectedValues,
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'StackItem',
                                    data: {
                                        className: CssClassConstants.customerFilter,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "SearchableComboBoxComponent",
                                                data: {
                                                    ...{
                                                        facetProps: {
                                                            facetName: ApplicationConstants.BusinessUnitFacetName,
                                                            facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "BusinessUnitFacetText", UIControlsText.BusinessUnitFacetText),
                                                            facetData: businessUnit,
                                                            selectedData: selectedBusinessUnitValues,
                                                            showFacetCount: false
                                                        },
                                                        selectedValues,
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'StackItem',
                                    data: {
                                        className: CssClassConstants.customerFilter,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "SearchableComboBoxComponent",
                                                data: {
                                                    ...{
                                                        facetProps: {
                                                            facetName: ApplicationConstants.SkuNameFacetName,
                                                            facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkuNameFacetText", UIControlsText.SkuNameFacetText),
                                                            facetData: skuName,
                                                            selectedData: selectedSkuNameValues,
                                                            showFacetCount: false
                                                        },
                                                        selectedValues,
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'StackItem',
                                    data: {
                                        className: CssClassConstants.customerFilter,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "SearchableComboBoxComponent",
                                                data: {
                                                    ...{
                                                        facetProps: {
                                                            facetName: ApplicationConstants.SkuPartNumberFacetName,
                                                            facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SkuPartNumberFacetText", UIControlsText.SkuPartNumberFacetText),
                                                            facetData: skuPartNumber,
                                                            selectedData: selectedSkuPartNumberValues,
                                                            showFacetCount: false
                                                        },
                                                        selectedValues,
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                            ]
                        },
                    },



                    {
                        type: 'Col',
                        data: {
                            type: 'Row',
                            components: [
                                {
                                    type: 'Col',
                                    data: {
                                        id: 'BUProductSearchGrid',
                                        'data-nextgen-parent-group': "Create - BU Product Search",
                                        'data-nextgen-parentid': "Create - BU Product Search",
                                        styles: stackItemStyles,
                                        align: "auto",
                                        visible: isSpecialPricingDeal ? false : true,
                                        components: [
                                            {
                                                type: "NextGenKendoGrid",
                                                data: {
                                                    ...{
                                                        kendoGridProps: { ...businessUnitGridConfig.kendoGridProps }, onRowSelectionCallBack
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'Col',
                                    data: {
                                        id: 'PFAMProductSearchGrid',
                                        'data-nextgen-parent-group': "Create - PFAM Product Search",
                                        'data-nextgen-parentid': "Create - PFAM Product Search",
                                        styles: stackItemStyles,
                                        align: "auto",
                                        visible: isSpecialPricingDeal,
                                        components: [
                                            {
                                                type: "NextGenKendoGrid",
                                                data: {
                                                    ...{
                                                        kendoGridProps: { ...productSearchGridConfig.kendoGridProps }, onDetailRowSelectionCallBack
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        type: 'Col',
                        data: {
                            type: 'Row',
                            components: [
                                {
                                    type: 'Col',
                                    data: {
                                        id: 'BUProductSelectionGrid',
                                        style: DefaultPageStyles.paddingBottom16,
                                        styles: stackItemStyles,
                                        align: "auto",
                                        visible: commonPPContextState.pageMode === PageMode.Create ? isDealRegistraionDeal : showDR,
                                        components: [
                                            {
                                                type: "NextGenKendoGrid",
                                                data: {
                                                    ...{
                                                        kendoGridProps: { ...businessUnitSelectionGridConfig.kendoGridProps }, onDeleteCallBack
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'InnerHTMLContainer',
                                    data: {
                                        id: 'InfoMessage',
                                        visible:showMessageBar,
                                        contolType: UIControlType.MessageBar,
                                        message: getMessage(invalidPfams),
                                        messageBarType: MessageBarType.info,
                                        handleDismiss: handleDismiss,
                                        styles:DefaultPageStyles.UserPromptBackground
                                    }
                                },
                                {
                                    type: 'Col',
                                    data: {
                                        id: 'PFAMProductSelectionGrid',
                                        styles: stackItemStyles,
                                        align: "auto",
                                        visible: commonPPContextState.pageMode === PageMode.Create ? isSpecialPricingDeal : showSP,
                                        components: [
                                            {
                                                type: "NextGenKendoGrid",
                                                data: {
                                                    ...{
                                                        kendoGridProps: { ...productSelectionGridConfig.kendoGridProps }, onDeleteCallBack
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                ]
            },
        }
    ];

    return ProductSearchTemplate;
}