import { AzSearchStore } from "azsearchstore";
import React, { useEffect, useState } from "react";
import { countriesFilter, getCountryObjByName, getEntityDataFromSession, isNullOrEmpty, readGridConfig, getRuleValidationMessage } from "../../../../components/shared/common/util";
import { UIControlsText } from "../../../../content/UIControlsText";
import { GridType } from "../../../../models/GridType";
import { IGridProps } from "../../../../components/shared/grid/IGridProps";
import { DealDetailsStyles, PageStyles } from "../../common/content/Page.Styles";
import { ApplicationConstants } from "../../../../models/ApplicationConstants";
import { nextGenService } from "../../../../services/NextGenService";
import _, { cloneDeep } from "lodash";
import { IDropdownOption, IHoverCard, IPlainCardProps, IStyle } from "office-ui-fabric-react";
import { stackItemStyles } from "../PricingProgramFormUtils";
import { DefaultPageStyles, _Styles } from "../../../../content/styles/Page.styles";
import { RadioGridCell } from "../../../../components/shared/grid/radioGridCell";
import { useCommonPPContext } from '../../common/context/common-pp-context';
import { ILegalEntity } from "../../../../models/ILegalEntity";
import { CssClassConstants } from "../../../../content/CssClassConstants";
import { IDealType } from "../../../../models/IDealType";
import { SessionStorageConsts } from "../../../../models/SessionStorageConsts";
import { DealType } from "../../../../models/DealType";
import { Guid } from "../../../../infrastructure/Guid";
import { CaseActionTypes } from "../../common/reducer/CaseActionTypes";
import { OpportunityDealStatusCodes } from "../../../../models/OpportunityDealStatusCodes";
import { IDealState } from "../../common/state/IDealState";
import { ActionType } from "../../../../models/ActionType";
import { CustomerType } from "../../../../models/CustomerType";
import { CustomerStatusCodes } from "../../../../models/CustomerStatusCodes";
import CreateUnmanagedCustomer from "../../Customer/createUnmanagedCustomer";
import { UIControlType } from "../../../../models/UIControlType";
import { PanelType } from "@fluentui/react";
import { PageMode } from "../../../../models/PageMode";
import { ErrorMessages } from "../../../../content/ErrorMessages";
import { GetCustomerViewJson } from "../customerDetail";
import { IFacetData } from "../../../../models/IFacetData";
import { ChannelSubType } from "../../../../models/ChannelSubType";
import { IPivotTab } from "../../../../models/IPivotTab";
import { GridPageChangeEvent } from "@progress/kendo-react-grid";
import { IPageState } from "../../../../components/shared/grid/IPageState";
import { backToDashboard } from "../../../../components/shared/common/util";
import { MessageTypeText } from '../../../../models/MessageTypeText';

export function CustomerSearch() {
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);
    const [searchFilterButtonText, setSearchFilterButtonText] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter));
    const { commonPPContextState, commonPPContextDispatch } = useCommonPPContext();
    const [customerSearchGridConfig, setCustomerSearchGridConfig] = useState<IGridProps>(readGridConfig(GridType.CustomerSearch));
    const [selectedCustomer, setSelectedCustomer] = useState<ILegalEntity>();
    const [selectedCountries, setSelectedCountries] = useState<IDropdownOption[]>([]);
    const [selectedSearchValue, setSelectedSearchValue] = useState<string>("");
    const [countriesDropDownData, setCountriesDropdownData] = useState(countriesFilter());
    const [isOpenCasePanel, setIsOpenCasePanel] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
    const [loadingData, setLoadingData] = useState(false);
    const [hideCustomerDialog, setHideCustomerDialog] = useState(true);
    const [customerMessage, setCustomerMessage] = useState<string>("");
    const [customerName, setCustomerName] = useState<string>("");
    var customerJson = [{}];
    const [customerOption, setCustomerOption] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search));
    const [customerDetail, SetCustomerDetail] = useState<ILegalEntity>();
    const [hideUnManagedCustomerEdit, SetHideUnManagedCustomerEdit] = useState<boolean>(true);
    const [customerGridData, setCustomerGridData] = useState<ILegalEntity[]>([]);
    let allFacetNames = ApplicationConstants.CustomerSearchFilterFacetNames;
    const [dunsid, setDunsid] = useState<IFacetData[]>([]);
    const [selectedDunsidValues, setSelectedDunsidValues] = useState<IFacetData[]>([]);
    const [selectedPricingPrograms, setselectedPricingPrograms] = useState<IDealType[]>([]);
    const [resetPaginationToDefault, setResetPaginationToDefault] = useState<IPageState>();
    const [showCustomerGrid, setShowCustomerGrid] = useState(false);
    const [hideCustomerSearchFilterBtn, setHideCustomerSearchFilterBtn] = useState(true);
    const [isCountryDisabled, setIsCountryDisabled] = useState(false);
    
    useEffect(() => {
        if (commonPPContextState.pageMode !== PageMode.Create) {
            setLoadingData(true);
        }
        const oppDealNumber = commonPPContextState.selectedOptyDealCaseNumber;
        if (commonPPContextState.name && oppDealNumber && oppDealNumber.length > 0) {
            var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0];
            if (oppDeal) {
                if (oppDeal.statusCode === OpportunityDealStatusCodes.Draft && commonPPContextState.pageMode === PageMode.Edit) {
                    //validate customer status on edit and draft status, else if page mode is edit and no customer exists disable country
                    var customers = oppDeal.dealCustomers;
                    if (customers && customers?.length > 0) {
                        var isCustomerActive = commonPPContextState.isCustomerActive;
                        var customerDecision = commonPPContextState.isUnManagedCustomerDecisionMade;
                        if (customers && customers?.length > 0) {
                            if (!customers[0].isManaged && !customerDecision) {
                                SetHideUnManagedCustomerEdit(false);
                            }
                        }
                        if (!isCustomerActive) {
                            setHideCustomerDialog(false);
                            if (customers) {
                                setCustomerName(customers[0].legalEntityName);
                                if (customers[0].isManaged) {
                                    setCustomerMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InActiveManagedCustomerMessage", ErrorMessages.InActiveManagedCustomerMessage))
                                }
                                else {
                                    setCustomerMessage(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InActiveUnManagedCustomerMessage", ErrorMessages.InActiveUnManagedCustomerMessage))
                                }
                            }
                            // to show customer tab on error 
                        }
                    }
                }
                else if (oppDeal.statusCode !== OpportunityDealStatusCodes.Draft && commonPPContextState.pageMode === PageMode.Edit) {
                    if (oppDeal.dealCustomers && oppDeal.dealCustomers.length > 0) {
                        var dealCustomer = oppDeal.dealCustomers[0];
                        if (dealCustomer) {
                            SetCustomerDetail(dealCustomer);
                        }
                    }
                    setCustomerOption(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetails", UIControlsText.CustomerDetails));
                }
                setLoadingData(false);
                if (oppDeal.dealCustomers && oppDeal.dealCustomers?.length > 0) {
                    setSelectedCustomer(oppDeal.dealCustomers[0]);
                }
                else {
                    setSelectedCustomer(undefined);
                }
            }
            // to populate selected deal from api input
            if (commonPPContextState.opportunityDeals) {
                var selectedDeals: IDealType[] = [];
                var allDeals = getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes);
                if (commonPPContextState.opportunityDeals.length > 1) {
                    selectedDeals = allDeals.filter(t => t?.name?.toLowerCase() === DealType.DealRegistration.toLowerCase() || t?.name?.toLowerCase() === DealType.SpecialPricing.toLowerCase());
                }
                else {
                    var oppDeal = commonPPContextState.opportunityDeals.filter(d => d.name === oppDealNumber)[0];
                    selectedDeals = allDeals.filter(t => t?.name?.toLowerCase() === oppDeal.dealType?.name?.toLowerCase());
                }
                setselectedPricingPrograms(selectedDeals);
            }

            if(commonPPContextState.pageMode === PageMode.Edit) {
                setIsCountryDisabled(true);
            }
        }
    }, [commonPPContextState.name, commonPPContextState.selectedOptyDealCaseNumber]);

    //#region Azure store config settings
    const store = new AzSearchStore();
    store.setConfig({
        index: ApplicationConstants.PricingProgramCustomerSearchIndex,
        queryKey: process.env.REACT_APP_SEARCH_API_KEY || "",
        service: process.env.REACT_APP_SEARCH_API_ENV || ""
    });

    store.updateSuggestionsParameters({
        suggesterName: ApplicationConstants.PricingProgramCustomerCustomerSuggesterName,
        select: ApplicationConstants.CustomerSearchSelectColumn,
        top: ApplicationConstants.TopSearchSuggesstions,
        fuzzy: ApplicationConstants.IsFuzzy,
        filter: "",
    });
    const suggestionsProcessor = function (results: any) {
        return results.map(function (result: any) {
            result.searchText = result["@search.text"];
            return result;
        });
    };
    store.setSuggestionsProcessor(suggestionsProcessor);
    //#endregion

    //#region Get/Set Customer Data 
    const onSubmit = (selectedValue: string) => {
        setResetPaginationToDefault({ ...{ skip: 0, take: customerSearchGridConfig.kendoGridProps.noOfRecords } as IPageState });
        setSelectedSearchValue(selectedValue);
        setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter))
        getCustomerSearchData(selectedValue, 0, selectedDunsidValues);
    };
    //create filter experssion for Search API for customer search Index
    const createFilterExpression = (filterNames: string[], filterValues: any[], euFilter: boolean): string => {
        let filterStringExpression = "";
        for (let i = 0; i < filterValues.length; i++) {
            if (filterStringExpression.length > 0) {
                filterStringExpression = filterStringExpression + " " + ApplicationConstants.AndOperator + " ";
            }
            if (!isNullOrEmpty(filterValues[i])) {
                filterStringExpression = filterStringExpression + "(" + filterValues[i].map((item: string) => " " + filterNames[i] + " " + ApplicationConstants.Eq + " '" + item + "' ").join(" " + ApplicationConstants.OrOperator + " ") + ") ";
            }
        }

        return filterStringExpression;
    }
    // Gets the customer Data and binds with unique records
    const getCustomerSearchData = async (selectedValue: string, skip: number, selectedFacetValues: any[]): Promise<void> => {
        try {
            setLoadingData(true);
            let filterStringExpression = "";
            if (selectedCountries.length > 0) {
                store.setGlobalFilter(ApplicationConstants.CustomerSearchCountryGlobalIndex, selectedCountries.map(item => ApplicationConstants.CustomerSearchCountryGlobalIndex + " " + ApplicationConstants.Eq + " '" + item.text + "'").join(" " + ApplicationConstants.OrOperator + " "));
                if (filterStringExpression.length == 0) {
                    filterStringExpression = createFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex], [selectedCountries.map(item => item.text)], false);
                }
            }
            if (!isNullOrEmpty(selectedFacetValues) && selectedFacetValues?.length > 0) {
                filterStringExpression = filterStringExpression.length > 0 ? filterStringExpression + " " + ApplicationConstants.AndOperator + " " : "";
                filterStringExpression = filterStringExpression + createFilterExpression([ApplicationConstants.DunsNumberFacetName], [selectedFacetValues?.map(item => item.value)], false);
            }
            let facetExpression = facetList(allFacetNames);
            let legalEntities: ILegalEntity[] = [];
            let apiResults: any = [];
            let searchConfig = { ...customerSearchGridConfig };
            searchConfig.kendoGridProps.defaultMainGridSelectionstate = {};
            if (isNullOrEmpty(selectedValue)) { // if Search value is empty and user fetching based on country get distinct results
                let apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramAllDistinctCustomerSearchResults(filterStringExpression, selectedValue, skip, ApplicationConstants.CognitiveSearchMode));
                if (apiReturnedData.status === 200) {
                    let customerSearchResultsData = [...(apiReturnedData.data as { [key: string]: any })["value"] as string];
                    let customerCount = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                    apiResults = [...customerSearchResultsData]
                    searchConfig.kendoGridProps.totalRecords = customerCount;
                    searchConfig.kendoGridProps.isServerSideGrid = true;
                    setHideCustomerSearchFilterBtn(true);
                }
            } else {
                selectedValue = selectedValue.concat("*"); // first time search the selected value with  contains options
                let apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramCustomerSearchResults(filterStringExpression, selectedValue, skip, facetExpression, ApplicationConstants.CognitiveSearchMode, ApplicationConstants.CustomerSearchTopThreshold));
                if (apiReturnedData.status === 200) {
                    let customerCount = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                    if (customerCount === 0) {
                        selectedValue = selectedValue.slice(0, -1); // if first call results 0 results then second  time search the selected value with  exact options
                        apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramCustomerSearchResults(filterStringExpression, selectedValue, skip, facetExpression, ApplicationConstants.CognitiveSearchMode, ApplicationConstants.CustomerSearchTopThreshold));
                        customerCount = (apiReturnedData.data as { [key: string]: any })["@odata.count"] as number;
                    }

                    let customerSearchResultsData = [...(apiReturnedData.data as { [key: string]: any })["value"] as string];
                    if (customerCount > customerSearchResultsData.length) {
                        for (
                            let i = 1;
                            i < Math.ceil(customerCount / ApplicationConstants.CustomerSearchTopThreshold);
                            i++
                        ) {
                            apiReturnedData = await Promise.resolve(nextGenService.getPricingProgramCustomerSearchResults(filterStringExpression, selectedValue, i * ApplicationConstants.CustomerSearchTopThreshold, facetExpression, ApplicationConstants.CognitiveSearchMode, ApplicationConstants.CustomerSearchTopThreshold));
                            customerSearchResultsData.push(...(apiReturnedData.data as { [key: string]: any })["value"] as string);
                        }
                    }
                    //Managed Records get distinct records based on Org Name and TPID
                    let managedRecords = customerSearchResultsData.filter((x: any) => x.IsManagedAccount === true);
                    apiResults.push(
                        ...new Map(managedRecords.map((item: any) => [item[ApplicationConstants.CustomerSearchSelectColumn] || item[ApplicationConstants.TPIDFacetName], item])).values(),
                    );
                    //Un Managed Records get distinct records based on Org Name 
                    let unManagedRecords = customerSearchResultsData.filter((x: any) => x.IsManagedAccount === false);
                    apiResults.push(
                        ...new Map(unManagedRecords.map((item: any) => [item[ApplicationConstants.CustomerSearchSelectColumn], item])).values(),
                    );

                    if (selectedFacetValues?.length === 0) {
                        for (var i = 0; i < allFacetNames.length; i++) {
                            setFilterDatasource((apiReturnedData.data as { [key: string]: any })["@search.facets"][allFacetNames[i]] as string[], allFacetNames[i]);
                        }
                    }
                }
                searchConfig.kendoGridProps.totalRecords = apiResults.length;
                searchConfig.kendoGridProps.isServerSideGrid = false;
                apiResults.length > 0 ? setHideCustomerSearchFilterBtn(false) : setHideCustomerSearchFilterBtn(true);
            }
            legalEntities = apiResults.map((item: any) => {
                return {
                    id: item.id,
                    customerId: item.id,
                    country: item.Country,
                    crmAccountId: item.ChildCRMID,
                    legalEntityName: item.OrganizationName,
                    tpid: item.TPID === null ? 0 : item.TPID,
                    segment: item.Segment,
                    vertical: item.Vertical,
                    verticalCategory: item.VerticalCategory,
                    unitsCommitted: item.unitsCommitted === undefined ? 0 : item.unitsCommitted,
                    isManaged: item.IsManagedAccount,
                    customerType: CustomerType.Primary,
                    reviewDecision: item.ReviewDecision != null ? item.ReviewDecision : CustomerStatusCodes.Approved,
                    dunsNumber: item.DunsNumber,
                    action: ActionType.Add
                } as ILegalEntity
            });
            searchConfig.kendoGridProps.data = [...legalEntities.sort((a, b) => {
                return (a.legalEntityName.toLowerCase() > b.legalEntityName.toLowerCase()) ? 1 : -1;
            })];
            setSelectedCustomer(undefined);
            setCustomerGridData([...legalEntities.sort()]);
            if (searchConfig.kendoGridProps.actionTypes?.showAddNewPanelGridAction != null) {
                searchConfig.kendoGridProps.actionTypes.showAddNewPanelGridAction = true;
            }
            setCustomerSearchGridConfig({ ...searchConfig });
            if (!isNullOrEmpty(selectedCustomer)) {
                setSelectedCustomer(selectedCustomer);
            }
            setCustomerSearchGridConfig({ ...searchConfig });
            setLoadingData(false);
            setShowCustomerGrid(true);

        } catch (error) {
            setLoadingData(false);
            setShowCustomerGrid(true);
            console.log(error);
        }
    }
    const onPageChange = (event: GridPageChangeEvent): void => {
        store.setInput(selectedSearchValue);
        setResetPaginationToDefault({ ...{ skip: event.page.skip, take: customerSearchGridConfig.kendoGridProps.noOfRecords } as IPageState });
        if (selectedSearchValue === "" && selectedSearchValue.length === 0) {
            getCustomerSearchData(selectedSearchValue, event.page.skip, selectedDunsidValues);
        }

    };

    //Set data source of facets
    const setFilterDatasource = (values: any[], facetType: string): void => {
        switch (facetType) {
            case ApplicationConstants.DunsNumberFacetName:
                setDunsid(values);
                break;
            default: console.log("Default setSelectedValuesDatasource");
        }
    }
    const facetList = (facetNames: string[]): string[] => {
        let facetCount = ApplicationConstants.FacetCount;
        let facetString: string[] = [];

        for (let i = 0; i < facetNames.length; i++) {
            facetString.push(facetNames[i] + ",count:" + facetCount);
        }

        return facetString;
    }
    const onClear = () => {
        if (customerSearchGridConfig.kendoGridProps.actionTypes?.showAddNewPanelGridAction != null) {
            customerSearchGridConfig.kendoGridProps.actionTypes.showAddNewPanelGridAction = false;
        }
        customerSearchGridConfig.kendoGridProps.data = [];
        customerSearchGridConfig.kendoGridProps.totalRecords = 0;
        setCustomerSearchGridConfig({ ...customerSearchGridConfig });
        setSelectedCustomer(undefined);
        setSelectedDunsidValues([]);
        setselectedPricingPrograms([]);
        setShowCustomerSearchFilters(false);
        setHideCustomerSearchFilterBtn(true);
        commonPPContextDispatch({ type: CaseActionTypes.pricingProgramSelectionChanged, opportunityDeals: [] });
    };
    //#endregion

    //#region Render customer search grid with single selection
    const onCustomCellRenderCallBack = (selectedRowData: any) => {
        let option = [{ key: selectedRowData.dataItem.id, text: "", ariaLabel: "pick one" }];
        return (
            <RadioGridCell
                cellId={selectedRowData.dataItem.id}
                options={option}
                selectedOption={selectedCustomer?.id}
                itemSelectionUpdate={onCustomerSelectionHandler}
            />
        );
    }
    //#endregion

    //#region  set context on selection of customer

    const onCustomerSelectionHandler = (cellId: string, selectedValue: any): void => {
        let searchConfig = { ...customerSearchGridConfig };
        let customerData: ILegalEntity = searchConfig.kendoGridProps.data.filter((x: { id: Guid; }) => x.id === selectedValue)[0];
        setSelectedCustomer({ ...customerData });
        setDealCustomerData();

        if (commonPPContextState.opportunityDeals[0].statusCode === OpportunityDealStatusCodes.Draft && commonPPContextState.pageMode === PageMode.Edit && commonPPContextState.opportunityDeals[0].dealType != undefined) {
            customerEdit(customerData);
        }
        else {
            setIsOpenCasePanel(true);
        }
    }

    const setDealCustomerData = () => {
        if (!isNullOrEmpty(selectedCustomer) && selectedCustomer != undefined && !isNullOrEmpty(commonPPContextState)) {
            commonPPContextState.opportunityDeals.forEach(x => {
                x.customerName = selectedCustomer.legalEntityName;
                x.customerCountry = selectedCustomer.country;
            });
        }
    }

    const openPricingProgram = () => {
        if (commonPPContextState.pageMode !== PageMode.Edit) {
            setIsOpenCasePanel(true);
        }
        else {
            commonPPContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText) });
        }
    }

    const onAddNewCustomerSelectionHandler = (customerEntity: ILegalEntity) => {
        customerEntity.reviewDecision = CustomerStatusCodes.Draft;
        customerEntity.action = ActionType.Add;
        customerEntity.customerType = CustomerType.Primary;
        setSelectedCustomer({ ...customerEntity });
        setSelectedDunsidValues([]);
        
        if (commonPPContextState.pageMode !== PageMode.Edit) {
            setselectedPricingPrograms([]);
            setIsOpenCasePanel(true);
        }
        else {
            customerEdit(customerEntity);
        }
        closePanel();
    }

    const customerEdit = (customer: ILegalEntity) => { 
        let opportunityDeal = commonPPContextState.opportunityDeals[0];
        let selectedCustomerCountry = getCountryObjByName(customer?.country || '');

        setSelectedCustomer(customer);
        if (selectedCustomer != undefined) {
            selectedCustomer.legalEntityName = customer.legalEntityName;
        }

        let domain = opportunityDeal.dealCustomers && opportunityDeal.dealCustomers.length ? opportunityDeal.dealCustomers[0].dealCustomerDomain : "";
        opportunityDeal.dealCustomers = customer != undefined ? [{ ...customer, ...{ dealCustomerDomain: domain } }] : [];
        opportunityDeal.customerName = customer?.legalEntityName;
        opportunityDeal.dealCustomerCountry = selectedCustomerCountry?.countryId;
        opportunityDeal.geoCountries = selectedCustomerCountry !== undefined ? [{ ...selectedCustomerCountry, action: ActionType.Add }] : [];
        setSelectedCustomer(opportunityDeal.dealCustomers[0]);

        if (opportunityDeal.dealCustomers[0].isManaged === true) {
            SetHideUnManagedCustomerEdit(true);
        }
    }

    const handlePricingProgramSelection = (pricingPrograms: IDealType[]) => {
        commonPPContextDispatch({ type: CaseActionTypes.pricingProgramSelectionChanged, opportunityDeals: [{}] });
        const opportunityDeals: IDealState[] = [];
        let selectedCustomerCountry = getCountryObjByName(selectedCustomer?.country || '');
        pricingPrograms.forEach(dealType => {
            let opportunityDeal: IDealState = commonPPContextState.opportunityDeals.filter(x => x.dealType?.id == dealType.id)[0];
            if (!isNullOrEmpty(opportunityDeal)) { //modify customer details
                let domain = opportunityDeal.dealCustomers && opportunityDeal.dealCustomers.length ? opportunityDeal.dealCustomers[0].dealCustomerDomain : "";
                opportunityDeal.dealCustomers = selectedCustomer != undefined ? [{ ...selectedCustomer, ...{ dealCustomerDomain: domain } }] : [];
                opportunityDeal.customerName = selectedCustomer?.legalEntityName;
                opportunityDeal.dealCustomerCountry = selectedCustomerCountry?.countryId;
                opportunityDeal.dealType = { ...dealType };
                opportunityDeal.geoCountries = selectedCustomerCountry !== undefined ? [{ ...selectedCustomerCountry, action: ActionType.Add }] : [];
                opportunityDeals.push(opportunityDeal);
            }

            else { // add new opportunity
                let newoppDeal: IDealState = {
                    id: Guid.newGuid(),
                    dealType: dealType,
                    statusCode: OpportunityDealStatusCodes.Draft,
                    submitterDetails: commonPPContextState.submitterDetails,
                    geoCountries: selectedCustomerCountry !== undefined ? [{ ...selectedCustomerCountry, action: ActionType.Add }] : [],
                    dealCustomers: selectedCustomer != undefined ? [{ ...selectedCustomer }] : []
                };

                opportunityDeal = commonPPContextState.opportunityDeals[0];
                if (!!opportunityDeal) {
                    let domain = opportunityDeal.dealCustomers && opportunityDeal.dealCustomers.length ? opportunityDeal.dealCustomers[0].dealCustomerDomain : "";
                    newoppDeal.dealAddress = opportunityDeal.dealAddress;
                    newoppDeal.dealVertical = opportunityDeal.dealVertical;
                    newoppDeal.dealName = opportunityDeal.dealName;
                    newoppDeal.dealOverview = opportunityDeal.dealOverview;
                    newoppDeal.dealVolume = opportunityDeal.dealVolume;
                    newoppDeal.dealCustomers?.forEach(x => x.dealCustomerDomain = domain); 
                    newoppDeal.customerName = selectedCustomer?.legalEntityName;
                    newoppDeal.dealCustomerCountry = selectedCustomerCountry?.countryId;
                }

                opportunityDeals.push(newoppDeal);
            }
        });
        commonPPContextDispatch({ type: CaseActionTypes.pricingProgramSelectionChanged, opportunityDeals: opportunityDeals });
        commonPPContextDispatch({ type: CaseActionTypes.casePivotChanged, selectedPivot: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealInformationText", UIControlsText.DealInformationText) });
        commonPPContextDispatch({ type: CaseActionTypes.pricingProgramDealType, applicableDealTypes: pricingPrograms });
        if (commonPPContextState.opportunityDeals.filter(x => x.name === commonPPContextState.selectedOptyDealCaseNumber)?.length === 0) {
            commonPPContextDispatch({ type: CaseActionTypes.SelectedDealChanged, selectedDealNumber: (commonPPContextState.opportunityDeals.length > 0 && commonPPContextState.opportunityDeals[0].name) });
        }

        setselectedPricingPrograms(pricingPrograms);
        setIsOpenCasePanel(false);
    }
    const handlePricingProgramSelectionCancel = () => {
        let existingCustomer = commonPPContextState.opportunityDeals.filter(x => x.dealCustomers)[0];
        if (!isNullOrEmpty(existingCustomer)) {
            setSelectedCustomer(existingCustomer?.dealCustomers != undefined ? existingCustomer.dealCustomers[0] : undefined);
        }
        else {
            setSelectedCustomer(undefined);
        }
        setIsOpenCasePanel(false);
    }
    //#endregion

    //#region on counrty selection reset customer grid.
    const onCountrySelectionHandler = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedCountry(item?.key.toString());
        if (!isNullOrEmpty(item)) {
            setSelectedCountries([item as IDropdownOption]);
            if (customerSearchGridConfig.kendoGridProps.actionTypes?.showAddNewPanelGridAction != null) {
                customerSearchGridConfig.kendoGridProps.actionTypes.showAddNewPanelGridAction = false;
            }
            customerSearchGridConfig.kendoGridProps.data = [];
            customerSearchGridConfig.kendoGridProps.totalRecords = 0;
            setCustomerSearchGridConfig({ ...customerSearchGridConfig });
            let pivotTabs: IPivotTab[] = cloneDeep(commonPPContextState.pivotTabs) || [];
            commonPPContextState.pivotTabs?.slice(1).forEach((item) => (item.isDisabled = true));
            pivotTabs.slice(1).forEach((item) => (item.isDisabled = true));
            commonPPContextDispatch({ type: CaseActionTypes.pivotTabData, pivotTabDetails: pivotTabs });
        } else {
            setSelectedCountries([]);
        }
    }
    //#endregion

    const [showCustomerSearchFilters, setShowCustomerSearchFilters] = useState<boolean>(false)
    //hide or show facets on click of button will be included
    const onShowFacets = () => {
        if (!showCustomerSearchFilters) {
            setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HideSearchFilter", UIControlsText.HideSearchFilter));
            setShowCustomerSearchFilters(true);
        }
        else {
            setSearchFilterButtonText(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ShowSearchFilter", UIControlsText.ShowSearchFilter));
            setShowCustomerSearchFilters(false);
        }
    }

    const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false)
    //add new customer component panel will be added here
    const onAddNewPanelCallBack = () => {
        setShowAddCustomer(true);
    }
    const closePanel = () => {
        setShowAddCustomer(false);
    }


    /* On partner location change countries dropdown will be reset */
    useEffect(() => {
        let countries = countriesFilter(commonPPContextState.partnerDetails?.countryId);
        setCountriesDropdownData(countries);
        var countryId = "";

        if (commonPPContextState.pageMode === PageMode.Create) {
            countryId = commonPPContextState.partnerDetails?.countryId || "";
        }
        else {
            var requester = commonPPContextState.opportunityAccounts?.filter(acc => acc.channelSubType === ChannelSubType.Requester)[0];
            if (requester) {
                countryId = requester.countryId || "";
            }
        }
        let selectedPartnerCustomerCountry = countries?.filter(x => x.countryId === countryId)
        if (!isNullOrEmpty(selectedPartnerCustomerCountry) && selectedPartnerCustomerCountry.length > 0) {
            setSelectedCountries([{ key: selectedPartnerCustomerCountry[0]?.countryId, text: selectedPartnerCustomerCountry[0]?.countryName } as IDropdownOption])
        }
        setSelectedCountry(countryId);
    }, [commonPPContextState.partnerDetails?.countryId, commonPPContextState.selectedOptyDealCaseNumber]);

    // Method called on onChange event of any child combobox (facet)
    const selectedValues = async (selectedValue: any[], facetName: string): Promise<void> => {
        try {
            setSelectedDunsidValues(selectedValue)
            getCustomerSearchData(selectedSearchValue, 0, selectedValue);
        }
        catch (error) {
            console.log(error);
        }
    }

    // open dialog to edit un-managed customer 
    const triggerEditCustomer = () => {
        setShowAddCustomer(true);
    }
    const onSuggest = (selectedValue: string): string => {
        let filterStringExpression = "";
        if (selectedCountries.length > 0) {
            store.setGlobalFilter(ApplicationConstants.CustomerSearchCountryGlobalIndex, selectedCountries.map(item => ApplicationConstants.CustomerSearchCountryGlobalIndex + " " + ApplicationConstants.Eq + " '" + item.text + "'").join(" " + ApplicationConstants.OrOperator + " "));
            if (filterStringExpression.length == 0) {
                filterStringExpression = createFilterExpression([ApplicationConstants.CustomerSearchCountryGlobalIndex], [selectedCountries.map(item => item.text)], false);
            }
        }
        return filterStringExpression;

    }
    const CustomerSearchJson = [
        {
            type: "Stack",
            data: {
                id: "StackForCustomerSearch",
                components: [
                    {
                        type: "CustomTileCardComponent",
                        data: {
                            id: "ViewCardCustomer",
                            baseState: commonPPContextState,
                            name: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetails", UIControlsText.CustomerDetails),
                            iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetailsIcon", UIControlsText.CustomerDetailsIcon),
                            components: [
                                {
                                    type: 'Stack',
                                    data: {
                                        horizontal: true,
                                        wrap: true,
                                        components: [
                                            {
                                                type: 'StackItem',
                                                data: {
                                                    //style: DefaultPageStyles.padding8,
                                                    align: "auto",
                                                    components: [
                                                        {
                                                            type: "GuidanceTextInformation",
                                                            visible: commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit,
                                                            data: {
                                                                id: "CustomerSearchGuidance",
                                                                label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CustomerSearchGuidance", UIControlsText.CustomerSearchGuidance),
                                                            }
                                                        }

                                                    ]
                                                }
                                            },
                                        ]
                                    },
                                },
                                {
                                    type: 'Row',
                                    data: {
                                        horizontal: "true",
                                        disableshrink: 'true',
                                        components: [
                                            {
                                                type: 'Col',
                                                data: {
                                                    styles: stackItemStyles,
                                                    align: "auto",
                                                    visible: selectedCustomer != undefined ? true : false,
                                                    components: [
                                                        {
                                                            type: "InnerHTMLContainer",
                                                            data: {
                                                                id: "SelectedCustomerInfo",
                                                                contolType: UIControlType.CustomerTable,
                                                                selectedCustomer: selectedCustomer,
                                                                showEdit: hideUnManagedCustomerEdit,
                                                                editEvent: triggerEditCustomer
                                                            }
                                                        },
                                                        {
                                                            type: 'DialogComponent',
                                                            data: {
                                                                id: 'invalidCustomerDialog',
                                                                hidden: hideCustomerDialog,
                                                                isBlocking: true,
                                                                title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "InactiveCustomer", UIControlsText.InactiveCustomer),
                                                                subText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "SelectedCustomer", UIControlsText.SelectedCustomer) + customerName,
                                                                DialogContent: customerMessage,
                                                                onDismiss: () => {
                                                                    setHideCustomerDialog(true);
                                                                },
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                        ]

                                    },
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: 'Paper',
            visible: (commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit),
            data: {
                id: 'CustomerSearchPaper',
                components: [
                    {
                        type: 'Row',
                        data: {
                            id: 'CustomerSearchTab',
                            style: DefaultPageStyles.padding8,
                            horizontal: "true",
                            disableshrink: 'true',
                            components: [
                                {
                                    type: 'Col',
                                    data: {
                                        styles: stackItemStyles,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "Dropdown",
                                                data: {
                                                    id: "CustomerCountryDropdown",
                                                    label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerCountryText", UIControlsText.CustomerCountryText),
                                                    options: (countriesDropDownData && countriesDropDownData.map((pt) => ({ key: pt.countryId, text: pt.countryName } as IDropdownOption))) || [],
                                                    styles: PageStyles.dropdownStyles,
                                                    className: _Styles.dropdown,
                                                    placeholder: "Select the Customer Country",
                                                    onChange: onCountrySelectionHandler,
                                                    selectedKey: selectedCountry,
                                                    disabled: isCountryDisabled
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'Col',
                                    data: {
                                        styles: stackItemStyles,
                                        align: "auto",
                                        components: [
                                            {
                                                type: "SearchBar",
                                                data: {
                                                    ...{
                                                        store: store, onSubmit, placeHolderText: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search), onClear, onShowFacets, onSuggest,
                                                        searchID: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerSearchID", UIControlsText.CustomerSearchID),
                                                        showFacetsText: searchFilterButtonText,
                                                        hideFacetsButton: hideCustomerSearchFilterBtn,
                                                        overrideCss: CssClassConstants.CustomerSearchBoxContainer,
                                                        hideSearchBtn: selectedCountries.length > 0 ? false : true,
                                                        showDistinctSuggestions: true,
                                                        hideSlashFormatter: true
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'Col',
                                    data: {
                                        styles: stackItemStyles,
                                        align: "auto",
                                        components: [
                                            {
                                                //type: "PrimaryButton", // will be enabled after partner screen is ready
                                                //data: {
                                                //    text: UIControlsText.PartnerDetails,
                                                //    className: "mt-4"
                                                //}
                                            }
                                        ]
                                    }
                                },
                            ]
                        }
                    }
                ]
            }
        },
        {
            type: 'Row',
            visible: commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit,
            data: {
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: 'Col',
                        data: {
                            style: DefaultPageStyles.padding8,
                            styles: stackItemStyles,
                            align: "auto",
                            components: [
                                {
                                    type: "GuidanceTextInformation",
                                    visible: commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit,
                                    data: {
                                        id: "CustomerSearchResultsGuidance",
                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "CustomerSearchResultsGuidance", UIControlsText.CustomerSearchResultsGuidance),
                                        styles: PageStyles.textFieldStylesTwoColumn,
                                    }
                                }
                            ]
                        }
                    },

                ]

            },
        },
        {
            type: 'Stack',
            visible: showCustomerSearchFilters && (commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit),
            data: {
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: 'StackItem',
                        data: {
                            styles: PageStyles.dropdownStyles,
                            className: CssClassConstants.customerFilter,
                            align: "auto",
                            components: [
                                {
                                    type: "SearchableComboBoxComponent",
                                    data: {
                                        ...{
                                            facetProps: {
                                                facetName: ApplicationConstants.DunsNumberFacetName,
                                                facetLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DunsNumberFacetText", UIControlsText.DunsNumberFacetText),
                                                facetData: dunsid,
                                                selectedData: selectedDunsidValues,
                                                showFacetCount: false
                                            },
                                            selectedValues
                                        }
                                    }
                                }
                            ]
                        }
                    },

                ]

            },
        },
        {
            type: 'Row',
            visible: (commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit),
            data: {
                horizontal: "true",
                style: DefaultPageStyles.padding8,
                disableshrink: 'true',
                components: [
                    {
                        type: 'Col',

                        data: {
                            styles: stackItemStyles,
                            align: "auto",
                            id: 'customersearch',
                            'data-nextgen-parent-group': "Create - Customer Search Details",
                            'data-nextgen-parentid': "Create - Customer Search Details",
                            components: [
                                {
                                    type: 'Spinner',
                                    visible: loadingData,
                                    data: {
                                        id: 'SpinnerCusomerSearchSection',
                                        label: 'Loading...'
                                    }
                                },
                                {
                                    type: "NextGenKendoGrid",
                                    visible: showCustomerGrid,
                                    id: 'CustomerSearchResultsGrid',
                                    data: {
                                        ...{
                                            kendoGridProps: { ...customerSearchGridConfig.kendoGridProps }, onPageChange, onCustomCellRenderCallBack, onAddNewPanelCallBack, resetPaginationToDefault: resetPaginationToDefault
                                        }
                                    }
                                }
                            ]
                        }
                    },

                ]

            },
        },
        {
            type: 'Row',
            visible: (commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit),
            data: {
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: 'Col',
                        data: {
                            styles: stackItemStyles,
                            align: "auto",
                            components: [
                                {
                                    type: 'PrimaryButton',
                                    data: {
                                        id: 'PricingProgBtn',
                                        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText),
                                        styles: PageStyles.commandButtonPadding,
                                        title: UIControlsText.ContinueText,
                                        onClick: openPricingProgram,
                                        visible: selectedCustomer != undefined ? true : false
                                    }
                                },
                                {
                                    type: 'PrimaryButton',
                                    data: {
                                        id: 'PricingProgCancelBtn',
                                        text: UIControlsText.CancelText,
                                        styles: PageStyles.commandButtonPadding,
                                        title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText),
                                        onClick: backToDashboard
                                    }
                                },
                            ]
                        },
                    }
                ]

            },
        },
        {
            type: 'Stack',
            visible: (selectedCustomer != undefined ? true : false) && (commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit),
            data: {
                id: 'PricingProgromSelection',
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: 'StackItem',
                        data: {
                            id: 'StackItemPricingProgromSelection',
                            styles: stackItemStyles,
                            align: "auto",
                            components: [
                                {
                                    type: "PricingProgramSelector",
                                    data: {
                                        dealTypes: getEntityDataFromSession<IDealType>(SessionStorageConsts.dealTypes).filter(t => t?.name?.toLowerCase() === DealType.DealRegistration.toLowerCase() || t?.name?.toLowerCase() === DealType.SpecialPricing.toLowerCase()),
                                        selectedDealTypes: selectedPricingPrograms,
                                        isManaged: selectedCustomer?.isManaged,
                                        organizationName: selectedCustomer?.legalEntityName,
                                        productTypeName: commonPPContextState?.partnerDetails?.productGroupName,
                                        dealTypeName: DealType.DealRegistration,
                                        handlePricingProgramSelection,
                                        isOpenPanel: isOpenCasePanel,
                                        handlePricingProgramSelectionCancel,
                                        partnerDetails: commonPPContextState?.partnerDetails
                                    }
                                }
                            ]
                        }
                    },
                ]
            },
        },
        {
            type: "Row",
            visible: (commonPPContextState.pageMode === PageMode.Create || commonPPContextState.pageMode === PageMode.Edit),
            data: {
                horizontal: "true",
                disableshrink: 'true',
                components: [
                    {
                        type: "InnerHTMLContainer",
                        data: {
                            id: "addCustomer",
                            contolType: UIControlType.Panel,
                            isOpen: showAddCustomer,
                            dismissPanel: closePanel,
                            headerText: hideUnManagedCustomerEdit ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNewCustomerText", UIControlsText.AddNewCustomerText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "EditCustomerText", UIControlsText.EditCustomerText),
                            content: <CreateUnmanagedCustomer {...{ onSubmitToPricingProgramCallBack: onAddNewCustomerSelectionHandler, onCloseCallBack: closePanel, savedCustomer: commonPPContextState?.opportunityDeals[0]?.dealCustomers ? commonPPContextState?.opportunityDeals[0]?.dealCustomers[0] : undefined  }} />,
                            type: PanelType.custom,
                            customWidth: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PanelCustomerWidth55Percent", UIControlsText.PanelCustomerWidth55Percent)
                        }
                    }]
            }
        },

    ];
    // show search json by default
    if (customerOption === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "Search", UIControlsText.Search)) {
        customerJson = CustomerSearchJson
    }
    //when case is approved / need more information and edit mode show details json
    else if (customerOption === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CustomerDetails", UIControlsText.CustomerDetails)) {
        customerJson = GetCustomerViewJson(customerDetail);
    }
    return customerJson;
}