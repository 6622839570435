import { IDropdown, MessageBarType } from '@fluentui/react';
import { IDropdownOption, IStackItemStyles, IStackTokens } from "office-ui-fabric-react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { getUserSession, isMsStoreUser, isNullOrEmpty, getRuleValidationMessage, getEntityDataFromSession } from "../../../../components/shared/common/util";
import { BreadCrumbStyles, DefaultPageStyles, PartnerSearchStyles, separatorStyles, _Styles } from "../../../../content/styles/Page.styles";
import { UIControlsText } from "../../../../content/UIControlsText";
import { DealDetailsStyles, PageStyles } from "../../common/content/Page.Styles";
import { IPartnerDetails } from '../../../../models/IPartnerDetails';
import { IComponent } from '../../../shared/Components/DynamicFormRendering';
import { stackItemStyles } from '../PricingProgramFormUtils';
import { IPricingProgramProps } from '../props/IPricingProgramProps';
import { DisplayMode } from '../../../../models/DisplayMode';
import { UIControlType } from '../../../../models/UIControlType';
import { GuidanceText } from '../../../../models/GuidanceText';
import { UserRole } from '../../../../models/UserRole';
import { trim } from 'lodash';
import { MessageTypeText } from '../../../../models/MessageTypeText';
import { SessionStorageConsts } from '../../../../models/SessionStorageConsts';
import { UserSubRole } from '../../../../models/UserSubRole';
import { useToSetPageTitle as mdppAccessibilitySetPageTitle } from '../../../../hooks/accessiblity-hooks';
import { PageNames } from '../../../../hooks/accessiblity-hooks';

export function CasePartnerDetailForm(props: IPricingProgramProps) {
    const [partnerRole, setPartnerRole] = useState("");
    const [mpnLocationProductTypes, setMPNLocationProductTypes] = useState<any[]>([]);
    const [organizationId, setOrganizationId] = useState<string>();
    const [locationId, setLocationId] = useState<string>();
    const [locationDropdown, setLocationDropdown] = useState<IDropdownOption[]>([])
    const [productDropdown, setProductDropdown] = useState<IDropdownOption[]>([])
    const [mpnCountryName, setMPNCountryName] = useState<string>();
    const [mpnCountryId, setMPNCountryId] = useState<string>();    
    const [mpnLocationCountry, setMPNLocationCountry] = useState<string | undefined>();
    const [productGroupValue, setProductGroupValue] = useState<string | undefined>();
    const [productGroup, setProductGroup] = useState<string | undefined>();
    const [disableContinue, setDisableContinue] = useState<boolean>(true);
    const [displayMessageText, setDisplayMessageText] = useState<boolean>(true);
    const [displayMessageNote, setDisplayMessageNote] = useState<boolean>(true);
    const [productRoles, setProductRoles] = useState<any[]>([]);
    const history = useHistory();
    const dropdownRef = React.createRef<IDropdown>();
    const [isInternalUser, setIsInternalUser] = useState<boolean>(false);
    const partnerPaddingStyle: IStackTokens = { padding : "8px" };
    const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings);

    mdppAccessibilitySetPageTitle(PageNames.NewCaseSubmission);
    
    React.useEffect(() => {
        getUserDetails();
    }, []);

    React.useEffect(() => {
        if (dropdownRef.current?.selectedOptions?.length != undefined && dropdownRef.current?.selectedOptions?.length < 1)
            dropdownRef.current?.focus(true);
    }, [dropdownRef]);


    /**
     * Get user details and add locations and products groups to dropdowns
     */
    const getUserDetails = () => {
        let data = JSON.parse(getUserSession());
        let userRoleDetails: any[] = data.userRoleDetails;
        let organizationLocationIds: any[] = [];
        let locationProductTypes: any[] = [];
        
        userRoleDetails.map(x => {
            let countryName = props.countries.find(ele => ele.countryId == x.countryId)?.countryName;
            let mpnOrganizationLocation = `${x.organizationName} / ${countryName}`

            if (!isNullOrEmpty(x.locationId) && organizationLocationIds.findIndex(y => y.locationId == x.locationId) === -1) {
                organizationLocationIds.push({organizationId: x.organizationId, organizationName: x.organizationName, locationId: x.locationId, countryId: x.countryId,
                location: countryName, mpnOrganizationLocation: mpnOrganizationLocation})
            } 

            locationProductTypes.push({
                location: mpnOrganizationLocation,
                productType: x.productType,
                userRole: x.userSubRole,
                countryId: x.countryId,
                countryName: countryName,
                organizationId: x.organizationId,
                locationId: x.locationId
            });
        }
        );

        let locDropdown: IDropdownOption[] = [];
        let locArray = Array.from(organizationLocationIds);
        for (let i = 0; i < locArray.length; i++) {
            locDropdown.push({ key: locArray[i].locationId || locArray[i].mpnOrganizationLocation, text: locArray[i].mpnOrganizationLocation});
        }

        setLocationDropdown(locDropdown);        
        setMPNLocationProductTypes(locationProductTypes);
        setProductsAndRoles(locDropdown, locationProductTypes);
        if (isMsStoreUser()) {
            setIsInternalUser(true);
        }
    }

    /**
     * sets product types and roles if only one location exits
     * @param locDropdown 
     * @param mpnLocationProductTypes 
     */
    const setProductsAndRoles = (locDropdown: IDropdownOption[], mpnLocationProductTypes: any[]) => {
        let prodDropdown: IDropdownOption[] = [];
        let productRole: any[] = [];
        if (locDropdown.length === 1) {
            setMPNLocationCountry(locDropdown[0].key.toString());
            let products: any[] = mpnLocationProductTypes.filter(x => x.locationId == locDropdown[0].key.toString());
            setOrganizationId(products[0].organizationId);
            setLocationId(products[0].locationId);   
            setMPNCountryId(products[0].countryId);
            setMPNCountryName(products[0].countryName);
            products.map(prod => {
                if (prodDropdown.findIndex(x => x.key == prod.productType.productId) === -1) {
                    prodDropdown.push({ key: prod.productType.productId, text: prod.productType.productName });
                }
                productRole.push({ product: prod.productType.productId, role: prod.userRole });
            });

            var excludeUserRole = process.env.REACT_APP_EXCLUDE_USERROLE || UserSubRole.ClaimOnlyADD;
            productRole = productRole.filter(x => !excludeUserRole.split(',').includes(x.role));
            setProductRoles(productRole);
        }
        else {
            props?.productTypes.map(eachProduct => {
                prodDropdown.push({ key: eachProduct?.productId, text: eachProduct?.productName });
            });
        }
        setProductDropdown(prodDropdown);
        if (prodDropdown.length === 1) {
            setProductGroupValue(prodDropdown[0].key.toString());
            setProductGroup(prodDropdown[0].text);
            if (productRole.length === 1) {
                setPartnerRole(productRole[0].role);
                setDisableContinue(false);
            }
        }

    }

    /**
     * on location change assign selected location to local variable and get product Groups for the location
     * @param event 
     * @param option 
     * @param index 
     */
    const onLocationChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setMPNLocationCountry(option?.key?.toString());
        getProductGroups(option?.key.toString())
        dropdownRef.current?.focus(false);
    }

    /**
     * Get product groups for given location id
     * @param locId 
     */
    const getProductGroups = (locId: string | undefined) => {
        if (locId) {
            let products: any[] = mpnLocationProductTypes.filter(x => x.locationId == locId);
            setOrganizationId(products[0].organizationId);
            setLocationId(products[0].locationId);            
            setMPNCountryId(products[0].countryId);
            setMPNCountryName(products[0].countryName);
            let prodDropdown: IDropdownOption[] = [];
            let productRole: any[] = [];
            products.map(prod => {
                if (prodDropdown.findIndex(x => x.key == prod.productType.productId) === -1) {
                    prodDropdown.push({ key: prod.productType.productId, text: prod.productType.productName });
                }
                productRole.push({ product: prod.productType.productId, role: prod.userRole });
            });
            setProductDropdown(prodDropdown);
            setProductRoles(productRole);
            if (prodDropdown.length === 1) {
                setProductGroupValue(prodDropdown[0].key.toString());
                setProductGroup(prodDropdown[0].text);
                let roles = productRole.filter(x => x.product === prodDropdown[0].key.toString());
                if (roles.length > 1) {
                    setDisableContinue(true);
                }
                else {
                    setPartnerRole(roles[0].role);
                    setDisableContinue(false);
                }
            }
        }
    }

    /**
     * on continue button click set context and show customer search
     */
    const onContinueClick = () => {
        let partnerDetails: IPartnerDetails = {
            mpnLocation: mpnLocationCountry,
            countryId: mpnCountryId,
            countryName: mpnCountryName,
            userRole: partnerRole,
            organizationId: organizationId,
            productGroupId: productGroupValue,
            productGroupName: productGroup,
            mpnLocationId: locationId,
        }
        history.push({
            pathname: '/managecase/pricingprogram',
            state: { ...{ partnerDetails } }
        })
    }

    /**
     * on product group change assign selected product group to local variable and set roles
     * @param event 
     * @param option 
     * @param index 
     */
    const onProductGroupSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setProductGroupValue(option?.key.toString());
        setProductGroup(option?.text);
        if (productRoles.length > 0) {
            let roles = productRoles.filter(x => x.product === option?.key.toString());
            var excludeUserRole = process.env.REACT_APP_EXCLUDE_USERROLE || UserSubRole.ClaimOnlyADD;
            roles = roles.filter(x => !excludeUserRole.split(',').includes(x.role));
            if (roles.length > 1) {
                setDisableContinue(true);
            }
            else {
                setPartnerRole(roles[0].role);
                setDisableContinue(false);
            }
            if (locationDropdown.length === 1) {
                dropdownRef.current?.focus(false);
            }
        }
        else {
            setDisableContinue(false);
        }
        if (locationDropdown.length === 1) {
            dropdownRef.current?.focus(false);
        }
        if (isInternalUser) {
            setDisableContinue(false);
        }
    }

    /**
     * on click of cancel button navigate user to home page
     */
    const onCancelClick = () => {
        history.push("/homePage");
    }

    /**
     * casePartnerDetailForm template
     */
    const casePartnerDetailForm: IComponent = {
        type: 'FormGroup',
        data: {
            id: 'RootContainer',
            baseState: props.pageContext,            
            components: [
                {
                    type: 'Breadcrumb',
                    data: {
                        id: 'Breadcrumb',
                        title: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseSubmissionText", UIControlsText.CaseSubmissionText),
                        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseSubmissionText", UIControlsText.CaseSubmissionText),
                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CaseSubmissionText", UIControlsText.CaseSubmissionText),
                        styles: BreadCrumbStyles,
                        items: [
                          
                            { text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HomeText", UIControlsText.HomeText), key: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "HomeText", UIControlsText.HomeText), href: "/homepage" },
                            { text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "NewCaseSubmissionText", UIControlsText.NewCaseSubmissionText), key: "NCS", href: "/partnerdetail" }
                        ]
                    }
                },
                {
                    type: 'Separator',
                    data: {
                        id: 'Separator',
                        styles: separatorStyles
                    }
                },
                {
                    type: 'FormGroup',
                    data: {
                        id: 'PartnerSearch',
                        horizontal: "true",
                        disableshrink: 'true',
                        visible: true,
                        'data-nextgen-parent-group': "Create - Partner Detail",
                        'data-nextgen-parentid': "Create - Partner Detail",
                        components: [
                            {
                                type: 'Stack',
                                visible: true,
                                data: {
                                    id: 'StackItemPartnerSearchHeader',
                                    align: "auto",
                                    visible: true,
                                    tokens: partnerPaddingStyle,
                                    components: [
                                        {
                                            type: "StackItem",
                                            data: {

                                                id: "verifyDetailsHeader",
                                                visible: true,
                                                components: [{
                                                    type: "InnerHTMLContainer",
                                                    data: {
                                                        id: "PartnerSearchHeader",
                                                        contolType: UIControlType.Text,
                                                        text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "VerifyDetailsHeader", UIControlsText.VerifyDetailsHeader),
                                                        textSize: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "TextSizeXLarge", UIControlsText.TextSizeXLarge)
                                                    }
                                                }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'Stack',
                                data: {
                                    id: 'StackItemPartnerSearchText',
                                    horizontal: "true",
                                    disableshrink: 'true',
                                    visible: displayMessageText,
                                    tokens: partnerPaddingStyle,
                                    components: [
                                        {
                                            type: "StackItem",
                                            data: {
                                                id: "PartnerSearchTextMsgBar",
                                                visible: displayMessageText,
                                                components: [{
                                                    type: "InnerHTMLContainer",
                                                    data: {
                                                        id: "PartnerSearchText",
                                                        contolType: UIControlType.MessageBar,
                                                        message: isInternalUser ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "InternalUserSearchCaseSubmissionText", GuidanceText.InternalUserSearchCaseSubmissionText) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "PartnerSearchCaseSubmissionText", GuidanceText.PartnerSearchCaseSubmissionText),
                                                        messageBarType: MessageBarType.info,
                                                        styles: DealDetailsStyles.customerWarningMessageWidth
                                                    }
                                                }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'Stack',
                                visible: !isInternalUser,
                                data: {
                                    id: 'StackItemPartnerSearchNote',
                                    horizontal: "true",
                                    disableshrink: 'true',
                                    visible: displayMessageNote,
                                    tokens: partnerPaddingStyle,
                                    components: [
                                        {
                                            type: "StackItem",
                                            data: {
                                                id: "partnerSearchNote",
                                                components: [{
                                                    type: "InnerHTMLContainer",
                                                    data: {
                                                        id: "PartnerSearchNote",
                                                        contolType: UIControlType.MessageBar,
                                                        message: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldGuidanceText, "PartnerSearchCaseSubmissionNote", GuidanceText.PartnerSearchCaseSubmissionNote),
                                                        messageBarType: MessageBarType.info,
                                                        styles: DealDetailsStyles.customerWarningMessageWidth
                                                    }
                                                }]
                                            }

                                        }
                                    ]
                                }
                            },
                            {
                                type : "Paper",
                                data :{
                                    id :"PatnerDetailsPaper",
                                    components : [                                                                              
                                        {
                                            type: 'Stack',
                                            visible: true,
                                            data: {
                                                id: "mpn-location",
                                                visible: !isInternalUser,
                                                horizontal: true,
                                                align: "auto",
                                                components: [                                                    
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "locationIcon",
                                                            styles: stackItemStyles,
                                                            components: [{
                                                                type: "FontIcon",
                                                                data: {
                                                                    id: "locIcon",
                                                                    iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "POIIconName", UIControlsText.POIIconName),
                                                                    className: PartnerSearchStyles.locationIconClass,
                                                                }
                                                            }]
                                                        }
                                                    },                                                    
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "locationId",
                                                            styles: stackItemStyles,
                                                            components: [
                                                                {
                                                                    type: "Dropdown",
                                                                    visible: locationDropdown.length > 1,
                                                                    data: {
                                                                        id: "mpnLocationID",
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNLocationIdCountry", UIControlsText.MPNLocationIdCountry),
                                                                        options: locationDropdown,
                                                                        styles: PageStyles.dropdownStyles,
                                                                        className: _Styles.dropdown,
                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldPlaceHolderText, "MPNLocationIdCountryPlaceHolder", UIControlsText.MPNLocationIdCountryPlaceHolder),
                                                                        onChange: onLocationChange,
                                                                        selectedKey: mpnLocationCountry,
                                                                        componentRef: locationDropdown.length > 1 ? dropdownRef : null
                                                                    }
                                                                },
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    visible: locationDropdown.length <= 1,
                                                                    data: {
                                                                        id: "partnerMPNCountryForView",
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNLocationIdCountry", UIControlsText.MPNLocationIdCountry),
                                                                        styles: PageStyles.dropdownStyles,
                                                                        value: mpnLocationCountry,
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "MPNLocationIdCountry", UIControlsText.MPNLocationIdCountry),                               
                                                                        contolType: UIControlType.LabelValueView,
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            type: 'Stack',
                                            data: {
                                                id: "productTypes",
                                                visible: true,
                                                horizontal: true,
                                                align: "auto",
                                               
                                                components: [
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "productIcon",
                                                            styles: stackItemStyles,
                                                            components: [{
                                                                type: "FontIcon",
                                                                data: {
                                                                    id: "productGroupIcon",
                                                                    iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ProductVariantIConName", UIControlsText.ProductVariantIConName),
                                                                    className: PartnerSearchStyles.productGroupIconClass,
                                                                }
                                                            }]
                                                        }
                                                    },
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "productTypeForEdit",
                                                            visible: productDropdown.length > 1,
                                                            components: [
                                                                {
                                                                    type: "Dropdown",
                                                                    data: {
                                                                        id: "productTypeDropdown",
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                        options: productDropdown,
                                                                        styles: PageStyles.dropdownStyles,
                                                                        className: _Styles.dropdown,
                                                                        placeholder: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupPlaceHolderText", UIControlsText.DealProductGroupPlaceHolderText),
                                                                        onChange: onProductGroupSelect,
                                                                        selectedKey: productGroupValue,
                                                                        componentRef: ((locationDropdown.length === 1 && productDropdown.length > 1) || isInternalUser) ? dropdownRef : null
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "productTypeForView",
                                                            visible: productDropdown.length <= 1,
                                                            components: [ 
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    data: {
                                                                        id: "productTypeTextbox",
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),
                                                                        styles: PageStyles.textFieldStylesOneColumn,
                                                                        value: props?.productTypes?.find(t => productGroupValue === t.productId)?.productName,
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DealProductGroupText", UIControlsText.DealProductGroupText),                                 
                                                                        contolType: UIControlType.LabelValueView,
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            type: 'Stack',
                                            data: {
                                                id: "partnerRole",
                                                visible: !isInternalUser,
                                                horizontal: true,
                                                align: "auto",
                                               
                                                components: [
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "productIcon",
                                                            styles: stackItemStyles,
                                                            components: [{
                                                                type: "FontIcon",
                                                                data: {
                                                                    id: "productGroupIcon",
                                                                    iconName: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContactIconName", UIControlsText.ContactIconName),
                                                                    className: PartnerSearchStyles.partnerRoleIconClass,
                                                                }
                                                            }]
                                                        }
                                                    },
                                                    {
                                                        type: "StackItem",
                                                        data: {
                                                            id: "role",
                                                            components: [
                                                                {
                                                                    type: "InnerHTMLContainer",
                                                                    data: {
                                                                        id: "partnerRoleTextbox",
                                                                        label: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerRole", UIControlsText.PartnerRole),
                                                                        styles: PageStyles.textFieldStylesOneColumn,
                                                                        value: partnerRole,
                                                                        ariaLabel: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "PartnerRole", UIControlsText.PartnerRole),                                 
                                                                        contolType: UIControlType.LabelValueView,
                                                                    }
                                                                }
                                                            ]
                                                        }
            
                                                    }
                                                ]
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: "Stack",
                                data: {
                                    id: "buttonGroup",
                                    horizontal: "true",
                                    tokens: partnerPaddingStyle,
                                    components: [
                                        {
                                            type: 'StackItem',
                                            data: {
                                                id: "continue",
                                                styles: stackItemStyles,
                                                align: "auto",
                                                components: [
                                                    {
                                                        type: "PrimaryButton",
                                                        data: {
                                                            id: "continueBtn",
                                                            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ContinueText", UIControlsText.ContinueText),
                                                            disabled: disableContinue,
                                                            styles: PageStyles.commandButtonPadding,
                                                            onClick: onContinueClick
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            type: 'StackItem',
                                            data: {
                                                id: "cancel",
                                                styles: stackItemStyles,
                                                align: "auto",
                                                components: [
                                                    {
                                                        type: "DefaultButton",
                                                        data: {
                                                            id: "cancelBtn",
                                                            text: getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CancelText", UIControlsText.CancelText),
                                                            styles: PageStyles.commandButtonPadding,
                                                            onClick: onCancelClick
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }

                        ]
                    }
                }
            ]
        }
    }
    return casePartnerDetailForm;
}