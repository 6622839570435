import { useState, useCallback, useEffect } from "react";
import {
    Grid, GridColumn, GridItemChangeEvent, GridCellProps, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent,
    getSelectedState, GridPageChangeEvent, GridRowProps, GridFooterCellProps, GridSortChangeEvent, GridExpandChangeEvent,
    GridDetailRowProps, GridFilterChangeEvent, GridHeaderCellProps, GridToolbar, GridRowClickEvent, GridDataStateChangeEvent, GridGroupChangeEvent, GridFilterCell, GridColumnMenuFilter, GridNoRecords
} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor, filterBy, CompositeFilterDescriptor, GroupDescriptor } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { CellRender, RowRender } from "./gridrenderers";
import { DropdownFilterCell } from "./dropDownFilterCell";
import { IGridProps } from "./IGridProps"
import * as React from "react";
import { IPageState } from "./IPageState"
import { GridSelectionType } from "./SelectionType";
import { FontIcon, PrimaryButton } from "@fluentui/react";
import { _Styles } from "../../../content/styles/Page.styles";
import { IKendoGridColumnProps } from "./IKendoGridColumnProps";
import { ApplicationConstants } from "../../../models/ApplicationConstants";
import { MenuItems } from "../verticalmenu/menuItems";
import { JavaScriptExtensions } from "../../../infrastructure/JavaScriptExtensions";
import { FilterMenu } from "./gridfilter";
import { CssClassConstants } from "../../../content/CssClassConstants";
import { UIControlsText } from "../../../content/UIControlsText";
import { addExportExcelHeader, getUserRole, isNullOrEmpty, gridAccessibilityFix } from "../common/util";
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";
import moment from "moment";
import { GridType } from "../../../models/GridType";
import NextGenKendoChildGrid from "./nextgenkendochildgrid";
import { IGridChildProps } from "./IGridChildProps";
import { DealType } from "../../../models/DealType";
import { MessageTypeText } from '../../../models/MessageTypeText';
import { getRuleValidationMessage, getEntityDataFromSession } from "../../../components/shared/common/util";
import { SessionStorageConsts } from '../../../models/SessionStorageConsts';

const sessionResourceData = getEntityDataFromSession<any>(SessionStorageConsts.resourceStrings)
/**
 * NextGenKendoGrid Component.
 * @function component
 */
const NextGenKendoGrid: React.FunctionComponent<IGridProps> = (props: IGridProps) => {
    const idGetter = getter(props.kendoGridProps.dataItemKey);
    const [selectedCheckState, setSelectedCheckState] = useState<{ [id: string]: boolean | number[]; }>({});
    const [detailSelectedCheckState, setDetailSelectedCheckState] = useState<{ [id: string]: boolean | number[]; }>({});
    const initialSort: Array<SortDescriptor> = [{ field: props.kendoGridProps.initialSortField, dir: "asc" }];
    const [sort, setSort] = useState(initialSort);
    const [editField, setEditField] = useState<string | undefined>(undefined);
    const [griddata, setGridData] = useState<any[]>([]);
    const [gridCount, setGridCount] = useState<number>(props.kendoGridProps.totalRecords);
    const initialDataState: IPageState = { skip: 0, take: props.kendoGridProps.noOfRecords };
    const [page, setPage] = useState<IPageState>(initialDataState);
    const initialFilter: CompositeFilterDescriptor = { logic: "and", filters: [] }
    const [filter, setFilter] = useState(initialFilter);
    const [gridWidth, setGridWidth] = useState("auto");
    const [userRoles] = useState<string>(getUserRole());
    const [toggleText, setToggleExpandCollapse] = useState<string>(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll));
    var date = moment()
        .utcOffset('+05:30')
        .format('MM-DD-YYYY');

    useEffect(() => {
        props.resetPaginationToDefault && setPage(props.resetPaginationToDefault)
    }, [props.resetPaginationToDefault])

    useEffect(() => {
        (function(){
            var gridCheckbox = document.querySelectorAll('.k-checkbox');
                gridCheckbox.forEach((element) => {
                element.setAttribute('aria-label', 'checkbox');
            });
        })();
    })

    //listening the props change and reset griddata state
    useEffect(() => {
        if (props.kendoGridProps.defaultMainGridSelectionstate) {
            setSelectedCheckState(props.kendoGridProps.defaultMainGridSelectionstate);
        }
        SetGridWidth();
        if (!props.showFilterTextBox) {
            setFilter(initialFilter);
            setGridData(props.kendoGridProps.data);
        } else {
            setGridData(filterBy(props.kendoGridProps.data || [], filter));
        }
        setGridCount(isNullOrEmpty(props.kendoGridProps.totalRecords) ? props.kendoGridProps.data?.length : props.kendoGridProps.totalRecords);
        window.addEventListener('resize', SetGridWidth);
        setToggleExpandCollapse(getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll));
    }, [props.kendoGridProps.data, props.kendoGridProps.defaultMainGridSelectionstate])

    useEffect(() => {
        if (props.kendoGridProps.defaultDetailGridSelectionstate) {
            setDetailSelectedCheckState(props.kendoGridProps.defaultDetailGridSelectionstate);
        }
    }, [props.kendoGridProps.defaultDetailGridSelectionstate]);

     //Fixing Accessibility for noRecords, sortIcons, GridPagerBtns & gridFootercell
    useEffect(() => {
        gridAccessibilityFix();
    });

    //customize the cell to render of selected legal grid
    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => (
        <CellRender originalProps={props} td={td} enterEdit={enterEditonSelectedData} editField={editField} />
    );
    //customize the row to render of selected legal grid
    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        return (
            <RowRender originalProps={props} tr={tr} exitEdit={exitEditonSelectedData} editField={editField} />
        )
    };
    //Editable cell creation on selecte Legals - on typing editable cell update value from user
    const onItemChange = (e: GridItemChangeEvent) => {
        let field = e.field || "";
        let intValue = 0
        let colProps = props.kendoGridProps.columns.filter((col: IKendoGridColumnProps) => {
            if (col.apiField === field)
                return col.editorType;
        })[0] as IKendoGridColumnProps;
        if (colProps) {
            if (colProps.editorType === ApplicationConstants.NumericEditorType && e.value < 0) {
                return;
            }
        }
        intValue = Math.floor(e.value)
        e.dataItem[field] = intValue;
        let newData = griddata.map((item: any) => {
            if (item.id === e.dataItem.id) {
                item[field] = intValue;
            }
            return item;
        });
        props.kendoGridProps.data = [...newData];
        setGridData(newData);
    };
    //--event for checkbox header selection in the search grid: when header checked all the child checkbox will be checked
    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            if (props.disableSelectAllCheckbox) {
                return;
            }
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};
            event.dataItems.forEach(item => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedCheckState(newSelectedState);
            props.onRowSelectionCallBack?.(newSelectedState);
        }, []);
    //event to trigger on change of checkbox in the grid
    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            if (event.dataItem.editMode) {
                return;
            }
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedCheckState,
                dataItemKey: props.kendoGridProps.dataItemKey,
            });
            setSelectedCheckState(newSelectedState);
            //to enable check uncheck based on config, when multiple
            if (event.syntheticEvent !== undefined) {
                const checkboxElement: any = event.syntheticEvent.target
                const checked = checkboxElement.checked;
                if (props.kendoGridProps.selectionType === GridSelectionType.Single) {
                    event.dataItems.forEach(item => {
                        if (event.dataItem[props.kendoGridProps.dataItemKey] === item[props.kendoGridProps.dataItemKey]) {
                            newSelectedState[idGetter(item)] = checked
                        } else {
                            newSelectedState[idGetter(item)] = false;
                        }
                    });
                    props.onPrimarySelectionCallBack?.(event.dataItem, checked);
                }
            }
            props.onRowSelectionCallBack?.(newSelectedState);
        },
        [selectedCheckState]
    )
    //--- Start of  hierarchal grid ---//
    const expandChange = (event: GridExpandChangeEvent) => {
        event.dataItem.expanded = event.value;
        setGridData([...griddata]);

        if (!event.value || event.dataItem.details) {
            return;
        }
        //populate data here on expand
        let data: any = griddata.slice();
        let index = data.findIndex((row: any) => row[props.kendoGridProps.groupByColumn] === event.dataItem[props.kendoGridProps.groupByColumn]);
        data[index].details = props.kendoGridProps.masterData?.filter((item: any) => item[props.kendoGridProps.groupByColumn] === event.dataItem[props.kendoGridProps.groupByColumn]);//sub grid value goes here
        setGridData(data);
    };

    const DetailComponent = useCallback(
        (childprops: GridDetailRowProps) => {
            var childData = childprops.dataItem.details ? childprops.dataItem.details : [];
            let childGridProps: IGridChildProps = {
                parent: props,
                childGridData: childData,
                defaultChildGridSelectionstate: detailSelectedCheckState
            }
            return (
                <React.Fragment>
                    <NextGenKendoChildGrid {...{ ...childGridProps }} />
                </React.Fragment>
            );
        }, [detailSelectedCheckState]
    );
    //--- End of  hierarchal grid ---//
    //event to trigger on page change on pagination
    const pageChange = (event: GridPageChangeEvent) => {
        props.onPageChange?.(event);
        setPage(event.page);
    };

    //--Start of Inline Edit Grid Functionality --//
    //on click of row enable editing
    const enterEditonSelectedData = (dataItem: any, field: string) => {
        if(field.match('unitsCommitted')){
            const newData = griddata.map((item: any) => ({
                ...item,
                inEdit: item.id === dataItem.id ? field : undefined,
            }));
            setGridData(newData);
            setEditField(field);
        }
        else if(dataItem.hasOwnProperty('isUploadedRecord')){
            if(dataItem.isUploadedRecord){
                const newData = griddata.map((item: any) => ({
                    ...item,
                    inEdit: item.id === dataItem.id ? field : undefined,
                }));
                setGridData(newData);
                setEditField(field);
            }
        }
        else{
            const newData = griddata.map((item: any) => ({
                ...item,
                inEdit: item.id === dataItem.id ? field : undefined,
            }));
            setGridData(newData);
            setEditField(field);
        }
    };
    
    const exitEditonSelectedData = () => {
        let editedItem = griddata.filter((item: any) => (item.inEdit != undefined));

        if (props.kendoGridProps.editField) {
            const newData = griddata.map((item: any) => ({ ...item, inEdit: undefined }));
            setGridData(newData);
            setEditField(undefined);
        }
        props.onEditCallBack?.(editedItem[0]);
    };
    //--End of Inline Edit Grid Functionality --//

    //--Start of Delete Row in Grid Functionality --//
    const gridDeleteCell = (props: GridCellProps) => (<DeleteCell {...props} remove={removeSelectedRow} />);
    //method to render delete icon in grid component
    const DeleteCell = (props: { editField?: any; remove?: any; dataItem?: any }) => {
        const { dataItem } = props;
        return dataItem.editMode ? (<td className="k-command-cell"></td>) : (
            <td >
                <FontIcon aria-label={CssClassConstants.DeleteIconName} iconName={CssClassConstants.DeleteIconName} className={_Styles.DeleteRow} onClick={() =>
                    props.remove(dataItem)
                }>
                </FontIcon>
            </td>
        );
    };
    //callback method for removing selected row from grid on delete button click
    const removeSelectedRow = (deletedRowData: any) => {
        if (!deletedRowData.editMode) {
            const newList = griddata.filter((item) => item.id !== deletedRowData.id);
            props.kendoGridProps.data = [...newList];
            setGridData([...newList]);
            if (props.onDeleteCallBack) {
                props.onDeleteCallBack(deletedRowData, newList);
            }
        }
    };
    //--End of Delete Row in Grid Functionality --//
    // --Start of Download Row in Grid Functionality--//
    const gridDownloadCell = (props: GridCellProps) => (<DownloadCell {...props} />);
    const DownloadCell = (props: { editField?: any; download?: any; dataItem?: any }) => {
        const { dataItem } = props;
        var gridContent = "data:application/pdf;base64," + dataItem.content
        return dataItem.editMode ? (<td className="k-command-cell"></td>) : (
            <td >
                <a download={dataItem.name} href={gridContent}>
                    <FontIcon aria-label={CssClassConstants.DownloadIconName}
                        iconName={CssClassConstants.DownloadIconName}
                        className={_Styles.DeleteRow} ></FontIcon>
                </a>

            </td>
        );
    };

    // --End of Download Row in Grid Functionality--//

    //to calculate total of column in footer cell
    const footerCellwithTotal = (footerprops: GridFooterCellProps) => {
        const field = footerprops.field || "";
        var findItem = props.kendoGridProps.columns.find(x => x.apiField === field);
        var showFooter = false;
        if (findItem !== undefined) {
            showFooter = findItem.showFooter;
        }
        if (showFooter) {
            const total = griddata?.reduce((prev: number, current: { [x: string]: any }) => {
                return current[field] ? prev + Number(current[field]) : prev
            }, 0);
            return (<td colSpan={footerprops.colSpan} style={footerprops.style}> {findItem?.footerTitle} {total}</td>);
        }
        return (<td></td>);
    };
    const addNew = () => {
        const newDataItem = {
            inEdit: true,
            Discontinued: false,
            isUploadedRecord: true,
            customerType: 2,
        };
        props.kendoGridProps.data = [newDataItem, ...griddata]
        setGridData([newDataItem, ...griddata]);
    };
    const mandatoryHeaderCell = (props: GridHeaderCellProps) =>
        <span className="k-link" title={props.title}>
            {props.title} <span className={"gridmandatoryField"}>*</span>
        </span>
    const HeaderCell = (props: GridHeaderCellProps) =>
        <span className="k-link" title={props.title}>
            {props.title}
        </span>

    const allowView = (config: any) => {
        return (config?.allowColumnView?.length == 0 ? true : config?.allowColumnView?.some((r: any) => userRoles.includes(r)) ? true : false)
            && (config.isVisible == null || config.isVisible ? true : false);
    }
    const VerticalMenuCell = (menuprops: { dataItem?: any; }) => {
        const { dataItem } = menuprops;
        return <td >
            <MenuItems dashBoardConfig={props.kendoGridProps} dashBoardData={menuprops}
                selectedData={dataItem}  ></MenuItems>
        </td >
    };
    const gridErrorCell = (props: GridCellProps) => (<ErrorCell {...props} />);
    const ErrorCell = (props: { editField?: any; remove?: any; dataItem?: any }) => {
        const { dataItem } = props;
        return dataItem.editMode ? (<td className="k-command-cell"></td>) : (
            <td >
                <p className="error-msg"> {dataItem.errors}</p>
                <p className="warning-msg"> {dataItem.warnings}</p>
            </td>
        );
    };

    //method to update grid data and count based on filter from context menu of grid column
    const filterChange = (e: GridFilterChangeEvent) => {
        setFilter(e.filter)
        if (e.filter) {
            if (e.filter.filters.length >= 1) {
                setGridCount(filterBy(props.kendoGridProps.data || [], e.filter).length);
                setGridData(filterBy(props.kendoGridProps.data || [], e.filter));
            }
            else {
                setGridCount(filterBy(griddata || [], e.filter).length);
                setGridData(filterBy(griddata || [], e.filter));
            }
        }
        else {
            setGridCount(props.kendoGridProps.totalRecords);
            setGridData(props.kendoGridProps.data);
        }
    };


    const clearGridData = () => {
        props.kendoGridProps.data = []
        setGridData([]);
        if (props.onDeleteCallBack) {
            props.onDeleteCallBack({}, []);
        }
    };
    const SetGridWidth = () => {
        if (JavaScriptExtensions.isEmptyOrNullOrUndefined(props.kendoGridProps.width)) {
            setGridWidth("auto");
        } else if (props.kendoGridProps.width === "0") {
            let headerElement = document.getElementsByClassName("headerStyle");
            let width = headerElement.length > 0 ? headerElement[0].clientWidth + "px" : "auto";
            setGridWidth(width);
        }
    }

    //method to download grid data in to excel file
    const _export = React.useRef<ExcelExport | null>(null);
    const excelExport = () => {
        if (_export.current !== null) {
            if (props.kendoGridProps.gridName !== GridType.LegalEntityUpload) {
                addExportExcelHeader(_export, props);
            }
            else {
                _export.current.save();
            }
        }
    };

    const CategoryFilterCell = (filterProps: any) => (
        <DropdownFilterCell
            {...filterProps}
            data={props.dropDownFilterContent}
            defaultItem={filterProps.defaultItem}
        />
    );
    //Generates the grid columns for the childcolumns of a single column
    const getChildColumns = (kendoGridChildColumns: IKendoGridColumnProps[]) => {
        if (kendoGridChildColumns === undefined) {
            return [];
        }
        const tempKendoGridColumnsProps = kendoGridChildColumns.map((col) => {
            return <GridColumn
                key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter} />
        });
        return tempKendoGridColumnsProps
    }
    const getColumns = (col: IKendoGridColumnProps, error: string) => {
        if (col === undefined) {
            return [];
        }
        if (col.childColumns && col.childColumns.length > 0) {
            return <GridColumn
                title={col.columnTitle} children={getChildColumns(col.childColumns)}
            />
        }
        else {
            if (error !== "errors") {
                return <GridColumn
                    key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                    editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter}
                    editor={col.editorType} className={col.cssClass} footerCell={footerCellwithTotal}
                    format={col.editorFormat} columnMenu={col.allowFilter ? FilterMenu : undefined}
                    children={getChildColumns(col.childColumns)} headerClassName={isColumnActive(col.apiField) ? CssClassConstants.ActiveClass : ""}
                    filterCell={col.categoryFilterCell ? CategoryFilterCell : GridFilterCell}
                    headerCell={col.mandatoryField ? mandatoryHeaderCell : HeaderCell}
                    cell={col.isCustomCell ? gridRenderCustomCell : undefined}
                />
            }
            else {
                return <GridColumn
                    key={col.key} field={col.apiField} title={col.columnTitle} width={col.width}
                    editable={col.isEditable} sortable={col.allowSorting} filterable={col.allowFilter}
                    className={col.cssClass} cell={gridErrorCell}
                    children={getChildColumns(col.childColumns)}
                    filterCell={col.categoryFilterCell ? CategoryFilterCell : GridFilterCell}
                    headerCell={col.mandatoryField ? mandatoryHeaderCell : HeaderCell}
                />
            }

        }
    }

    //Toggle Grid Data (Expand All and Collapse All)
    const toggleExpand = () => {
        setToggleExpandCollapse(toggleText === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll) ? getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "CollapseAll", UIControlsText.CollapseAll) : getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll));
        let toggleData = griddata.map(eachRowItem => {
            eachRowItem.details = props.kendoGridProps.masterData?.filter((eachRecord: any) => eachRecord[props.kendoGridProps.groupByColumn] === eachRowItem[props.kendoGridProps.groupByColumn]);
            if (isNullOrEmpty(eachRowItem.expanded)) {
                eachRowItem.expanded = true;
            }
            else {
                eachRowItem.expanded = toggleText === getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ExpandAll", UIControlsText.ExpandAll) ? true : false;
            }
            return eachRowItem;
        });
        setGridData(toggleData);
    };

    const isColumnActive = (field: string) => {
        return (
            GridColumnMenuFilter.active(field, filter)
        );
    }
    const gridRenderCustomCell = (selectedRowData: any) => (
        props.onCustomCellRenderCallBack ? props.onCustomCellRenderCallBack(selectedRowData) : ""
    );
    const addNewPanel = () => {
        if (props.onAddNewPanelCallBack) {
            props.onAddNewPanelCallBack();
        }
    };
    const getAddNewButtonText = () => {
        if (props.kendoGridProps.addNewButtonText) {
            return props.kendoGridProps.addNewButtonText;
        }
        else {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNew", UIControlsText.AddNew);
        }
    }
    const customNoErrorMessage=() =>{
        if(props.customNoRecordsErrorMessage !== undefined){
            return props.customNoRecordsErrorMessage();
        }
        else if(props.kendoGridProps.noRecordsCustomMessage !== undefined) {
            return props.kendoGridProps.noRecordsCustomMessage
        }
        else {
            return getRuleValidationMessage(sessionResourceData, MessageTypeText.OpportunityValidationError, "NoRecordsAvailableText", UIControlsText.NoRecordsAvailableText);
        }
    }

    const handleDealTypeAbbreviation = (dealtype?:string) => {
        switch(dealtype){
            case DealType.SpecialPricing:
                return "SP";
            case DealType.DealRegistration:
                return "DR";
            default:
                return "";
        }
    }

    const handleExportFileName = () =>{
        var gridName = props.kendoGridProps.gridName;
        var fileName = gridName + " " + date;
        var dealtypeString = props.kendoGridProps.dealType;

        var dash = " - ";
        if(gridName === GridType.DRSPExpandView){
            return props.kendoGridProps.caseNumber + dash + handleDealTypeAbbreviation(dealtypeString) + dash + "Expanded View " + date;
        }else if(gridName === GridType.DRSPCompactView){
            return props.kendoGridProps.caseNumber + dash + handleDealTypeAbbreviation(dealtypeString) + dash + "Compact View " + date;
        }else if(gridName === GridType.BUView){
            return props.kendoGridProps.caseNumber + dash + handleDealTypeAbbreviation(dealtypeString) + dash + "BU View " + date;
        }

        return fileName;
    }

    return (
        <React.Fragment>
            <ExcelExport data={griddata} fileName={handleExportFileName()} ref={_export}>
                {props.kendoGridProps.columns.map((col) => {
                    if (allowView(col)) {
                        if (col.childColumns && col.childColumns.length > 1) {
                            return (<ExcelExportColumnGroup title={col.columnTitle} width={parseInt(col.width)} headerCellOptions={{ textAlign: "left" }}>
                                {col.childColumns.map((item) => {
                                    return <ExcelExportColumn field={item.apiField} title={item.columnTitle} />
                                })}
                            </ExcelExportColumnGroup>)
                        } else if (col.childColumns && col.childColumns.length === 1) {
                            return (
                                <ExcelExportColumn field={col.apiField} title={col.columnTitle + " - " + col.childColumns[0].columnTitle} width={parseInt(col.width)} />
                            );
                        } else {
                            return (
                                <ExcelExportColumn field={col.apiField} title={col.columnTitle} width={parseInt(col.width)} />
                            );
                        }
                    }
                })}
            </ExcelExport>
            <Grid
                data={props.kendoGridProps.expandField !== "" ?
                    orderBy(griddata?.slice(page.skip, page.take + page.skip) || [], sort) :
                    props.kendoGridProps.isServerSideGrid ?
                        orderBy(griddata?.map(item => ({ ...item, [props.kendoGridProps.selectedField]: selectedCheckState[idGetter(item)] })) || [], sort) :
                        orderBy(griddata?.map(item => ({ ...item, [props.kendoGridProps.selectedField]: selectedCheckState[idGetter(item)] })).slice(page.skip, page.take + page.skip) || [], sort)
                }
                skip={page.skip}
                take={page.take}
                dataItemKey={props.kendoGridProps.dataItemKey}
                selectedField={props.kendoGridProps.selectedField}
                selectable={{
                    enabled: false, drag: false, cell: false,
                    mode: props.kendoGridProps.selectionType === GridSelectionType.Mulitple ? 'multiple' : 'single'
                }}
                resizable={props.kendoGridProps.resizable}
                sortable={props.kendoGridProps.sortable}
                sort={sort}
                pageable={props.kendoGridProps.pageable}
                filter={filter}
                filterable={props.showFilterTextBox ? true : false}
                onFilterChange={filterChange}
                onItemChange={onItemChange}
                onSelectionChange={props.kendoGridProps.editMode ? undefined : onSelectionChange}
                onHeaderSelectionChange={props.kendoGridProps.selectionType === GridSelectionType.Mulitple ? onHeaderSelectionChange : undefined}
                onPageChange={pageChange}
                onSortChange={(e: GridSortChangeEvent) => { setSort(e.sort); }}
                total={gridCount}
                className={props.kendoGridProps.className}
                cellRender={customCellRender}
                rowRender={props.kendoGridProps.actionTypes?.showGridContextMenuAction ? null : customRowRender}
                editField={props.kendoGridProps.editField}
                onExpandChange={expandChange}
                expandField={props.kendoGridProps.expandField}
                detail={props.kendoGridProps.masterData ? DetailComponent : null}
                style={{ width: gridWidth }}
            >
                {(props.kendoGridProps.actionTypes?.showAddNewRowGridAction ||
                    props.kendoGridProps.actionTypes?.showClearAllGridAction ||
                    props.kendoGridProps.actionTypes?.showDownloadExcelGridAction ||
                    props.kendoGridProps.actionTypes?.showExpandCollapseGridAction ||
                    props.kendoGridProps.actionTypes?.showAddNewPanelGridAction ||
                    !isNullOrEmpty(props.kendoGridProps.gridDisplayName)) &&
                    <GridToolbar >
                        <div id="gridTitle">
                            {props.kendoGridProps.gridDisplayName}
                        </div>
                        <div className="ms-auto">
                            {
                                props.kendoGridProps.actionTypes?.showAddNewRowGridAction &&
                                <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "AddNew", UIControlsText.AddNew)} onClick={addNew} className="me-2" />
                            }
                            {props.kendoGridProps.actionTypes?.showAddNewPanelGridAction &&
                                <PrimaryButton text={getAddNewButtonText()} onClick={addNewPanel} className="me-2" />
                            }
                            {props.kendoGridProps.actionTypes?.showDownloadExcelGridAction && props.kendoGridProps.data?.length > 0 &&
                                <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "DownloadExcel", UIControlsText.DownloadExcel)} onClick={excelExport} className="me-2" />
                            }
                            {props.kendoGridProps.actionTypes?.showClearAllGridAction && props.kendoGridProps.data?.length > 0 &&
                                <PrimaryButton text={getRuleValidationMessage(sessionResourceData, MessageTypeText.FieldLabelText, "ClearAll", UIControlsText.ClearAll)} onClick={clearGridData} className="me-2" />
                            }
                            {props.kendoGridProps.actionTypes?.showExpandCollapseGridAction && props.kendoGridProps.data?.length > 0 &&
                                <PrimaryButton text={toggleText} onClick={toggleExpand} className="me-2" />
                            }
                        </div>
                    </GridToolbar>
                }
                {props.kendoGridProps.actionTypes?.showGridContextMenuAction &&
                    <GridColumn cell={VerticalMenuCell} filterable={false} width="40px" />
                }
                {props.kendoGridProps.actionTypes?.showSelectAllCheckboxGridAction &&
                    <GridColumn field={props.kendoGridProps.selectedField} filterable={false}
                        headerSelectionValue={
                            griddata?.findIndex((item: any) => !selectedCheckState[idGetter(item)]) === -1
                        } />
                }
                {props.kendoGridProps.columns.map((col) => {
                    if (allowView(col)) {
                        return getColumns(col, col.key)
                    }
                })}
                {props.kendoGridProps.additionalColumns?.map((col) => {
                    if (col.isVisible) {
                        return <GridColumn field={col.apiField} key={col.key} title={col.columnTitle}
                            headerCell={mandatoryHeaderCell} filterable={col.allowFilter} width={col.width} className={col.cssClass}
                        />
                    }
                })}
                {props.kendoGridProps.actionTypes?.showRowDeleteGridAction &&
                    <GridColumn cell={gridDeleteCell} title="Actions" filterable={false} />
                }
                {props.kendoGridProps.actionTypes?.showRowDownloadGridAction &&
                    <GridColumn cell={gridDownloadCell} title="Actions" filterable={false} />
                }
                {props.kendoGridProps.noRecordsCustomMessage &&
                <GridNoRecords>
                    <div dangerouslySetInnerHTML={{ __html: customNoErrorMessage() }}> 
                    </div>
                </GridNoRecords>
            }
            </Grid>
        </React.Fragment>
    )
}
export default NextGenKendoGrid;